import React from "react"
import {Spinner} from "@greenbone/cloud-component-library"
import {HostValidationApiClient} from "../../../service/HostValidation/HostValidationApiClient"
import {HistoricHostValidationTableRow} from "./HistoricHostValidationTableRow"
import {Table} from "@greenbone/cloud-dynamic-table"
import {JWT} from "../../../welcomeScreen/JWT"
import styled from "styled-components"
import Select from "react-select"
import {CustomerRestApiClient} from "../../../service/CustomerRestApiClient"

export const StyledSelect = styled(Select)`
  padding-top: 0.5rem;
  padding-bottom: .5rem;
`


const RowEntry = ({row, dataset}) => {
    const approvalRequest = dataset

    return (
        <HistoricHostValidationTableRow key={approvalRequest.id} approvalRequest={approvalRequest}
                                        selected={() => false}/>
    )
}


export class HistoricalHostValidationLists extends React.Component {

    tableColumns = [
        {accessor: "ipAddress", Header: "Host Address"},
        {accessor: "contactEmail", Header: "Contact Email"},
        {accessor: "contactName", Header: "Contact Name"},
        {
            accessor: (row) => new Date(row.requestedOn).getTime() / 1000,
            filterLabel: (row) => (new Date(row.requestedOn).toDateString()),
            id: "requestedOn",
            Header: "Requested On"
        },
        {accessor: "companyName", Header: "Requester Company"},
        {accessor: "userEmail", Header: "Requested By"},
        {accessor: "type", Header: "Type"},
        {accessor: "status", Header: "Status"}
    ]

    state = {
        hostValidations: [],
        historicHostValidations: [],
        _loading: false,
        _exception: false,
        _sending: false
    }

    constructor(props) {
        super(props)
        this.hostValidationClient = new HostValidationApiClient()
    }

    componentDidMount() {
        this.loadHistoricalHostValidations()
    }

    loadAvailableUserInformation() {
        const groupIds = [...new Set(this.state.historicHostValidations.map(val => val.userId))]
        const realmId = this.state.historicHostValidations.find(val => val.realm !== null)?.realmId

        if (!realmId || groupIds.length <= 0) {
            return
        }


        const apiClient = new CustomerRestApiClient()
        apiClient.getUserList(groupIds, realmId)
            .then(response => {
                const hostValidationsWithUser = this.state.historicHostValidations.map(validation => {
                    const user = response.find(user => user.userId === validation.userId)

                    return {...validation, ...user}
                })

                this.setState({historicHostValidations: hostValidationsWithUser})

            })


    }

    loadHistoricalHostValidations() {
        this.setState({_loading: true})
        this.hostValidationClient.getHistoricalApprovalRequest()
            .then((json) => {
                this.setState({historicHostValidations: json})
            })
            .catch(_exception => {
                this.setState({_exception})
            })
            .finally(() => {
                this.setState({_loading: false})
                this.loadAvailableUserInformation()
            })
    }

    handleError = _exception => this.setState({_exception})


    render() {
        if (this.state._exception) {
            throw this.state._exception
        }
        if (this.state._loading) {
            return (<Spinner/>)
        }

        return (

            <Table
                actions={<div></div>}
                cacheKey={`${new JWT().subject()}-historicHostValidations/2021.07.01`}
                searchableColumns={["ipAddress", "contactEmail", "contactName", "requestedOn", "requesterName", "requesterEmail", "type", "status"]}
                data={this.state.historicHostValidations}
                columns={this.tableColumns}
                entryGenerator={RowEntry}
                defaultSortBy={[{id: "requestedOn", desc: true}]}
                filtering={true}/>

        )
    }
}

