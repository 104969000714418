import React from "react"
import {useParams} from "react-router"
import {Col, Row} from "reactstrap"
import {Caption, DashboardCellBanner, Paragraph} from "@greenbone/cloud-component-library"
import {FetchData} from "./components/FetchData"
import {DistributionsOfSeverity} from "./components/company/DistributionsOfSeverity"
import {ManagedSecurityRestApiClient} from "../../service/ManagedSecurityRestApiClient"
import {OverallSeverityTrend} from "./components/company/OverallSeverityTrend"
import {TaskList} from "./components/company/TaskList"
import styled from "styled-components"
import {CompanyName} from "./components/company/CompanyName"
import {ErrorBoundary} from "../../components/ErrorBoundary"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 30px;
  grid-template-areas:
    ". .";
`

const ErrorOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  width: 100%;
`

function errorMessage(error) {
    return <ErrorOuter>
        <Caption>
            No Data Available
        </Caption>
    </ErrorOuter>
}

export function ManagedSecurityCompanyOverviewPage() {
    const {customerId} = useParams()

    return <>
        <Row>
            <Col>
                <CompanyName groupId={customerId}/>
            </Col>
        </Row>
        <Grid>
            <div style={{minHeight: "30rem"}}>
                <DashboardCellBanner>
                    Overall Severity Trend
                </DashboardCellBanner>
                <ErrorBoundary>
                    <FetchData errorMessage={errorMessage}
                               request={() => new ManagedSecurityRestApiClient().getSeverityDistribution(customerId)}>
                        {(data) => <OverallSeverityTrend data={data}/>}
                    </FetchData>
                </ErrorBoundary>
            </div>
            <div>
                <DashboardCellBanner>
                    Distribution Of Severity Last Week
                </DashboardCellBanner>
                <ErrorBoundary>
                    <FetchData errorMessage={errorMessage}
                               request={() => new ManagedSecurityRestApiClient().getSeverityDistribution(customerId)}>
                        {({data}) => <DistributionsOfSeverity data={data}/>}
                    </FetchData>
                </ErrorBoundary>
            </div>
        </Grid>
        <Row>
            <Col>
                <DashboardCellBanner>
                    Task List
                </DashboardCellBanner>
                <Paragraph style={{margin: ".5rem"}}>
                    This is a list of the configured task for the customer. This list contains the last result of a task
                    scan and the trend in comparison to the last scan.
                </Paragraph>
                <ErrorBoundary>
                    <FetchData errorMessage={errorMessage}
                               request={() => new ManagedSecurityRestApiClient().getTaskList(customerId)}>
                        {({data}) => <TaskList taskList={data} groupId={customerId}/>}
                    </FetchData>
                </ErrorBoundary>
            </Col>
        </Row>
    </>
}