import React, {useContext, useEffect, useState} from "react"
import {Col, Row} from "reactstrap"
import {
    Button,
    ButtonSpinner,
    FlexRow,
    GhostButton,
    Headline,
    Input,
    Snackbar,
    Spinner,
    Subheadline
} from "@greenbone/cloud-component-library"
import {BillingAddress} from "./BillingAddress2"
import {useTranslation} from "react-i18next"
import {CustomerRestApiClient} from "../../../../service/CustomerRestApiClient"
import {CountryContext} from "../../../../Context/CountryContext"
import {GreyBoxHead, GreyBoxSimpleHead} from "./GreyBox"
import {Logger} from "../../../../Logger"

export function AddressSlide({id, onClose}) {
    const [loading, setLoading] = useState(true)
    const [groupAddressErrors, setGroupAddressErrors] = useState()
    const [additionalBillingAddressErrors, setAdditionalBillingAddressErrors] = useState()
    const [groupAddress, setGroupAddress] = useState(null)
    const [additionalBillingAddress, setAdditionalBillingAddress] = useState(null)
    const [useAdditionalBillingAddress, setUseAdditionalBillingAddress] = useState(false)
    const [clearAdditionalBillingAddress, setClearAdditionalBillingAddress] = useState(false)
    const [additionalBillingAddressTransmitSuccess, setAdditionalBillingAddressTransmitSuccess] = useState(false)
    const [groupTransmitSuccess, setGroupTransmitSuccess] = useState(false)
    const [vatTransmitSuccess, setVatTransmitSuccess] = useState(false)
    const [vat, setVat] = useState()
    const [vatError, setVatError] = useState(null)
    const [isSending, setSending] = useState(false)

    const {countryList} = useContext(CountryContext)
    const {t} = useTranslation()

    const next = () => {
        Snackbar.Success("Address updates successfully")
        onClose()
    }

    const handleBack = () => {
        onClose()
    }

    const handleAdditionalBillingAddressResponse = (request) => {
        request
            .then(response => {
                if (!response) {
                    setAdditionalBillingAddressTransmitSuccess(true)
                    return
                }


                if (response?.status === 400 || response?.status === "BAD_REQUEST") {
                    setAdditionalBillingAddressErrors(response?.fieldErrors)
                    setAdditionalBillingAddressTransmitSuccess(false)
                    return
                }

                if (response?.status === 428) {
                    setVatError(response?.message)
                    setAdditionalBillingAddressTransmitSuccess(false)
                    return
                }

                throw new Error(response?.message)
            })
            .catch(e => {
                if (e?.status === 428) {
                    setVatError("VAT number must be set if address country is not Germany")
                    setAdditionalBillingAddressTransmitSuccess(false)
                    return
                }

                Snackbar.Error("An error occurred")
            })
    }

    const submitCompanyAddress = async () => {
        handleAdditionalBillingAddressResponse(
            new CustomerRestApiClient().setCompanyAddressOnBillingService(id, additionalBillingAddress)
        )
    }

    const deleteAdditionalBillingAddress = async () => {
        handleAdditionalBillingAddressResponse(
            new CustomerRestApiClient().deleteAdditionalBillingAddressOnBillingService(id)
        )
    }

    const submitVat = async () => {
        const apiClient = new CustomerRestApiClient()

        await apiClient.setGroupVat(id, {vatNumber: vat})
            .then(response => {
                // console.log("vat", response)
                if (!response) {
                    setVatTransmitSuccess(true)
                    setVatError(null)
                    return
                }
                if (response?.status === 400 || response?.status === "BAD_REQUEST") {
                    setVatError(response?.fieldErrors?.vatNumber)
                    return
                }

                if (response?.status === 428) {
                    setVatError(response?.message)
                    return
                }

                throw new Error(response?.message)

            })
            .catch(async (e) => {
                if (e?.status === 428) {
                    setVatError("VAT number must be set if address country is not Germany")
                    return
                }
                setVatError("Invalid value")
            })
    }

    const submitGroupAddress = async (values) => {
        const apiClient = new CustomerRestApiClient()

        await apiClient.setCompanyAddressOnUserManagementService(id, groupAddress)
            .then(response => {
                if (!response) {
                    setGroupTransmitSuccess(true)
                    return
                }

                if (response?.status === 428) {
                    setGroupTransmitSuccess(false)
                    setVatError("This field required")
                    return
                }

                if (response?.status === 400 || response?.status === "BAD_REQUEST") {
                    setGroupAddressErrors(response?.fieldErrors)
                    setGroupTransmitSuccess(false)
                    return
                }

                throw new Error(response?.message)
            })
            .catch(e => {
                Snackbar.Error("An error occurred")
            })
    }


    const handleSubmit = async () => {
        setSending(true)
        await submitGroupAddress()

        await submitVat()

        if (useAdditionalBillingAddress) {
            await submitCompanyAddress()
        } else if (clearAdditionalBillingAddress) {
            await deleteAdditionalBillingAddress()
        }

        setSending(false)
    }

    useEffect(() => {

        if (!vatTransmitSuccess) {
            return
        }

        if (additionalBillingAddressTransmitSuccess && groupTransmitSuccess) {
            next()
        }

        if (groupTransmitSuccess && useAdditionalBillingAddress === false) {
            next()
        }
        // eslint-disable-next-line
    }, [additionalBillingAddressTransmitSuccess, groupTransmitSuccess, useAdditionalBillingAddress, vatTransmitSuccess])


    useEffect(() => {

        async function f() {
            const groupApiClient = new CustomerRestApiClient()
            const subscriptionApiClient = new CustomerRestApiClient()

            try {
                const groupAddress = await groupApiClient.getCompanyAddressFromUserManagementService(id)
                const companyAddress = await subscriptionApiClient.getCompanyAddressFormBillingService(id)

                subscriptionApiClient.getGroupVat(id)
                    .then(response => {
                        setVat(response.vatNumber)
                    })
                    .catch(e => {
                        Logger.exception(e)
                    })

                setGroupAddress(groupAddress)
                setAdditionalBillingAddress(companyAddress)

                if (companyAddress?.title) {
                    setUseAdditionalBillingAddress(true)
                }
            } catch (e) {
                Logger.exception(e)
                if (e.status) {
                    return
                }

                Snackbar.Error(e)
            }
        }

        if (id !== null) {
            f()
        }
    }, [id])

    useEffect(() => {
        if (groupAddress && additionalBillingAddress && countryList.length > 0) {
            setLoading(false)
        }
    }, [groupAddress, additionalBillingAddress, countryList])


    return <div style={{width: "40rem"}}>
        <Row>
            <Col>
                <Headline>{t("addressSlide.headline")}</Headline>
            </Col>
        </Row>
        {loading ? <Spinner/> :
            <>
                <Row style={{marginBottom: "2rem"}}>
                    <Col>

                        <GreyBoxSimpleHead title={t("addressSlide.companyAddress")}
                                           text={t("addressSlide.companyAddress.desc")}/>
                        <BillingAddress countries={countryList} disableAction={true} errors={groupAddressErrors}
                                        address={groupAddress}
                                        onChange={address => setGroupAddress(address)}/>

                    </Col>
                </Row>
                <Row style={{marginBottom: "2rem"}}>
                    <Col>
                        <Subheadline>{t("vat.headline")}</Subheadline>
                        <Input isValid={vatError} type={"text"} value={vat}
                               onChange={(event) => setVat(event.target.value)}
                               name={"vat"}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FlexRow style={{visibility: useAdditionalBillingAddress ? "hidden" : "visible", marginBottom: "2rem"}}
                                 justifyContent={"space-between"}>
                            <GhostButton onClick={handleBack}>
                                {t("billingAddress.abort")}
                            </GhostButton>

                            <Button style={{marginLeft: "1rem"}} disabled={isSending} onClick={handleSubmit}>
                                {t("billingAddress.save")} {isSending && <ButtonSpinner/>}
                            </Button>
                        </FlexRow>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GreyBoxHead checkbox selected={useAdditionalBillingAddress}
                                     onSelect={() => {
                                         setGroupTransmitSuccess(false)
                                         setAdditionalBillingAddressTransmitSuccess(false)
                                         setVatTransmitSuccess(false)

                                         setUseAdditionalBillingAddress(prevState => {
                                             setClearAdditionalBillingAddress(prevState);

                                             return !prevState;
                                         })
                                     }}
                                     title={t("addressSlide.useDifferentBillingAddress")}
                                     text={t("addressSlide.useDifferentBillingAddress.desc")}>
                        </GreyBoxHead>
                        <BillingAddress countries={countryList} disableAction={true}
                                        style={{display: useAdditionalBillingAddress ? "block" : "none"}}
                                        errors={additionalBillingAddressErrors} address={additionalBillingAddress}
                                        onChange={address => setAdditionalBillingAddress(address)}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FlexRow style={{visibility: !useAdditionalBillingAddress ? "hidden" : "visible"}}
                                 justifyContent={"space-between"}>
                            <GhostButton onClick={handleBack}>
                                {t("billingAddress.abort")}
                            </GhostButton>

                            <Button style={{marginLeft: "1rem"}} disabled={isSending} onClick={handleSubmit}>
                                {t("billingAddress.save")} {isSending && <ButtonSpinner/>}
                            </Button>
                        </FlexRow>

                    </Col>
                </Row>
            </>
        }
    </div>
}
