export function createAction(type, payload) {
    return {type, payload}
}

export class Actions {
    static setSaving(saving) {
        return createAction("setSaving", saving)
    }

    static setStep(step) {
        return createAction("setStep", step)
    }

    static setToken(token) {
        return createAction("setToken", token)
    }

    static setAllowNext(allowNext) {
        return createAction("setAllowNext", allowNext)
    }

    static setDistribution(distribution) {
        return createAction("setDistribution", distribution)
    }

    static setSellingPrice(sellingPrice) {
        return createAction("setSellingPrice", sellingPrice)
    }

    static setTokenString(string) {
        return createAction("setTokenString", string)
    }

    static setTokenValidation(message) {
        return createAction("setTokenValidation", message)
    }

    static setPriceValidation(validation) {
        return createAction("setPriceValidation", validation)
    }

    static setSwitchImmediate(switchImmediate) {
        return createAction("setSwitchImmediate", switchImmediate)
    }
}


