import {EntityRestApiClient, GenericUrlProvider, RestApiClient} from "@greenbone/cloud-component-library"
import {globalInstanceStorage} from "../../../../../GlobalSingle"


function ErrorHandler(e) {
    // if (e.status === 400 || e.status === 500) {
    throw e
    // } else {
    //     Snackbar.Error(e);
    // }
}


export class RangeApiClient extends EntityRestApiClient {

    constructor(apiClient = new RestApiClient(globalInstanceStorage.getFetchClient(), ErrorHandler),
                urlProvider = new GenericUrlProvider("/usermanagement/admin/customers")) {
        super(apiClient, urlProvider)
        this.apiClient = apiClient
    }

    getAll() {
        return this.apiClient.get(`/billing/admin/pricing`)
    }


    setMetadata(everything) {
        return this.apiClient.put(`/billing/admin/pricing/config`, everything)
    }


    setRanges(everything) {
        return this.apiClient.put(`/billing/admin/pricing/ranges`, everything)
    }


}
