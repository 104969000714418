import {useReducer} from "react"
import {slideInInitialState, slideInReducer} from "./SlideInReducer"
import {closeSlideIn, openSlideIn} from "./actions"
import {combineReducers} from "./combineReducers"


export function useSlideInState() {
    const [rootReducer, initialState] = combineReducers({
        slideIn: [slideInReducer, slideInInitialState]
    })

    const [state, dispatch] = useReducer(rootReducer, initialState)


    const setOpen = id => {
        dispatch(openSlideIn(id))
    }

    const setClosed = () => {
        dispatch(closeSlideIn())
    }

    return {isOpen: state.slideIn.isOpen, setOpen, setClosed}

}