import * as Sentry from "@sentry/browser"
import React from "react"
import ReactDOM from "react-dom"
import {App} from "./app/App"
import * as serviceWorker from "./serviceWorker"
import "bootstrap/dist/css/bootstrap.css"
import {AuthenticationWrapperFactory} from "./AuthenticationWrapper"
import {globalInstanceStorage} from "./app/GlobalSingle"
import {BASEURL, IS_API_MOCKED, IS_DEVELOP, IS_LOCAL, SENTRY_KEY, VERSION} from "./Constants"

import "./i18n"


Sentry.init({
    dsn: SENTRY_KEY,
    attachStacktrace: true,
    enabled: !IS_LOCAL,
    release: VERSION,
    environment: IS_DEVELOP ? "DEV" : "PROD"
})


let AuthWrapper = AuthenticationWrapperFactory(BASEURL, globalInstanceStorage, false, false, "/mssp-admin/public/config/realm/msp")


function main() {
    if (IS_API_MOCKED) {
        if (window.location.pathname === "/ui/vmssp/admin") {
            window.location.pathname = "/ui/vmssp/admin/"
            return
        }

        const {worker} = require("./mocks/browser")
        worker.start({
            serviceWorker: {
                url: "/ui/vmssp/admin/mockServiceWorker.js"
            }
        })
    }


    ReactDOM.render(<AuthWrapper app={App}></AuthWrapper>, document.getElementById("root"))


    if (!IS_API_MOCKED) {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister()
    }


}

main()


