import {withStyles, withTheme} from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import React from "react"
import {compose} from "redux"
import styled from "styled-components"

const styles = theme => ({
    progressInline: {
        margin: "0.31rem auto"
    }
})

const StyledCircularProgressWrapper = styled.div`
  display: flex;  
  justify-content: center;
  align-items: center;
  
  .MuiCircularProgress-colorPrimary {
    color: ${props => props.theme.button.normal.background};
  }
`

class Loader extends React.Component {


    render() {
        const {classes} = this.props

        const className = classes.progress
        let size = "4.50rem"


        return (
            <StyledCircularProgressWrapper align="center">
                <CircularProgress
                    className={className}
                    size={size}
                    color="primary"/>
            </StyledCircularProgressWrapper>
        )
    }
}

export default compose(withTheme, withStyles(styles))(Loader)

