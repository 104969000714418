import React, {useEffect, useState} from "react"
import {
    AlertBox,
    AlertBoxType,
    Checkbox,
    Headline,
    Paragraph,
    Snackbar,
    useApiRequest
} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {RegistrationRestApiClient} from "./RegistrationRestApiClient"
import {SaveButton} from "../../../../SaveButton"

export function RegistrationForm() {
    const apiClient = new RegistrationRestApiClient()
    const [data, loading] = useApiRequest(apiClient.getStatus.bind(apiClient))

    const [saving, setSaving] = useState(false)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        setDisabled(data?.isUserRegistrationEnabled || false)
    }, [data])

    const handleSave = e => {
        e.preventDefault()

        setSaving(true)
        apiClient.setStatus(!!disabled)
            .then(response => {
                if (response?.status === "BAD_REQUEST") {
                    Snackbar.Error("Could not save preference")
                    return
                }
                Snackbar.Success("Saved Successfully")
            })
            .catch(e => Snackbar.Error(e))
            .finally(() => setSaving(false))
    }

    return <form onSubmit={handleSave}>
        <Row>
            <Col>
                <Headline>
                    Registration
                </Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <Checkbox checked={disabled}
                          value={disabled}
                          onChange={() => setDisabled(!disabled)}
                          label={"Enable users to register themselves."}/>
            </Col>
        </Row>
        {(!disabled && !loading) &&
        <Row>
            <Col>
                <AlertBox type={AlertBoxType.Warning}>
                    <Paragraph>
                        Your costumers can't signup themselves. All changes and registration has to be done by you.
                        We recommend only using this while you're initially setting up your platform.
                    </Paragraph>
                </AlertBox>
            </Col>
        </Row>
        }
        <Row>
            <Col>
                <SaveButton saving={saving}>
                    Save
                </SaveButton>
            </Col>
        </Row>


    </form>

}
