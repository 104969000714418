import {globalInstanceStorage} from "../../GlobalSingle";


export class StyleRestApiClient {

    constructor() {
        this.httpClient = globalInstanceStorage.getFetchClient();
    }

    async getStyles() {
        const response = await this.httpClient.get("/vmssp-admin/config/");
        return JSON.parse(await response.text());
    }

    setStyles(styles) {
        return this.httpClient.put("/vmssp-admin/config", styles);
    }
}
