import React from "react"
import ReactQuill from "react-quill"
import DOMPurify from "dompurify"
import {Snackbar} from "@greenbone/cloud-component-library"
import "./quill.snow.css"

export function Editor({value, setValue}) {
    const modules = {
        toolbar: [
            [{"header": [1, 2, 3, 4, false]}],
            ["bold", "italic", "underline", "blockquote"],
            [{"list": "ordered"}, {"list": "bullet"}],
            ["link"],
            ["clean"]
        ]
    }


    const onChange = html => {
        DOMPurify.sanitize(html, {ADD_ATTR: ["target"]})
        const removed = DOMPurify.removed
        if (Array.isArray(removed)) {
            if (removed.length > 0) {
                Snackbar.Error("Element is not allowed")
                return
            }
        }

        setValue(html)
    }

    return (
        <>
            <ReactQuill theme={"snow"} value={value} onChange={onChange} modules={modules}/>
        </>
    )
}
