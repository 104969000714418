var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { FlexRow } from "@greenbone/cloud-component-library";
import Check from "../assets/done-24px.svg";
import Forbidden from "../assets/forbidden.svg";
import Key from "../assets/vpn_key-24px.svg";
import MailResend from "../../../../../assets/icons/email-action-edit.svg";
import Deleted from "../../../../../assets/icons/person_off.svg";
import Deleting from "../../../../../assets/icons/auto_delete.svg";
import { Logger } from "../../../../Logger";
var Icon = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n"], ["\n  width: 20px;\n  height: 20px;\n\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n"])));
function resolveIcon(status) {
    switch (status) {
        case "ACTIVE":
            return _jsx(Check, {});
        case "INACTIVE":
            return _jsx(Forbidden, {});
        case "LOCKED":
            return _jsx(Key, {});
        case "INVITED":
            return _jsx(MailResend, {});
        case "DELETED":
            return _jsx(Deleted, {});
        case "DELETE_REQUESTED":
            return _jsx(Deleting, {});
        default:
            Logger.error("Unknown customer status ".concat(status));
            return "";
    }
}
export var Status = function (_a) {
    var status = _a.status;
    // @ts-ignore
    return _jsx(FlexRow, { children: _jsx(Icon, { children: resolveIcon(status) }) });
};
var templateObject_1;
