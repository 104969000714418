var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
var IconContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ETrends;
(function (ETrends) {
    ETrends["UP"] = "UP";
    ETrends["DOWN"] = "DOWN";
    ETrends["SAME"] = "SAME";
})(ETrends || (ETrends = {}));
export var Trend = function (_a) {
    var trend = _a.trend;
    switch (trend) {
        case ETrends.DOWN:
            return _jsx(IconContainer, { children: _jsx(TrendingDownIcon, {}) });
        case ETrends.UP:
            return _jsx(IconContainer, { children: _jsx(TrendingUpIcon, {}) });
        default:
            return _jsx(IconContainer, { children: _jsx(TrendingFlatIcon, {}) });
    }
};
var templateObject_1;
