import React, {useContext, useState} from "react"
import {AbortButton, FlexRow, Input, Spinner} from "@greenbone/cloud-component-library"
import {Table, TableCell, TableRow} from "@greenbone/cloud-dynamic-table"
import {isNumberPrimitive} from "@greenbone/cloud-validation-library"
import {IconButton} from "@material-ui/core"
import {Edit} from "@material-ui/icons"
import {SaveButton} from "../../../../../SaveButton"
import {RangeContext} from "../RangeContext"
import {createGlobalStyle} from "styled-components"


/*TODO - jwerremeyer fix properly in table
* It's an asbestos bandage - a horrible fix.
*  */
const GlobalStyles = createGlobalStyle`
  th.MuiTableCell-root:nth-child(2),
  th.MuiTableCell-root:nth-child(3),
  th.MuiTableCell-root:nth-child(4) {
    text-align: right;
  }
`


function getPrice(value) {
    if (isNumberPrimitive(value)) {
        return `${parseFloat(value).toFixed(2)} €`
    }
    try {
        return `${parseFloat(value).toFixed(2)} €`
    } catch (e) {
        return value
    }
}

const Entry = ({row, dataset}) => {

    const [oldSellingPrice] = useState(dataset.sellingPrice)
    const [sellingPrice, setSellingPrice] = useState(dataset.sellingPrice)
    const [edit, setEdit] = useState(false)
    const [error, setError] = useState(null)


    const {sending, setRange} = useContext(RangeContext)

    return <TableRow>
        <TableCell>
            {`${dataset.minAmount} - ${dataset.maxAmount}`}
        </TableCell>
        <TableCell style={{textAlign: "right"}}>
            {dataset.purchasePrice.toFixed(2)} €
        </TableCell>
        <TableCell style={{textAlign: "right"}}>
            {
                edit ? <><Input isValid={error} onChange={e => setSellingPrice(e.target.value)}
                                value={sellingPrice}/> </> : getPrice(sellingPrice)
            }
        </TableCell>
        <TableCell style={{textAlign: "right"}}>
            {edit ? <FlexRow justifyContent={"flex-end"}>
                    <SaveButton saving={sending} onClick={() => {
                        setError(null)
                        setRange({...dataset, sellingPrice})
                            .then(response => {
                                if (!response || response?.prices) {
                                    setEdit(false)
                                } else {
                                    setSellingPrice(oldSellingPrice)
                                    setError("Invalid value")
                                }
                            })
                    }}>Save</SaveButton>
                    <AbortButton onClick={() => {
                        setEdit(false)
                        setError(null)
                    }}>Abort</AbortButton>
                </FlexRow> :
                <IconButton onClick={() => setEdit(true)}>
                    <Edit/>
                </IconButton>
            }
        </TableCell>
    </TableRow>
}


export function RangeList() {
    const {data} = useContext(RangeContext)

    const columns = [
        {
            Header: "IP-Range",
            accessor: dataset => `${dataset.minAmount} - ${dataset.maxAmount}`
        },
        {
            Header: "Purchase Price",
            accessor: "purchasePrice",
            headerStyle: {textAlign: "right"}
        },
        {
            Header: "Your Sales Price",
            accessor: "sellingPrice"
        },
        {
            Header: "Action",
            id: "actions"
        }
    ]
    if (!Array.isArray(data?.prices)) {
        return <Spinner/>
    }


    return <>
        <GlobalStyles/>
        <Table searchableColumns={[""]} actions={<div/>} data={data.prices} entryGenerator={Entry} columns={columns}/>
    </>
}

