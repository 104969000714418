var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var ESeverityType;
(function (ESeverityType) {
    ESeverityType["HIGH"] = "HIGH";
    ESeverityType["MEDIUM"] = "MEDIUM";
    ESeverityType["LOW"] = "LOW";
})(ESeverityType || (ESeverityType = {}));
export var SeverityHeader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\n  color: white;\n  border-radius: 3px;\n  padding: 0.4rem;\n\n\n  ", "\n"], ["\n\n  color: white;\n  border-radius: 3px;\n  padding: 0.4rem;\n\n\n  ", "\n"])), function (props) {
    var severity = props.severity;
    switch (severity) {
        case ESeverityType.HIGH:
            return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["background: #D40155;"], ["background: #D40155;"])));
        case ESeverityType.MEDIUM:
            return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["background: #FCB800;"], ["background: #FCB800;"])));
        case ESeverityType.LOW:
            return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["background: #7DB5D0;"], ["background: #7DB5D0;"])));
        default:
            return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["background: #4c4c4c;"], ["background: #4c4c4c;"])));
    }
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
