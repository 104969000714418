import * as HttpStatuscode from "http-status-codes"

import {
    ApiClient,
    BadRequestError,
    InvalidStatusCodeError,
    NotFoundError,
    ServiceNotAvailableError
} from "@greenbone/cloud-component-library"

import {HostValidationUrlProvider} from "./HostValidationUrlProvider"
import {globalInstanceStorage} from "../../GlobalSingle"


const ValidationType = {
    Admin: "ADMIN",
    Contact: "CONTACT"
}

export class HostValidationApiClient extends ApiClient {

    constructor(apiClient = globalInstanceStorage.getFetchClient()) {
        super();
        this.urlProvider = new HostValidationUrlProvider();
        this.httpJsonClient = apiClient;
    }

    async getApprovalRequests() {
        const response = await this.httpJsonClient.get(this.urlProvider.approvalRequests());
        if (response && response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text());
        }

        this.handleError(response.status);
    }

    async getHistoricalApprovalRequest() {
        const response = await this.httpJsonClient.get(this.urlProvider.historicalApprovalRequests());
        if (response && response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text());
        }
    }

    async approveHost(approvalRequest) {
        if (approvalRequest.validationType === ValidationType.Admin) {
            return this.approveHostAdmin(approvalRequest.id);
        } else if (approvalRequest.validationType === ValidationType.Contact) {
            return this.approveHostContact(approvalRequest.id);
        } else {
            throw new Error("Invalid validation type");
        }
    }

    async rejectHost(approvalRequest) {
        if (approvalRequest.validationType === ValidationType.Admin) {
            return this.rejectHostAdmin(approvalRequest.id);
        } else if (approvalRequest.validationType === ValidationType.Contact) {
            return this.rejectHostContact(approvalRequest.id);
        } else {
            throw new Error("Invalid validation type");
        }
    }

    async approveMultipleHosts(approvalRequests) {
        const apiClient = new HostValidationApiClient();
        return Promise.all(approvalRequests.map(r => apiClient.approveHost(r)));
    }

    async rejectMultipleHosts(approvalRequests) {
        const apiClient = new HostValidationApiClient();
        return Promise.all(approvalRequests.map(r => apiClient.rejectHost(r)));
    }

    async approveHostAdmin(token) {
        const response = await this.httpJsonClient.put(this.urlProvider.approveHostAdmin(token));
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return;
        }

        this.handleError(response.status);
    }

    async rejectHostAdmin(token) {
        const response = await this.httpJsonClient.put(this.urlProvider.rejectHostAdmin(token));
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return;
        }

        this.handleError(response.status);
    }

    async approveHostContact(token) {
        const response = await this.httpJsonClient.put(this.urlProvider.approveHostContact(token));
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return;
        }

        this.handleError(response.status);
    }

    async rejectHostContact(token) {
        const response = await this.httpJsonClient.put(this.urlProvider.rejectHostContact(token));
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return;
        }

        this.handleError(response.status);
    }

    handleError(code) {
        if (!code) {
            throw new ServiceNotAvailableError();
        }
        switch (code) {
            case HttpStatuscode.NOT_FOUND:
                throw new NotFoundError();
            case HttpStatuscode.UNPROCESSABLE_ENTITY:
                throw new BadRequestError();
            default:
                throw new InvalidStatusCodeError(code);
        }
    }
}
