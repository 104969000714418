import React from "react"
import {Bold, Caption} from "@greenbone/cloud-component-library"
import {ImageUpload} from "./ImageUpload"
import {IllustrationList} from "./IllustrationList"

export function SplitLayoutConfiguration({
                                             loginBigImageMime,
                                             loginBigImage,
                                             onImageUpload,
                                             loginImageOrientation,
                                             illustrationContrastColor,
                                             illustrationImage,
                                             loginScreenBackgroundColor,
                                             onChange
                                         }) {


    return <>
        <Caption>Background Image</Caption>

        <Bold>Configure the image for the login screen. Alternatively select a predefined illustration.</Bold>
        <br/>
        <Bold>Max size: 1 MB</Bold>

        <ImageUpload image={`${loginBigImageMime},${loginBigImage}`}
                     onChange={onImageUpload("loginBigImage")}
                     illustrationImage={illustrationImage}
                     passChange={onChange}
                     layout={loginImageOrientation}/>
        <IllustrationList illustrationImage={illustrationImage} onChange={onChange}/>
    </>
}
