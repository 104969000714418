import React, {useContext, useState} from "react"
import {TabSwitch} from "./TabSwitch"
import {LegalContext} from "./Context/LegalContext"
import {Editor} from "./Editor"


export function Imprint(props) {

    const {data, setImprintDE, setImprintEN} = useContext(LegalContext)

    const {imprint} = data

    const [english, setEnglish] = useState(true)

    return <>
        <TabSwitch active={english} onChange={setEnglish} activeText={"English"} inactiveText={"German"}/>

        {english && <Editor value={imprint.en} setValue={setImprintEN}/>}
        {!english && <Editor value={imprint.de} setValue={setImprintDE}/>}

    </>
}
