import {TableCell, TableRow} from "@greenbone/cloud-dynamic-table"
import {Link, useNavigate} from "react-router-dom"
import styled from "styled-components"
import DvrIcon from "@material-ui/icons/Dvr"
import {ApplicationUrlProvider} from "../../../../Layout/ApplicationUrlProvider"
import {Trend} from "../Trends"
import React from "react"
import {Dropdown, DropdownMenuItem} from "../../../../components/Dropdown"
import AdminPanelIcon from "../assets/admin_panel_settings.svg"
import {IconButton} from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import {JumpInComponent} from "../../JumpIn/JumpInComponent"

const Mail = styled.div`
  color: #777777;
  font-size: 0.7rem;
`

export function CompanyListRow({dataset}) {
    const navigate = useNavigate()

    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return <TableRow>
        <TableCell>
            {dataset.customer?.companyName}
            <Mail>
                {dataset.customer?.email}
            </Mail>
        </TableCell>
        <TableCell>{dataset.taskCount}</TableCell>
        <TableCell>{dataset.high}</TableCell>
        <TableCell>{dataset.medium}</TableCell>
        <TableCell>{dataset.low}</TableCell>
        <TableCell><Trend trend={dataset.trend}/></TableCell>
        <TableCell>
            {dataset?.customer?.adminJumpInEnabled ? <>
                <Dropdown id={dataset.groupId}>
                    <DropdownMenuItem text={"View Report"} icon={<DvrIcon/>}
                                      onClick={() => navigate(`${ApplicationUrlProvider.managedSecurity()}/${dataset.groupId}`)}/>
                    <DropdownMenuItem text={"Jump-In"} icon={<AdminPanelIcon/>} onClick={handleClickOpen}/>

                </Dropdown>

            </> : <>
                <Link to={`${ApplicationUrlProvider.managedSecurity()}/${dataset.groupId}`}>
                    <IconButton>
                        <DvrIcon/>
                    </IconButton>
                </Link>
            </>}

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <JumpInComponent customer={dataset.customer} handleClose={handleClose} groupId={dataset.groupId}/>
            </Dialog>
        </TableCell>
    </TableRow>
}
