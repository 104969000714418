import React from "react"
import {compose} from "redux"
import {Collapsible, ListMenuItem, ListMenuRootItem, MenuContext, TopLi} from "@greenbone/cloud-component-library"
import {ApplicationUrlProvider} from "./ApplicationUrlProvider"
import DatabaseSettings from "../../assets/icons/database-settings.svg"
import DesktopSettings from "../../assets/icons/desktop-monitor-settings.svg"
import Mail from "../../assets/icons/mail.svg"
import Legal from "../../assets/icons/legal-hammer.svg"
import {withLocation} from "../../hocs/withLocation";

export class _ConfigurationMenu extends React.Component {

    get isActive() {
        const {pathname} = this.props.location
        return pathname?.startsWith(ApplicationUrlProvider.configuration())
    }

    render() {
        return (
            <MenuContext.Consumer>
                {({minified, toggle, ...rest}) => {
                    const configurationOpen = rest[`configurationOpen`];
                    const toggleConfiguration = toggle("configurationOpen");

                    return <TopLi open={this.isActive}>
                        <ListMenuRootItem exact open={configurationOpen} extended={configurationOpen}
                                          onClick={(event) => {
                                              toggleConfiguration(event, true);
                                          }} to={ApplicationUrlProvider.configurationAppearance()}
                                          icon={<DatabaseSettings/>}
                                          text={"Configuration"}/>
                        <Collapsible style={{display: configurationOpen ? "block" : "none"}}>
                            <ListMenuItem open={this.isActive} minified={minified}
                                          to={ApplicationUrlProvider.configurationAppearance()} icon={DesktopSettings}>
                                Appearance
                            </ListMenuItem>
                            <ListMenuItem open={this.isActive} minified={minified}
                                          to={ApplicationUrlProvider.configurationMailserver()} icon={Mail}>
                                Mailserver
                            </ListMenuItem>
                            <ListMenuItem open={this.isActive} minified={minified}
                                          to={ApplicationUrlProvider.configurationLegal()} icon={Legal}>
                                Legal
                            </ListMenuItem>
                        </Collapsible>
                    </TopLi>;
                }}
            </MenuContext.Consumer>
        );
    }
}


export const ConfigurationMenu = compose(
    withLocation
)(_ConfigurationMenu);
