import React from "react"
import {FirstButton, FlexRow, SecondButton} from "@greenbone/cloud-component-library"

export function TabSwitch({active, onChange, activeText, inactiveText}) {



    return <FlexRow style={{marginBottom: "2rem"}} justifyContent={"start"}>
        <FirstButton

            active={active}
            onClick={() => onChange(true)}>
            {activeText}
        </FirstButton>
        <SecondButton
            style={{marginLeft: 0}}
            active={!active}
            onClick={() => onChange(false)}>
            {inactiveText}
        </SecondButton>
    </FlexRow>

}

