import { jsx as _jsx } from "react/jsx-runtime";
export var EDateDisplayType;
(function (EDateDisplayType) {
    EDateDisplayType[EDateDisplayType["Date"] = 0] = "Date";
    EDateDisplayType[EDateDisplayType["Time"] = 1] = "Time";
    EDateDisplayType[EDateDisplayType["DateTime"] = 2] = "DateTime";
    EDateDisplayType[EDateDisplayType["Month"] = 3] = "Month";
    EDateDisplayType[EDateDisplayType["Weekday"] = 4] = "Weekday";
})(EDateDisplayType || (EDateDisplayType = {}));
export function localizeDate(date, displayFormat) {
    switch (displayFormat) {
        case EDateDisplayType.Date: {
            return Intl.DateTimeFormat("en-US", {
                day: "numeric",
                month: "numeric",
                year: "numeric"
            }).format(date);
        }
        case EDateDisplayType.DateTime: {
            return Intl.DateTimeFormat("en-US", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric"
            }).format(date);
        }
        case EDateDisplayType.Time: {
            return Intl.DateTimeFormat("en-US", {
                hour: "numeric",
                minute: "numeric"
            }).format(date);
        }
        case EDateDisplayType.Month: {
            return Intl.DateTimeFormat("en-US", {
                month: "long"
            }).format(date);
        }
        case EDateDisplayType.Weekday: {
            return Intl.DateTimeFormat("en-US", {
                weekday: "long"
            }).format(date);
        }
    }
}
export function toDate(date, displayType) {
    if (!displayType) {
        displayType = EDateDisplayType.DateTime;
    }
    return localizeDate(date, displayType);
}
export var Date = function (_a) {
    var date = _a.date, displayType = _a.displayType;
    if (!displayType) {
        displayType = EDateDisplayType.DateTime;
    }
    return _jsx("span", { children: localizeDate(date, displayType) });
};
