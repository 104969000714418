import {EntityRestApiClient} from "./EntityRestApiClient"
import {RestApiClient} from "./RestApiClient"
import {globalInstanceStorage} from "../GlobalSingle"
import {GenericUrlProvider} from "./GenericUrlProvider"

function ErrorHandler(e) {
    // if (e.status === 400 || e.status === 500) {
    throw e
    // } else {
    //     Snackbar.Error(e);
    // }
}


export class CustomerRestApiClient extends EntityRestApiClient {

    constructor(apiClient = new RestApiClient(globalInstanceStorage.getFetchClient(), ErrorHandler),
                urlProvider = new GenericUrlProvider("/usermanagement/admin/customers")) {
        super(apiClient, urlProvider)
        this.apiClient = apiClient
    }


    async deleteCustomer(groupId) {
        return await this.apiClient.delete(`/usermanagement/admin/groups/${groupId}`)
    }

    async getUser(id, realm) {
        return await this.apiClient.post("/usermanagement/admin/user/userinformation", {realmId: realm, userIds: [id]})
    }

    async getUserList(ids, realm) {
        return await this.apiClient.post("/usermanagement/admin/user/userinformation", {realmId: realm, userIds: ids})
    }

    async getGroupVat(id) {
        return await this.apiClient.get(`/billing/admin/customer/${id}/vat`)
    }

    async setGroupVat(id, vat) {
        return await this.apiClient.put(`/billing/admin/customer/${id}/vat`, vat)
    }

    async create(entity) {
        return this.apiClient.post(`/usermanagement/admin/customer/managed/invite`, entity)
    }

    async setCompanyAddressOnUserManagementService(groupId, address) {
        return this.apiClient.put(`/usermanagement/admin/customer/${groupId}/company_address`, address)
    }

    async setCompanyAddressOnBillingService(groupId, address) {
        return this.apiClient.put(`/billing/admin/customer/${groupId}/billing_address`, address)
    }

    async deleteAdditionalBillingAddressOnBillingService(groupId) {
        return this.apiClient.delete(`/billing/admin/customer/${groupId}/billing_address`)
    }

    async getCompanyAddressFromUserManagementService(groupId) {
        return this.apiClient.get(`/usermanagement/admin/customer/${groupId}/company_address`)
    }

    async getCompanyAddressFormBillingService(groupId) {
        return this.apiClient.get(`/billing/admin/customer/${groupId}/billing_address`)
    }

    updateIpAmount(customerId, ipsInternal, ipsExternal, switchImmediate) {
        return this.apiClient.put(`/billing/admin/managed/customer/${customerId}/ipamount`, {
            ipsInternal,
            ipsExternal,
            switchImmediate
        })
    }

    updateIpDistribution(customerId, ipsInternal, ipsExternal) {
        return this.apiClient.put(`/billing/admin/managed/customer/${customerId}/ip-distribution`, {
            ipLimitExternal: ipsExternal,
            ipLimitInternal: ipsInternal
        })
    }


    async getProjectTokenDetails(token) {
        return await this.apiClient.get(`/billing/admin/projectkey/token/${token}`)
    }

    async submitProjectKey(customerId, options) {
        return await this.apiClient.post(`/billing/admin/managed/customer/${customerId}/projectkey`, options)
    }

    resendInvite(inviteId) {
        return this.apiClient.put(`/usermanagement/admin/customer/managed/invites/${inviteId}/resend`)
    }

    deleteInvite(inviteId) {
        return this.apiClient.delete(`/usermanagement/admin/customer/managed/invites/${inviteId}`)
    }

    setStatus(customerId, status) {
        return this.apiClient.put(`/usermanagement/admin/customers/${customerId}/status/${status}`)
    }

    migrateToMSP(groupId) {
        return this.apiClient.put(`/usermanagement/admin/customers/${groupId}/migrate`)
    }

    async getCountryCodes() {
        return await this.apiClient.get(`/usermanagement/public/countries`)
    }
}
