import React, {useContext, useEffect, useState} from "react"
import {InformationBarContext, InformationBarType, RestApiClient} from "@greenbone/cloud-component-library"
import {useNavigate} from "react-router-dom"
import {ApplicationUrlProvider} from "../../../Layout/ApplicationUrlProvider"
import {globalInstanceStorage} from "../../../GlobalSingle"

export const MissingMailConfigurationsContext = React.createContext({})

function ErrorHandler(e) {
    throw e
}

class MailConfigRestApiClient {
    constructor(apiClient = new RestApiClient(globalInstanceStorage.getFetchClient(), ErrorHandler)) {
        this.apiClient = apiClient
    }

    async getConfig() {
        return await this.apiClient.get(`/vmssp-admin/email`)
    }
}

export function MissingMailConfigurationContextProvider(props) {
    const {showBar} = useContext(InformationBarContext)
    const navigate = useNavigate()
    const [state, setState] = useState({
        dialogId: null,
        isMailConfigMissing: false
    })

    useEffect(() => {
        fetchConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function fetchConfig() {
        const apiClient = new MailConfigRestApiClient()
        apiClient.getConfig()
            .catch(e => {
                console.error(e)
                buildErrorMessage(e.status)
            })
    }

    function buildErrorMessage(statusCode) {
        if (statusCode !== 404) {
            return
        }

        const id = showBar(InformationBarType.Error,
            "You can't create new customers because the mailserver configuration is missing.",
            () => navigate(ApplicationUrlProvider.configurationMailserver()),
            "Configure Mailserver")
       setState({ dialogId: id, isMailConfigMissing: true})
    }

    return <MissingMailConfigurationsContext.Provider value={{...state, update: fetchConfig.bind(this)}}>
        {props.children}
    </MissingMailConfigurationsContext.Provider>

}
