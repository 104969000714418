import React, {useEffect, useState} from "react"
import {Col, Row} from "reactstrap"
import {Input, SearchableSelect} from "@greenbone/cloud-component-library"
import styled from "styled-components"


const A = styled.div`
  .MuiFormLabel-root {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }
`

export function BillingAddress({countries, onSubmit, address, errors, onClose, onChange, disableAction, ...other}) {

    const [state, setState] = useState(address)


    useEffect(() => {
        if (onChange) {
            onChange(state)
        }
    }, [state, onChange])

    const handleChange = event => {
        const {name, value} = event.target

        setState(prevState => ({...prevState, [name]: value}))
    }


    const handleSubmit = event => {
        event.preventDefault()
        onSubmit(state)
    }


    return <>
        <form onSubmit={handleSubmit}>
            <Row>
                <Col xs={3}>
                    <Input onChange={handleChange} isValid={errors?.title} value={state?.title} name={"title"}
                           margin={"normal"}
                           label={"Title"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input isValid={errors?.firstName} value={state?.firstName} name={"firstName"}
                           onChange={handleChange}
                           margin={"normal"}
                           label={"First Name"}/>
                </Col>
                <Col>
                    <Input isValid={errors?.lastName} value={state?.lastName} name={"lastName"} onChange={handleChange}
                           margin={"normal"}
                           label={"Last Name"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input isValid={errors?.companyName} value={state?.companyName} name={"companyName"}
                           onChange={handleChange} margin={"normal"}
                           label={"Company"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input isValid={errors?.streetAddress} value={state?.streetAddress} name={"streetAddress"}
                           onChange={handleChange}
                           margin={"normal"}
                           label={"Street Address"}/>
                </Col>
                <Col>
                    <Input isValid={errors?.houseNumber} value={state?.houseNumber} name={"houseNumber"}
                           onChange={handleChange} margin={"normal"}
                           label={"House Number"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input isValid={errors?.postalCode} value={state?.postalCode} name={"postalCode"}
                           onChange={handleChange} margin={"normal"}
                           label={"Postcode"}/>
                </Col>
                <Col>
                    <Input isValid={errors?.city} name={"city"} value={state?.city} onChange={handleChange}
                           margin={"normal"}
                           label={"City"}/>
                </Col>
            </Row>
            <Row style={{marginTop: "1.5rem"}}>
                <Col>
                    <A>
                        <SearchableSelect isValid={errors?.countryId} options={{
                            data: countries,
                            labelFieldName: "name",
                            valueFieldName: "id"
                        }} id={"COUNTRY-SELECT"} value={address?.countryId} onChange={handleChange} name={"countryId"}
                                          label={"Country"}/>
                    </A>

                </Col>
            </Row>

        </form>
    </>
}
