import React from "react"
import * as Sentry from "@sentry/react"
import {AlertIdentifier} from "@greenbone/cloud-component-library"


export class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        error: null
    }

    componentDidCatch(error, errorInfo /*React.ErrorInfo */) {
        this.setState({hasError: true, error})

        if (error) {
            Sentry.withScope(scope => {
                Object.keys(errorInfo).forEach(key => {
                    scope.setExtra(key, errorInfo[key])
                })
                Sentry.captureException(error)
            })
        }
    }

    render() {
        const {hasError, error} = this.state

        if (hasError) {
            return <AlertIdentifier exception={error}/>
        }

        return this.props.children
    }
}
