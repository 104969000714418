export class EntityRestApiClient {
    constructor(fetchClient, urlProvider) {
        this.client = fetchClient;
        // this.client.setHandleErrors(false);
        this.urlProvider = urlProvider;
    }

    getClient() {
        return this.client;
    }

    getUrlProvider() {
        return this.urlProvider;
    }

    async getAll() {
        return await this.client.get(this.urlProvider.getAll());
    }

    async getOne(id) {
        return await this.client.get(this.urlProvider.getOne(id));
    }

    async create(entity) {
        return await this.client.post(this.urlProvider.create(), entity);
    }

    async update(entity, id) {
        return await this.client.put(this.urlProvider.update(id), entity);
    }

    async delete(id) {
        return await this.client.delete(this.urlProvider.delete(id));
    }

}