import React, {useContext} from "react"
import {loadState, persistState, Snackbar} from "@greenbone/cloud-component-library"
import {CustomerOverviewRestApiClient} from "./service/CustomerOverviewRestApiClient"
import {CustomerRestApiClient} from "../../../service/CustomerRestApiClient"
import {DialogContext} from "../../../DialogContext"
import {Logger} from "../../../Logger"
import {JWT} from "../../../welcomeScreen/JWT"


export const CustomerContext = React.createContext({})


function createFilter(filter) {
    const newFilter = {...filter, groupStatus: filter.status}
    if (filter.status === "INVITED") {
        newFilter.groupStatus = null
    }

    return newFilter
}


export class CustomerProvider extends React.Component {

    state = {
        customers: [],
        filter: {
            "status": "ACTIVE",
            "serviceMode": null,
            "paymentOptions": []
        },
        isLoading: false
    }

    constructor(props) {
        super(props)
        this.apiClient = new CustomerOverviewRestApiClient()
        this.entityApiClient = new CustomerRestApiClient()
    }

    componentDidMount() {
        const CACHE_KEY = `${new JWT().subject()}-customerContext/2022.02.01`
        const loadedState = loadState(CACHE_KEY)
        if (loadedState) {
            this.setState({filter: loadedState}, () => this.fetchCustomers(true, false))
        } else {
            this.fetchCustomers(true)
        }
    }

    updateCustomerEmail = (groupId, newEmail) => {
        this.setState({isLoading: true})
        const index = this.state.customers.findIndex(c => c.groupId === groupId);
        if (index === -1) {
            return;
        }

        const newCustomers = [...this.state.customers];

        newCustomers[index] = {...newCustomers[index], contactEmail: newEmail};

        this.setState({customers: [...newCustomers], isLoading: false})
    }

    removeCustomer = (groupId) => {
        this.setState({isLoading: true})
        const index = this.state.customers.findIndex(c => c.groupId === groupId);
        if (index === -1) {
            return;
        }

        const newCustomers = [...this.state.customers];

        newCustomers.splice(index, 1); // Remove the item

        this.setState({customers: [...newCustomers], isLoading: false})
    }

    fetchCustomers = (showLoading, save = true) => {
        if (showLoading) {
            this.setState({isLoading: true})
        }

        if (save) {
            const CACHE_KEY = `${new JWT().subject()}-customerContext/2022.02.01`
            persistState(CACHE_KEY, this.state.filter)
        }

        const customerBillingInformationRequest = this.apiClient.getCustomerBillingInformation(createFilter(this.state.filter))
        const customerUserInformationRequest = this.apiClient.getCustomerUserInformation()

        Promise.all([customerBillingInformationRequest, customerUserInformationRequest])
            .then(([customerBillingInformation, customerUserInformation]) => {
                let customers = customerBillingInformation.map(bInfo => {
                    const uInfo = customerUserInformation.find(c => {
                        return c.groupId === bInfo.id
                    })

                    return {...uInfo, ...bInfo}
                })

                const hiddenCustomers = customerUserInformation.filter(user => !(customerBillingInformation.some(e => e.id === user.groupId)))

                if (this.state.filter.status === null
                    && this.state.filter.serviceMode === null
                    && this.state.filter.paymentOptions.length === 0
                ) {
                    customers = [...customers, ...hiddenCustomers]
                }

                if (this.state.filter.status === "INVITED") {
                    customers = [...customers, ...hiddenCustomers].filter(cu => cu.status === "INVITED")
                }


                this.setState({customers: [...customers], isLoading: false})
            })
            .catch(e => {
                this.setState({isLoading: false})
                this.handleUnknownError(e)
            })


    }

    cancelActiveSubscription = (groupId) => {
        return this.apiClient.cancelSubscription(groupId)
            .then(response => {
                this.fetchCustomers()
            })
            .catch(e => {
                this.handleUnknownError(e)
            })
    }


    setFilter = (filter) => {
        this.setState({filter})
    }

    applyFilter = () => {
        this.fetchCustomers(true)
    }

    getCustomer = id => {
        return this.state.customers.find(customer => customer.id === id)
    }

    handleUnknownError(e) {
        if (e?.message) {
            Snackbar.Error(e.message)
            Logger.error(`Invalid Status Code CODE: ${e?.status}, MESSAGE: ${e?.message}`)
            Logger.exception(e)
            return
        }


        Snackbar.Error("An Error Occurred")
        Logger.error(`Invalid Status Code apiClient.migrateToMSP CODE: ${e?.status}`)
        Logger.exception(e)
    }

    updateIpAmounts = (id, ipsInternal, ipsExternal, switchImmediate) => {
        return this.entityApiClient.updateIpAmount(id, ipsInternal, ipsExternal, switchImmediate)
            .then(response => {
                this.fetchCustomers()
                return response
            })
            .catch(e => {
                this.handleUnknownError(e)
            })
    }

    updateIpDistribution = (id, ipsInternal, ipsExternal) => {
        return this.entityApiClient.updateIpDistribution(id, ipsInternal, ipsExternal)
            .then(response => {
                this.fetchCustomers()
                return response
            })
            .catch(e => {
                this.handleUnknownError(e)
            })
    }

    setCustomerStatus = (id, status) => {
        return this.entityApiClient.setStatus(id, status)
            .then(response => {
                this.fetchCustomers()
                return response
            }).catch(e => {
                this.handleUnknownError(e)
            })
    }

    convertToManaged = id => {
        this.context.openConfirmDialog("Warning", "Are you sure you want to upgrade the company to a managed service company? This operation cannot be undone. Managed Service customers cannot book subscriptions on their own.", () => {
            const apiClient = new CustomerRestApiClient()

            apiClient.migrateToMSP(id)
                .then(response => {
                    this.fetchCustomers()
                    return response
                })
                .catch(e => {
                    if (e.status === 428) {
                        Snackbar.Error("Customer cannot be migrated.")
                        return
                    }
                    this.handleUnknownError(e)
                })
        })
    }

    resendInvite = inviteId => {
        const apiClient = new CustomerRestApiClient()
        apiClient.resendInvite(inviteId)
            .then(response => {
                this.fetchCustomers()
                Snackbar.Success("Invite has been resent.")
            })
            .catch(e => {
                this.handleUnknownError(e)
            })
    }

    deleteInvite = inviteId => {
        const apiClient = new CustomerRestApiClient()
        apiClient.deleteInvite(inviteId)
            .then(response => {
                this.removeCustomer(inviteId)
                Snackbar.Success("The invitation has been deleted.")
            })
            .catch(e => {
                if (e?.status === 404) {
                    Snackbar.Error("The invitation is not found.")
                } else {
                    this.handleUnknownError(e)
                }
            })
    }

    create = entity => {
        return this.entityApiClient.create(entity)
            .then(response => {
                this.fetchCustomers()
                return response
            })
            .catch(e => {

                if (e?.status === 201) {
                    this.fetchCustomers()
                    return
                }
                this.handleUnknownError(e)

            })
    }

    render() {
        return (
            <CustomerContext.Provider value={{
                ...this.state,
                applyFilter: this.applyFilter,
                setFilter: this.setFilter,
                customers: this.state.customers,
                filter: this.state.filter,
                getCustomer: this.getCustomer,
                updateIpAmounts: this.updateIpAmounts,
                setCustomerStatus: this.setCustomerStatus,
                cancelActiveSubscription: this.cancelActiveSubscription,
                convertToManaged: this.convertToManaged,
                create: this.create,
                resendInvite: this.resendInvite,
                deleteInvite: this.deleteInvite,
                update: this.fetchCustomers,
                updateIpDistribution: this.updateIpDistribution,
                updateCustomerEmail: this.updateCustomerEmail,
                removeCustomer: this.removeCustomer

            }}>
                {this.props.children}
            </CustomerContext.Provider>
        )
    }

}

CustomerProvider.contextType = DialogContext


export function useCustomer(id) {
    const {customers} = useContext(CustomerContext)
    return customers.find(customer => customer.id === id)

}

