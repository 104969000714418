export class MailSignatureUrlProvider {

    getAll() {
        return "/vmssp-admin/email/signature"
    }

    create() {
        return "/messageservice/email/check_signature"
    }

    update() {
        return "/vmssp-admin/email/signature"
    }


}
