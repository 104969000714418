import React, {useContext, useEffect, useMemo, useState} from "react"
import {AbortButton, FlexRow, Headline, Snackbar, Subheadline} from "@greenbone/cloud-component-library"
import {Col, Container, Row} from "reactstrap"
import {SaveButton} from "../../../../SaveButton"
import {useTranslation} from "react-i18next"
import {CustomerOverviewRestApiClient} from "../service/CustomerOverviewRestApiClient";
import {FormControl, Grid, Tooltip} from "@material-ui/core";
import {AssignmentInd} from "@material-ui/icons";
import Select, {components} from "react-select";
import {CustomerContext} from "../CustomerContext"


export function ChangeMainUserOfGroupForm({id, onClose}) {

    const [users, setUsers] = useState([])
    const [options, setOptions] = useState([])
    const [mainUserOption, setMainUserOption] = useState({})
    const [saving, setSaving] = useState(false)
    const [disable, setSDisable] = useState(true)
    const {t} = useTranslation()
    const apiClient = new CustomerOverviewRestApiClient()
    const {updateCustomerEmail} = useContext(CustomerContext)

    useEffect(() => {
        apiClient.getUsersOfGroup(id)
            .then(users => {
                setUsers(users);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const optionList = useMemo(() =>
            users
                .filter(user => user.email != null)
                .map(user => ({
                    value: user.userId,
                    label: user.email || 'No Email',
                    mainUser: user.mainUser,
                })),
        [users]);

    const defaultOption = useMemo(() =>
            options.find(option => option.mainUser),
        [options]);

    useEffect(() => {
        setOptions(optionList);
        setMainUserOption(defaultOption);
    }, [optionList, defaultOption]);

    if (!users) {
        return "Not Found"
    }

    const Option = (props) => {
        return (
            <components.Option {...props}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    {props.children}
                    {props.data.mainUser &&
                        <Tooltip title={t("customer.main.user")}>
                            <AssignmentInd/>
                        </Tooltip>}
                </div>
            </components.Option>
        )
    }

    const clearAll = () => {
        setUsers([]);
        setOptions([]);
        setMainUserOption({});
        setSaving(false);
    };
    const handleChange = (selectedOption) => {
        setMainUserOption(selectedOption)
        setSDisable(selectedOption.mainUser)
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setSaving(true)
        apiClient.changeMainUserOfGroup(id, mainUserOption.value)
            .then(() => {
                Snackbar.Success(t("customer.change.main.user.success"))
                updateCustomerEmail(id, mainUserOption.label)
            })
            .catch(error => {
                if (error.status) {
                    switch (error.status) {
                        case 400:
                            Snackbar.Error(t("customer.change.main.user.error4XX"))
                            break;
                        case 404:
                            Snackbar.Error(t("customer.change.main.user.error404"))
                            break;
                        case 422:
                            Snackbar.Error(t("customer.change.main.user.error4XX"))
                            break;
                        default:
                            break
                    }
                } else {
                    Snackbar.Error(error.message)
                }
            }).finally(() => {
            clearAll()
            onClose()
        });
    }

    return <>
        <Container style={{maxWidth: "40rem", minWidth: "40rem"}}>
            <Row>
                <Col>
                    <Headline>{t("customer.main.user")}</Headline>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Subheadline>{t("customer.change.main.user")}</Subheadline>
                </Col>
            </Row>

            <Row>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <AssignmentInd/>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <Select
                                value={mainUserOption}
                                onChange={handleChange}
                                options={options}
                                components={{Option}}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Row>

            <Row>
                <Col>
                    <FlexRow justifyContent={"space-between"}>
                        <AbortButton onClick={onClose}>Abort</AbortButton>
                        <SaveButton disabled={disable} saving={saving} onClick={handleSubmit}>Save</SaveButton>
                    </FlexRow>
                </Col>
            </Row>
        </Container>
    </>
}