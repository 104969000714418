import {InvalidStatusCodeError, SIODateTime} from "@greenbone/cloud-component-library"


const HttpMethod = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH"
};

export class RestApiClient {
    constructor(fetchClient, errorHandler) {
        this.fetchClient = fetchClient;
        this.errorHandler = errorHandler;
        this.handle = true;
    }

    /**
     * Allows to enable/disable the handling of errors
     * if disabled all errors will be thrown.
     * @param handleErrorsEnabled
     */
    setHandleErrors(handleErrorsEnabled) {
        this.handle = handleErrorsEnabled;
    }

    /**
     * Prepare an Object for rest transport and JSON stringify
     *
     * - convert all `Date` to `string` with format `YYYY-MM-DDTHH:mmZZ`
     *
     * @param value any json object
     * @returns {*}
     */
    prepareObject(value) {
        if (!value) {
            return;
        }
        Object.keys(value)
            .forEach(item => {
                if (value[item] && value[item].isSIODateTime) {
                    value[item] = value[item].toIsoString();
                }
            });
        return value;
    }

    /**
     * Parse a JSON response body
     * @param jsonString
     * @returns {null|any}
     */
    parseJsonText(jsonString) {
        const dateFormats = [
            {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z?$/},
            {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}Z?$/},
            {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}\+\d{4}/},
            {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/},
            {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/},
            {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{4}/}
        ];
        if (!jsonString) {
            return null;

        }
        return JSON.parse(jsonString, (key, value) => {
            if (typeof value === "string") {
                for (let i = 0; i < dateFormats.length; i++) {
                    if (dateFormats[i].regex.test(value)) {
                        return SIODateTime.parseIsoString(value);
                    }
                }
            }
            return value;
        });
    }

    async request(urlEndpoint, method, body) {
        return this.fetchClient.request(urlEndpoint, method, this.prepareObject(body))
            .then(async response => {
                if (response.status === 200 || response.status === 204 || response.status === 201)
                    return this.parseJsonText(await response.text())

                if (response.status === 400 || response.status === 409) {
                    return this.parseJsonText(await response.text());
                }

                throw new InvalidStatusCodeError(response.status);
            })
            .catch(e => {
                if (this.handle) {
                    this.errorHandler(e);
                } else {
                    throw e;
                }

            });
    }

    async get(urlEndpoint) {
        return this.request(urlEndpoint, HttpMethod.GET);
    }

    async post(urlEndpoint, body) {
        return this.request(urlEndpoint, HttpMethod.POST, body);
    }

    async delete(urlEndpoint) {
        return this.request(urlEndpoint, HttpMethod.DELETE);
    }

    async put(urlEndpoint, body) {
        return this.request(urlEndpoint, HttpMethod.PUT, body);
    }

    async patch(urlEndpoint, body) {
        return this.request(urlEndpoint, HttpMethod.PATCH, body);
    }
}
