import React, {Suspense, useEffect, useState} from "react"
import {BrowserRouter} from "react-router-dom"
import "./App.css"
import {InformationBarProvider, media, MenuProvider, StyleProvider} from "@greenbone/cloud-component-library"
import {ApplicationRouter} from "./Layout/ApplicationRouter"
import {createGlobalStyle} from "styled-components"
import {DialogProvider} from "./DialogContext"
import "@greenbone/cloud-component-library/build/Library.css"
import {JWT} from "./welcomeScreen/JWT"
import {MissingRole} from "./Layout/MissingRole"
import {CountryProvider} from "./Context/CountryContext"
import {createTheme, ThemeProvider} from "@material-ui/core/styles"
import {AdminSettingsProvider} from "./Context/AdminSettingsContext"

const theme = createTheme({
    palette: {
        primary: {
            light: "#81d950",
            main: "#66C430",
            dark: "#306118",
            contrastText: "#000"
        },
        secondary: {
            light: "#ff7961",
            main: "#f44336",
            dark: "#ba000d",
            contrastText: "#000"
        }
    }
})

const GlobalStyle = createGlobalStyle`

  .snackbar-container {
    z-index: 9999999;
  }

  html {
    font-size: 16px;

    @media (max-width: 1600px) {
      font-size: 13px;
    }

    ${media.isGiant`
        font-size: 13px;
    `}
    ${media.isDesktop`
        font-size: 13px;
    `}
    ${media.isTablet`
        font-size: 13px;
    `}
    ${media.isPhone`
        font-size: 13px;
    `}
  }
`


export function App(props) {

    const [hasRole, setHasRole] = useState(true)

    useEffect(() => {
        const jwt = new JWT()
        if (jwt.getRoles()) {
            if (jwt.getRoles().includes("vmssp-admin")) {
                setHasRole(true)
            }
        }
    }, [])


    return <>
        <Suspense fallback={<div>...loading language files</div>}>
            <StyleProvider>
                <GlobalStyle/>
                {hasRole ? <MenuProvider>
                    <ThemeProvider theme={theme}>
                        <CountryProvider>
                            <InformationBarProvider>
                                <DialogProvider>
                                    <AdminSettingsProvider>
                                        <BrowserRouter basename={"/ui/vmssp/admin"}>
                                            <ApplicationRouter/>
                                        </BrowserRouter>
                                    </AdminSettingsProvider>
                                </DialogProvider>
                            </InformationBarProvider>
                        </CountryProvider>
                    </ThemeProvider>
                </MenuProvider> : <MissingRole/>

                }

            </StyleProvider>
        </Suspense>
    </>


}



