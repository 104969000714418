//https://gcs-dev-mgmt.greenbone.io/usermanagement/admin/customers/93f25070-49c8-11eb-9270-d3c46628c461/migrate

import {rest} from "msw"
import {buildUrl} from "../buildUrl"


export const tempServiceHandlers = [

    rest.get(buildUrl("/reports/admin/reports/operatingSystemDistribution"), (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {"name": "Ubuntu 8.04", "count": 31124},
                {
                    "name": "Linux Kernel",
                    "count": 7677
                },
                {"name": "greenbone", "count": 839},
                {
                    "name": "Linux/Unix",
                    "count": 422
                },
                {"name": "Debian GNU/Linux 9", "count": 387}])
        )
    }),

    rest.get(buildUrl("/admin/reports/companyList"), (req, res, context) => {
        return res(
            context.status(200),
            context.json([
                {
                    "groupId": "1234-5678",
                    "high": 0,
                    "low": 0,
                    "maxSeverity": 0,
                        "medium": 0,
                        "taskCount": 0,
                        "trend": "UP"
                    }
                ]
            )
        )
    }),

    rest.get(buildUrl("/temp/vulnerability/:oid"), ((req, res, context) => {
        return res(
            context.status(200),
            context.json(
                {
                    "name": "PHP Denial of Service And Unspecified Vulnerabilities - 01 - Jul16 (Linux)",
                    "operatingSystem": "Ubuntu 8.04",
                    "severity": 10,
                    "qod": 30,
                    "port": "80/tcp",
                    "summary": "This host is installed with PHP and is prone\nto denial of service and unspecified Vulnerabilities",
                    "solution": "Upgrade to PHP version 5.5.32,\nor 5.6.18, or 7.0.3, or later.",
                    "solutionType": "VendorFix",
                    "affected": "PHP versions prior to 5.5.32, 5.6.x\n  before 5.6.18, and 7.x before 7.0.3 on Linux",
                    "insight": "The flaw is due an improper handling of zero-length\n  uncompressed data in 'ext/phar/phar_object.c' script.",
                    "cve": [],
                    "bid": [],
                    "cert": [],
                    "xref": [
                        "CVE-2016-4342",
                        "CVE-2016-2554",
                        "89154",
                        "83353",
                        "http://www.php.net/ChangeLog-7.php",
                        "http://www.openwall.com/lists/oss-security/2016/04/28/2"
                    ],
                    "thread": null,
                    "oid": "1.3.6.1.4.1.25623.1.0.808607",
                    "cvssBase": 0,
                    "family": "Web application abuses",
                    "nvtName": "PHP Denial of Service And Unspecified Vulnerabilities - 01 - Jul16 (Linux)",
                    "impact": "Successfully exploiting this issue allow\n  remote attackers to cause a denial of service (heap memory corruption) or\n  possibly have unspecified other impact.",
                    "qodType": "remote_banner_unreliable"
                }
            )
        )
    }))
]
