import React from "react"
import {useDropzone} from "react-dropzone"
import styled, {css} from "styled-components"
import {Button, FlexRow, Snackbar} from "@greenbone/cloud-component-library"
import eye from "../assets/eye.svg?url"
import {BigImageOrientation} from "./BigImageOrientation"
import checked from "../assets/check.svg?url"


const Outer = styled.section`
  margin-right: 2rem;
  position: relative;
  border: 2px solid transparent;
  border-radius: 3px;
  cursor: pointer;

  .active {
    right: 0;
    bottom: 0;
    background: #7EC14B;
    height: 25px;
    width: 25px;
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 80%;
    }
  }

  ${props => props.active && css`
    border-color: #7EC14B;

    .active {
      display: flex;
    }
  `}
  .dropzone {
    background: #E2E2E2;
    background-image: url("${eye}");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 33%;

    border-radius: 3px;
    height: 400px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      ${props => props.image && css`
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("${props => props.image}");
      `};

      width: 100%;
      height: 100%;
      background-size: contain;
    }
  }
`

export function ImageUpload(props) {

    const {layout, passChange, illustrationImage} = props

    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        accept: "image/jpeg, image/png, image/gif, image/jpg",
        onDrop(acceptedFiles, rejectedFiles, event) {

            if (rejectedFiles?.length >= 1) {
                Snackbar.Warning("Invalid file type")
            }

            if (acceptedFiles?.length >= 1) {
                props.onChange(acceptedFiles)
            }
        }
    })


    return (
        <div style={{marginBottom: "2rem"}}>
            <FlexRow>
                <Button {...getRootProps({className: "dropzone"})}>Upload Logo</Button>
            </FlexRow>
            <FlexRow>
                <Outer {...getRootProps({className: "dropzone"})} active={illustrationImage === null}
                       image={props.image}>
                    <div className="active">
                        <img src={checked} alt="Checked"/>
                    </div>
                    <div className={"dropzone"}>
                        <input {...getInputProps()} />
                        <p style={{margin: 0}}></p>
                    </div>
                </Outer>
                <BigImageOrientation layout={layout} onChange={passChange}/>
            </FlexRow>
        </div>
    )
}
