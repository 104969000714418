import {EntityRestApiClient} from "./EntityRestApiClient"
import {RestApiClient} from "./RestApiClient"
import {globalInstanceStorage} from "../GlobalSingle"
import {GenericUrlProvider} from "./GenericUrlProvider"

function ErrorHandler(e) {
    // if (e.status === 400 || e.status === 500) {
    throw e
    // } else {
    //     Snackbar.Error(e);
    // }
}


export class ManagedSecurityRestApiClient extends EntityRestApiClient {

    constructor(apiClient = new RestApiClient(globalInstanceStorage.getFetchClient(), ErrorHandler),
                urlProvider = new GenericUrlProvider("")) {
        super(apiClient, urlProvider)
        this.apiClient = apiClient
    }

    async getVulnerability(oid) {
        return this.apiClient.get(`/reports/admin/reports/oid/${oid}/details`)
    }

    async getAdminSettings() {
        return this.apiClient.get(`/usermanagement/admin/settings`)
    }

    async getReportList(groupId, taskId) {
        return this.apiClient.get(`/reports/admin/group/${groupId}/task/${taskId}/reportList`)
    }

    async getJumpInExchangeToken(groupId, password, totpToken) {
        return this.apiClient.post(`/usermanagement/admin/jumpIn/token`, {
            groupId,
            password,
            totpToken
        })
    }

    async getCompanyList() {
        return this.apiClient.get("/reports/admin/reports/companyList")
    }

    async getSeverityDistribution(groupId) {
        return this.apiClient.get(`/reports/admin/reports/group/${groupId}/severityDistribution`)
    }

    async getTaskList(groupId) {
        return this.apiClient.get(`/reports/admin/reports/group/${groupId}/taskList`)
    }

    async getOperatingSystemDistribution() {
        return this.apiClient.get("/reports/admin/reports/operatingSystemDistribution")
    }

    async getTopVulnerabilitiesLastWeek() {
        return this.apiClient.get("/reports/admin/reports/topVulnerabilitiesLastWeek")
    }

}
