import React from "react"
import {Caption, Flex, FlexRow, Headline, Subheadline} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import styled, {useTheme} from "styled-components"
import GMSP from "./assets/vMSP-GMSP Isometric-01.svg?url"
import GSP from "./assets/vMSP-GSC Isometric-01.svg?url"
import LinkIcon from "./assets/link.svg?url"

import ISO9100 from "./download/firstcolo_01 100 1700043_Main_DE (1).pdf"
import TUEV from "./download/firstcolo-GmbH-TUeV-geprueftes-Rechenzentrum-RZ-Kruppstrasse-26_02_2025.pdf"
import ISO27001 from "./download/firstcolo_ISO27001_total.pdf"

import IONOS9001 from "./download/Equinix-ISO-9001-27001-22301-Certificate-EN.pdf"
import IONOS27001 from "./download/IONOS-Holding-SE-DE-2022.pdf"
import {useTranslation} from "react-i18next"

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 550px;

  img {
    width: 450px;
    height: 450px;
  }
`

const Outer = styled(Flex)`
  justify-content: space-between;
  align-items: center;

  height: 100px;
  width: 100%;
  
  padding-right: 40px;
  padding-left: 40px;
  
  a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${props => props.theme.base};
  }
  
  span {
    max-width: 370px;
  }
`

const Link = ({href, text}) =>
    <Outer>
        <a href={href} target={"_blank"} rel="noopener noreferrer">
            <span>{text}</span>
            <div>
                <FlexRow>
                    Open Link <img src={LinkIcon} alt=""/>
                </FlexRow>
            </div>
        </a>
    </Outer>

const LinkContainer = styled.div`
  ${Outer}:nth-child(odd){
    background: #F7F7F7;
  }
`


export const CompliancePage = props => {
    const {base} = useTheme()

    const {t} = useTranslation()

    return <>
        <Row>
            <Col>
                <Headline>Compliance</Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <FlexRow justifyContent={"center"}>
                    <Subheadline style={{color: base}}>
                        <b>GMSP</b> Greenbone Managed Service Platform
                    </Subheadline>
                </FlexRow>

                <ImageContainer>
                    <img src={GMSP} alt="GMSP"/>
                </ImageContainer>
                <Caption>Links to Certifications (Infrastructure Partner) </Caption>
                <LinkContainer>
                    <Link text={t("compliancePage.tuev9001")} href={IONOS9001}/>
                    <Link text={t("compliancePage.tuev27001")} href={IONOS27001}/>
                </LinkContainer>

            </Col>
            <Col>
                <FlexRow justifyContent={"center"}>
                    <Subheadline style={{color: base}}>
                        <b>GSC</b> Greenbone Scan Cluster
                    </Subheadline>
                </FlexRow>

                <ImageContainer>
                    <img src={GSP} alt="GMSP"/>
                </ImageContainer>
                <Caption>Links to Certifications (Infrastructure Partner)</Caption>
                <LinkContainer>
                    <Link text={"ISO 9001 Certificate"} href={ISO9100}/>
                    <Link text={"TUEV Zertifikat"} href={TUEV}/>
                    <Link text={"ISO 27001 Certificate"} href={ISO27001}/>
                </LinkContainer>

            </Col>
        </Row>

    </>
}
