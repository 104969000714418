import { ActionConstants } from "./ActionConstants";
export function createAction(type, payload, fieldName) {
    if (fieldName) {
        return { type: type, payload: payload, fieldName: fieldName };
    }
    return { type: type, payload: payload };
}
export function openSlideIn(id) {
    return createAction(ActionConstants.OPEN_SLIDE_IN, { id: id });
}
export function closeSlideIn() {
    return createAction(ActionConstants.CLOSE_SLIDE_IN);
}
export function startLoading() {
    return createAction(ActionConstants.START_LOADING);
}
export function stopLoading() {
    return createAction(ActionConstants.STOP_LOADING);
}
export function setData(data, fieldName) {
    return createAction(ActionConstants.SET_DATA, data, fieldName);
}
