import {TableCell, TableRow} from "@greenbone/cloud-dynamic-table"
import React from "react"
import {ActionButton, FlexRow} from "@greenbone/cloud-component-library"
import {ValidationType} from "../ValidationType"

export class HostValidationTableRow extends React.Component {

    state = {
        _sending: false
    }

    rejectHostValidationEntry = (event, approvalRequest) => {
        this.setState({_sending: true})
        this.props.rejectHostValidationEntry(event, approvalRequest)
    }

    approveHostValidationEntry = (event, approvalRequest) => {
        this.setState({_sending: true})
        this.props.approveHostValidationEntry(event, approvalRequest)
    }


    render() {

        const approvalRequest = this.props.approvalRequest

        return (

            <TableRow>
                <TableCell>
                    {this.props.row.cells[0].render("Cell")}
                </TableCell>
                <TableCell>{approvalRequest.ipAddress}</TableCell>
                <TableCell>{approvalRequest.contactEmail}</TableCell>
                <TableCell>{approvalRequest.contactName}</TableCell>
                <TableCell>{(new Date(approvalRequest.requestedOn).toDateString())}</TableCell>
                <TableCell>{approvalRequest?.companyName}</TableCell>
                <TableCell>{approvalRequest?.userEmail}</TableCell>
                <TableCell>{approvalRequest.validationType}</TableCell>
                <TableCell>
                    {approvalRequest.validationType === ValidationType.Admin &&
                    <FlexRow>
                        <ActionButton onClick={event => this.approveHostValidationEntry(event, approvalRequest)}
                                      variant={"primary"}>
                            {this.state._sending ? "accepting..." : "Accept"}
                        </ActionButton>
                        <ActionButton onClick={event => this.rejectHostValidationEntry(event, approvalRequest)}
                                      variant={"secondary"}>
                            {this.state._sending ? "rejecting..." : "Reject"}

                        </ActionButton>
                    </FlexRow>}


                </TableCell>
            </TableRow>

        )
    }
}
