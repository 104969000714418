import React, {useMemo} from "react"
import {SIODateTime} from "@greenbone/cloud-component-library"
import {ResponsiveLine} from "@nivo/line"
import {EDateDisplayType, localizeDate} from "../../../../components/Date"

function createPoint(row, pointAccessor) {
    const dateTime = SIODateTime.parseIsoString(row.date)

    const weekday = (localizeDate(dateTime.toJsDate(), EDateDisplayType.Weekday))

    return {
        x: weekday,
        y: pointAccessor(row)
    }
}

function todo(data) {
    const high = [],
        medium = [],
        low = []

    for (let i = 0; i < data.length; i++) {
        const row = data[i]

        high.push(createPoint(row, row => row.high))
        medium.push(createPoint(row, row => row.medium))
        low.push(createPoint(row, row => row.low))
    }

    return [
        {
            id: "high",
            color: "#D4003E",
            data: high
        },
        {
            id: "medium",
            color: "#FCB800",
            data: medium
        },
        {
            id: "low",
            color: "#7DB5D0",
            data: low
        }
    ]
}

export function OverallSeverityTrend({data}) {

    const chartData = useMemo(() => todo(data.data), [data])


    return <div style={{position: "relative", height: "calc(100% - 2.5rem)"}}>
        <div style={{position: "absolute", width: "100%", height: "100%"}}>
            <LineChart data={chartData}/>
        </div>
    </div>

}

function LineChart({data}) {
    return <ResponsiveLine

        colors={d => {
            return d.color
        }}
        data={data}
        margin={{top: 50, right: 20, bottom: 50, left: 100}}
        xScale={{type: "point"}}
        yScale={{type: "linear", min: "auto", max: "auto", stacked: true, reverse: false}}
        yFormat=" >-.2f"
        curve="basis"
        axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Severity Count",
            legendOffset: -60,
            legendPosition: "middle"
        }}
        enablePoints={false}
        isInteractive={false}
        useMesh={false}
    />
}

