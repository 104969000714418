import React from "react"
import styled, {css, useTheme} from "styled-components"
import {Text} from "@greenbone/cloud-component-library"


const Iscon = styled.img`
  height: 2.50rem;
  margin-right: 1.25rem;
  max-width: 4rem;
`

const FlexRowWithSpace = styled.div`
  display: flex;
  margin-bottom: 1.88rem;
  ${props => props.center && css`align-items: center`}
`

const Headline = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 4px;
  color: ${props => props.theme.font.headline}
`


export function HelpLine(props) {


    const {icon, children, title, center} = props

    const Icon = icon

    let isValid = true

    const {button} = useTheme()

    try {
        isValid = React.isValidElement(React.createElement(Icon))
    } catch (e) {

    }

    return <FlexRowWithSpace center={center}>
        <div style={{color: button.normal.background}}>
            {isValid ? <Icon style={{height: "2.5rem", marginRight: "1.25rem", maxWidth: "3rem"}}/> :
                <Iscon src={Icon}/>
            }

        </div>
        <Text>
            {title && <Headline>{title}</Headline>}
            {children}
        </Text>
    </FlexRowWithSpace>

}
