import React from "react"
import {FlexRow, Headline} from "@greenbone/cloud-component-library"
import {FetchData} from "../FetchData"
import {CustomerOverviewRestApiClient} from "../../../Billing/Customers/service/CustomerOverviewRestApiClient"
import {JumpInDialog} from "../../JumpIn/JumpInDialog"

export function CompanyName({groupId}) {
    return <FlexRow alignItems={"center"}><FetchData
        request={() => new CustomerOverviewRestApiClient().getCustomerBillingInformation({groupIds: [groupId]})}>
        {({data}) => <FlexRow alignItems={"center"}>
            <Headline noMargin style={{marginRight: ".5rem"}}>
                {data.find(customer => customer.id === groupId)?.companyName || "Customer not found"}
            </Headline>
        </FlexRow>}
    </FetchData>
        <FetchData
            request={() => new CustomerOverviewRestApiClient().getCustomerUserInformation({groupIds: [groupId]})}
        >
            {({data}) => {
                const customer = data.find(customer => customer.groupId === groupId)
                return <FlexRow alignItems={"center"}>
                    {customer?.adminJumpInEnabled &&
                    <JumpInDialog customer={customer} groupId={groupId}/>
                    }

                </FlexRow>
            }}
        </FetchData>
    </FlexRow>
}