import React, {useEffect, useState} from "react"
import {Col, Row} from "reactstrap"
import {
    DashboardCellAutoGrid,
    DashboardCellBanner,
    DashboardCellIconTextHelper,
    FlexRow,
    Spinner
} from "@greenbone/cloud-component-library"
import MoneyIcon from "./assets/money.svg"
import MoneyStack from "./assets/money.svg"
import MoneyBag from "./assets/money-bag.svg"
import MoneyLetter from "./assets/money-letter.svg"
import SelfService from "./assets/self-service.svg"
import Project from "./assets/project.svg"
import RemotePay from "./assets/average.svg"


import {TotalPlans} from "./components/TotalPlans"
import {ExternalPlans} from "./components/ExternalPlans"
import {InternalPlans} from "./components/InternalPlans"
import {SalesDashboardRestApiClient} from "./service/SalesDashboardRestApiClient"
import {PlanDevelopment} from "./components/PlanDevelopment"
import {ErrorBoundary} from "../../../components/ErrorBoundary"
import { CSVDownload } from "./components/CSVDownload"

export function SalesDashboard(props) {

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function f() {
            const apiClient = new SalesDashboardRestApiClient()

            const response = await apiClient.getOverview()
            setData(response)
            setLoading(false)
        }

        f()
    }, [])

    if (loading) {
        return <Spinner/>
    }


    return <>
        <Row>
            <Col xs={3}>
                <DashboardCellBanner>Average purchase price per IP address</DashboardCellBanner>
                <DashboardCellIconTextHelper icon={<RemotePay/>}
                                             text={`${data?.averageIpPrice?.toFixed(2)} €`}/>
            </Col>
            <Col>
                <DashboardCellBanner>Billing Method Selected</DashboardCellBanner>
                <DashboardCellAutoGrid>
                    <DashboardCellIconTextHelper icon={<MoneyBag/>}
                                                 text={data?.serviceModes?.FREE || 0}
                                                 helper={"Free"}/>
                    <DashboardCellIconTextHelper icon={<MoneyLetter/>}
                                                 text={data?.serviceModes?.MANAGEDSERVICE}
                                                 helper={"MSP"}/>
                    <DashboardCellIconTextHelper icon={<SelfService/>}
                                                 text={data?.serviceModes?.SELFSERVICE}
                                                 helper={"Self Service"}/>
                    <DashboardCellIconTextHelper icon={<Project/>}
                                                 text={data?.serviceModes?.PROJECT || 0}
                                                 helper={"Project"}/>
                </DashboardCellAutoGrid>
            </Col>
        </Row>
        <Row>
            <Col>
                <TotalPlans count={data?.ipsInternal + data?.ipsExternal} data={[
                    {name: "intern", count: data?.ipsInternal},
                    {name: "extern", count: data?.ipsExternal}
                ]}/>
            </Col>
            <Col>
                <DashboardCellBanner>Total Purchase</DashboardCellBanner>
                <DashboardCellIconTextHelper icon={<MoneyStack/>}
                                             text={`${data?.totalPurchase?.toFixed(2)} €`}/>
            </Col>
            <Col>
                <DashboardCellBanner>Income Selfservice</DashboardCellBanner>
                <DashboardCellIconTextHelper icon={<MoneyIcon/>}
                                             text={`${data?.totalIncome?.toFixed(2)} €`}
                                             helper={""}/>

            </Col>
            <Col xs={3}>
                <DashboardCellBanner>Monthly billing data</DashboardCellBanner>
                <FlexRow alignItems={"center"} style={{height: "8.75rem", padding: "2rem"}}>
                    <CSVDownload />
                </FlexRow>
            </Col>
        </Row>
        <Row>
            <Col xs={4}>
                <ExternalPlans count={data?.ipsExternal} data={[
                    {name: "intern", count: data?.ipsInternal},
                    {name: "extern", count: data?.ipsExternal}
                ]}/>
                <InternalPlans count={data?.ipsInternal} data={[
                    {name: "intern", count: data?.ipsInternal},
                    {name: "extern", count: data?.ipsExternal}
                ]}/>
            </Col>
            <Col>
                <ErrorBoundary>
                    <PlanDevelopment/>
                </ErrorBoundary>

            </Col>
        </Row>
    </>

}
