import React, {useContext, useReducer, useState} from "react"
import {TableCell, TableRow} from "@greenbone/cloud-dynamic-table"
import styled from "styled-components"
import {
    Button,
    ButtonSpinner,
    FlexRow,
    GhostButton,
    Input,
    SlideIn,
    Snackbar
} from "@greenbone/cloud-component-library"
import {Status} from "./Status"
import {BillingMethod} from "./BillingMethod"
import Split from "../assets/split.svg"
import Reverse from "../assets/reverse.svg"
import Description from "../assets/description.svg"
import MailResend from "../../../../../assets/icons/email-action-edit.svg"
import {AmountForm} from "./AmountForm"
import {CustomerDetails} from "./CustomerDetails"
import {DialogContext} from "../../../../DialogContext"
import {CustomerContext} from "../CustomerContext"
import {ProjectKeyForm} from "./ProjectKeyForm"
import {ProjectKeyProvider} from "./ProjectKeyContext/ProjectKeyContext"
import VpnKeyIcon from "@material-ui/icons/VpnKey"
import {IconButton} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import DeleteIcon from "@material-ui/icons/Delete"
import PersonSearchIcon from "../assets/person_search.svg"
import {AddressSlide} from "./AddressSlide"
import {Menu, Separator, useContextMenu} from "react-contexify"
import "react-contexify/dist/ReactContexify.css"
import {DropdownMenuItem} from "../../../../components/Dropdown"
import {useTranslation} from "react-i18next"
import {CustomerRestApiClient} from "../../../../service/CustomerRestApiClient"
import {AssignmentInd} from "@material-ui/icons";
import {ChangeMainUserOfGroupForm} from "./ChangeMainUserOfGroupForm";

const initialState = {
    distributionOpen: false,
    editOpen: false,
    activeId: false,
    keyOpen: false
}

const Action = {
    OpenDistribution: "OpenDistribution",
    OpenEdit: "OpenEdit",
    OpenKey: "OpenKey",
    Close: "Close",
    OpenAddress: "OpenAddress",
    OpenChangeMainUser: "OpenChangeMainUser"
}


function reducer(state, action) {

    switch (action.type) {
        case Action.OpenDistribution : {
            return {...state, distributionOpen: true, activeId: action.activeId}
        }

        case Action.OpenEdit : {
            return {...state, editOpen: true, activeId: action.activeId}
        }

        case Action.OpenKey : {
            return {...state, keyOpen: true, activeId: action.activeId}
        }

        case Action.OpenAddress : {
            return {...state, addressOpen: true, activeId: action.activeId}
        }

        case Action.OpenChangeMainUser : {
            return {...state, changeMainUserOpen: true, activeId: action.activeId}
        }

        case Action.Close : {
            return {
                ...state,
                distributionOpen: false,
                editOpen: false,
                keyOpen: false,
                addressOpen: false,
                changeMainUserOpen : false,
                activeId: null
            }
        }

        default :
            return {...state}
    }
}

const Mail = styled.div`
  color: #777777;
  font-size: 0.7rem;
`


export const CustomerListRow = ({row, dataset}) => {


    const [{editOpen, distributionOpen, activeId, keyOpen, addressOpen, changeMainUserOpen}, dispatch] = useReducer(reducer, initialState)

    const {openCustomDialog, openConfirmDialog} = useContext(DialogContext)
    const {convertToManaged, resendInvite, deleteInvite} = useContext(CustomerContext)
    const MENU_ID = dataset.status === "INVITED" ? `MENU-${dataset.groupId}`: `MENU-${dataset.id}`
    const {show} = useContextMenu({
        id: MENU_ID
    })


    const DeleteCustomerConfirmDialog = ({showCancel, handleCancel, handleConfirm}) => {

        const [input, setInput] = useState("")
        const [sending, setSending] = useState(false)
        const {t} = useTranslation()

        const deleteCustomer = e => {
            e.preventDefault()

            if (input !== "DELETE") {
                Snackbar.Error("Please enter DELETE into the text field.")
                return
            }


            setSending(true)

            const apiClient = new CustomerRestApiClient()

            apiClient.deleteCustomer(dataset.groupId)
                .then(response => {
                    setSending(false)
                    if (response === null) {
                        handleConfirm()
                        Snackbar.Success("Customer is marked for deletion.")
                        return
                    }
                    throw e
                })
                .catch(e => {
                    setSending(false)

                    if (e?.status === 422) {
                        Snackbar.Error("Customer is already marked for deletion.")
                        return
                    }

                    Snackbar.Error(e.toString())
                })

        }

        return <div>
            {`Are you sure you want to delete
            ${dataset.companyName || dataset.contactEmail} ? `}
            <br/>

            Type <b>DELETE</b> to confirm.

            <Input type={"text"} onChange={e => setInput(e.target.value)}/>

            <FlexRow justifyContent={"flex-end"}>

                <GhostButton style={{marginRight: "1rem"}}
                             onClick={handleCancel}>{t("dialog.abort")}</GhostButton>
                <Button disabled={sending} onClick={deleteCustomer}>{t("dialog.confirm")}
                    {sending && <ButtonSpinner/>}
                </Button>

            </FlexRow>
        </div>

    }

    return <TableRow>
        <TableCell>
            {dataset.companyName}
            <Mail>
                {dataset.contactEmail}
            </Mail>
        </TableCell>
        <TableCell>
            {dataset?.serviceMode}
        </TableCell>
        <TableCell>
            <Status status={dataset?.status}/>
        </TableCell>
        <TableCell>
            {dataset?.ipsInternal}
        </TableCell>
        <TableCell>
            {dataset?.ipsExternal}
        </TableCell>
        <TableCell>
            <BillingMethod method={dataset?.paymentOption}/>
        </TableCell>
        <TableCell>
            {dataset?.averageIpPrice && `${dataset.averageIpPrice?.toFixed(2)} €`}
        </TableCell>
        <TableCell style={{minWidth: "170px"}}>

            {(dataset.status !== "DELETED" && dataset.status !== "DELETE_REQUESTED") && <>
                {dataset.status !== "INVITED" &&
                    <>
                        <IconButton
                            onClick={show}
                            aria-haspopup="true"
                            aria-controls="dropdown-menu">
                            <MoreVertIcon/>
                        </IconButton>


                        <Menu id={MENU_ID}>

                            {dataset.serviceMode === "SELFSERVICE" &&
                                <DropdownMenuItem text={"Convert to Managed Customer"}
                                                  icon={<Split style={{
                                                      width: "24px",
                                                      height: "24px"
                                                  }}/>}
                                                  onClick={() => convertToManaged(dataset.id)}/>}

                            {dataset.status !== "INVITED" && <>
                                <DropdownMenuItem text={"IP Distribution"} icon={<Reverse/>}
                                                  onClick={() => dispatch({
                                                      type: Action.OpenDistribution,
                                                      activeId: dataset.id
                                                  })}/>
                                <DropdownMenuItem text={"Details"} icon={<Description/>}
                                                  onClick={() => dispatch({
                                                      type: Action.OpenEdit,
                                                      activeId: dataset.id
                                                  })}/>


                                {dataset.serviceMode === "MANAGEDSERVICE" &&
                                    <>
                                        <DropdownMenuItem text={"Project Key"} icon={<VpnKeyIcon/>}
                                                          onClick={() => dispatch({
                                                              type: Action.OpenKey,
                                                              activeId: dataset.id
                                                          })}/>
                                        <DropdownMenuItem text={"Address"} icon={<PersonSearchIcon/>}
                                                          onClick={() => dispatch({
                                                              type: Action.OpenAddress,
                                                              activeId: dataset.id
                                                          })}/>

                                    </>
                                }
                            </>}

                            <DropdownMenuItem  text={"Main User"} icon={<AssignmentInd/>}
                                              onClick={() => {
                                                  dispatch({
                                                      type: Action.OpenChangeMainUser,
                                                      activeId: dataset.groupId
                                                  })
                                              }}/>
                            <Separator/>

                            <DropdownMenuItem danger text={"Delete"} icon={<DeleteIcon/>}
                                              onClick={() => {
                                                  openCustomDialog("Delete Group", DeleteCustomerConfirmDialog, () => {
                                                  })
                                              }}/>

                        </Menu>


                        <div>
                            <SlideIn open={distributionOpen} onClose={() => dispatch({type: Action.Close})}>
                                <AmountForm onClose={() => dispatch({type: Action.Close})} id={activeId}/>
                            </SlideIn>
                            <SlideIn open={editOpen} onClose={() => dispatch({type: Action.Close})}>
                                {editOpen &&
                                    <CustomerDetails id={activeId} onClose={() => dispatch({type: Action.Close})}/>}

                            </SlideIn>
                            <SlideIn open={keyOpen} onClose={() => dispatch({type: Action.Close})}>
                                <ProjectKeyProvider>
                                    <ProjectKeyForm id={activeId} onClose={() => dispatch({type: Action.Close})}/>
                                </ProjectKeyProvider>
                            </SlideIn>
                            <SlideIn open={addressOpen} onClose={() => dispatch({type: Action.Close})}>
                                {addressOpen &&
                                    <AddressSlide id={activeId} onClose={() => dispatch({type: Action.Close})}/>}

                            </SlideIn>
                            <SlideIn open={changeMainUserOpen} onClose={() => dispatch({type: Action.Close})}>
                                {changeMainUserOpen &&
                                    <ChangeMainUserOfGroupForm id={activeId} onClose={() => dispatch({type: Action.Close})}/>}
                            </SlideIn>
                        </div>
                    </>
                }

                {dataset.status === "INVITED" &&
                    <>
                        <IconButton
                            onClick={show}
                            aria-haspopup="true"
                            aria-controls="dropdown-menu-invited">
                            <MoreVertIcon/>
                        </IconButton>

                        <Menu id={MENU_ID}>
                            <DropdownMenuItem
                                text={"Resend Invite"}
                                icon={<MailResend/>}
                                onClick={() => {
                                    openConfirmDialog(
                                        "Resend Invitation",
                                        `A new invite email will be sent to: ${dataset?.contactEmail}`,
                                        () => {
                                            resendInvite(dataset.groupId)
                                        }
                                    )
                                }}
                            />
                            <Separator/>

                            <DropdownMenuItem danger text={"Delete"}
                                              icon={<DeleteIcon/>}
                                              onClick={() => {
                                                  openConfirmDialog(
                                                      "Delete Invitation",
                                                      `Are you sure you want to delete the invitation of ${dataset.contactEmail} ? `,
                                                      () => {
                                                          deleteInvite(dataset.groupId)
                                                      }
                                                  )
                                              }}
                            />
                        </Menu>
                    </>
                }
            </>}

        </TableCell>
    </TableRow>
}
