import {DashboardCellBanner, DashboardCellBody, DashboardCellText, FlexRow} from "@greenbone/cloud-component-library"
import {Donut} from "@greenbone/cloud-chart-library"
import React from "react"


const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34.003">
  <g id="Group_3969" data-name="Group 3969" transform="translate(-1156 -470.997)">
    <rect id="Rectangle_1588" data-name="Rectangle 1588" width="34" height="34" transform="translate(1156 471)" fill="none"/>
    <g id="Group_3960" data-name="Group 3960" transform="translate(1.692 -0.17)">
      <g id="Group_1870" data-name="Group 1870" transform="translate(1158.308 471.917)">
        <g id="Group_1860" data-name="Group 1860" transform="translate(0 9.886)">
          <path id="Path_956" data-name="Path 956" d="M16.772,23.659H8.25L9.1,20.25H15.92Z" transform="translate(0.272 -1.073)" fill="none" stroke="#7ec14b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <line id="Line_371" data-name="Line 371" x2="13.636" transform="translate(5.966 22.586)" fill="none" stroke="#7ec14b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <line id="Line_370" data-name="Line 370" x2="25.567" transform="translate(0 15.636)" fill="none" stroke="#7ec14b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <path id="Path_955" data-name="Path 955" d="M22.908,3.75a3.448,3.448,0,0,1,3.409,3.487v12.2a3.448,3.448,0,0,1-3.409,3.487H4.159A3.448,3.448,0,0,1,.75,19.44V7.237A3.448,3.448,0,0,1,4.159,3.75" transform="translate(-0.75 -3.75)" fill="none" stroke="#7ec14b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
        <g id="Group_1866" data-name="Group 1866" transform="translate(3.409)">
          <g id="pin-location-1" transform="translate(0)">
            <path id="Path_885" data-name="Path 885" d="M11.454.75a6.2,6.2,0,0,1,6.2,6.2c0,2.988-4.717,9.676-5.925,11.339a.347.347,0,0,1-.559,0C9.967,16.63,5.25,9.942,5.25,6.954a6.2,6.2,0,0,1,6.2-6.2Z" transform="translate(-1.803 -0.75)" fill="none" stroke="#7ec14b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path id="Path_886" data-name="Path 886" d="M16.647,17.784c2.511.5,4.155,1.327,4.155,2.267,0,1.523-4.32,2.757-9.651,2.757S1.5,21.574,1.5,20.051c0-.938,1.637-1.766,4.136-2.264" transform="translate(-1.5 -2.127)" fill="none" stroke="#7ec14b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <g id="Group_1865" data-name="Group 1865" transform="translate(6.818 4.204)">
            <line id="Line_363" data-name="Line 363" y1="6.818" transform="translate(2.557)" fill="none" stroke="#7ec14b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line id="Line_364" data-name="Line 364" x2="2.557" y2="2.557" transform="translate(2.557)" fill="none" stroke="#7ec14b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line id="Line_365" data-name="Line 365" x1="2.557" y2="2.557" stroke-width="1.5" stroke="#7ec14b" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>


`

export function ExternalPlans({count, data}) {
    return <> <DashboardCellBanner>External</DashboardCellBanner>
        <DashboardCellBody>
            <FlexRow fullWidth={true} alignItems={"center"} justifyContent={"space-between"}>
                <DashboardCellText>{count}</DashboardCellText>
                <div style={{width: "6.25rem", height: "6.25rem"}}>
                    <Donut width={100} height={100} accessor={d => d.count} thickness={10} data={data}
                           colorScheme={["#55C14A", "#B1EA8D"]}
                           icon={svg}
                    />
                </div>
            </FlexRow>


        </DashboardCellBody>
    </>
}