import React, {useEffect, useState} from "react"
import {Col, Container, Row} from "reactstrap"
import {
    AlertBox,
    AlertBoxType,
    Button,
    ButtonSpinner,
    Checkbox,
    FlexRow,
    GhostButton,
    Headline,
    Input,
    Snackbar
} from "@greenbone/cloud-component-library"
import {PaymentMethodRestApiClient} from "../../../../service/PaymentMethodRestApiClient"
import {PaymentTypes} from "./PaymentTypes"

export function StripeForm({memcopy, close}) {

    const [sending, setSending] = useState(false)
    const [state, setState] = useState(memcopy)
    const [isValid, setIsValid] = useState(null)
    const [isPublicValid, setPublicValid] = useState(null)
    const [showTestWarning, setShowTestWarning] = useState(false)

    useEffect(() => {

        const apiKey = state[PaymentTypes.CREDIT_CARD]?.apiKey
        const publicKey = state[PaymentTypes.CREDIT_CARD]?.publicAccessKey

        let showWarning = false

        if (apiKey) {
            if (apiKey.includes("test")) {
                showWarning = true
            }
        }

        if (publicKey) {
            if (publicKey.includes("test")) {
                showWarning = true
            }
        }

        setShowTestWarning(showWarning)

    }, [state])

    const onSubmit = event => {
        event.preventDefault()
        setSending(true)
        const apiClient = new PaymentMethodRestApiClient()
        apiClient.update(state)
            .then(response => {
                if (response && response.status === "BAD_REQUEST") {
                    try {
                        setIsValid(response?.fieldErrors["CREDIT_CARD.apiKey"])
                        setPublicValid(response?.fieldErrors["CREDIT_CARD.publicAccessKey"])
                    } catch (e) {

                    }
                    setSending(false)
                    return
                }


                if (!response) {
                    Snackbar.Success("CreditCard updated successfully.")

                    close(state)
                } else {
                    Snackbar.Error("Could not save configuration.")
                }
                setSending(false)
            })
            .catch(e => Snackbar.Error(e.toString()))
    }

    const onChange = (event) => {

        const value = event.target.value
        const name = event.target.name

        setState(prevState => ({
            ...prevState,
            [PaymentTypes.CREDIT_CARD]: {...prevState[PaymentTypes.CREDIT_CARD], [name]: value}
        }))
    }

    const onChkBoxChange = event => {

        setState(prevState => ({
            ...prevState,
            [PaymentTypes.CREDIT_CARD]: {
                ...prevState[PaymentTypes.CREDIT_CARD],
                active: !prevState[PaymentTypes.CREDIT_CARD].active
            }
        }))
    }

    return <Container fluid style={{minWidth: "30rem"}}>
        <Row>
            <Col>
                <Headline>Edit Credit Card</Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <Input isValid={isValid} margin={"normal"} name={"apiKey"} label={"ApiKey"} onChange={onChange}
                       type={"text"}
                       value={state[PaymentTypes.CREDIT_CARD]?.apiKey}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <Input isValid={isPublicValid} margin={"normal"} name={"publicAccessKey"} label={"Public Access Key"}
                       onChange={onChange}
                       type={"text"}
                       value={state[PaymentTypes.CREDIT_CARD]?.publicAccessKey}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <Checkbox onChange={onChkBoxChange} checked={state[PaymentTypes.CREDIT_CARD]?.active}
                          label={"is Active"}/>
            </Col>
        </Row>
        {showTestWarning && <Row>
            <Col>
                <AlertBox title={"Warning"} type={AlertBoxType.Warning}>
                    <>
                        You're using a Stripe test key.
                        This key should only be used to test the payment process. You won't be able to collect any
                        Credit Card payments.
                    </>
                </AlertBox>
            </Col>
        </Row>
        }

        <Row>
            <Col>
                <FlexRow justifyContent={"space-between"} style={{flexDirection: "row-reverse"}}>
                    <Button onClick={onSubmit}>Save {sending && <ButtonSpinner/>}</Button>
                    <GhostButton onClick={() => close(memcopy)} style={{marginLeft: 0}}>Abort</GhostButton>
                </FlexRow>
            </Col>
        </Row>
    </Container>
}
