import React from "react"
import {useDropzone} from "react-dropzone"
import styled, {css} from "styled-components"
import {Bold, Button, FlexColumn, Snackbar} from "@greenbone/cloud-component-library"
import camera from "../assets/camera.svg?url"

const Outer = styled.section`
  background-image: url("${camera}");
  background-repeat: no-repeat;
  background-position: center;
  border: 7px #ACACAC dashed;

  width: 13.125rem;
  height: 9.625rem;
  margin-bottom: 1rem;
  
  .dropzone {
    height: 100%;
    width: 100%;
  }
  
  p {
    cursor: pointer;
    width: 100%;
    height: 100%;
    
      
  ${props => props.image && css`
    background-image: url("${props.image}");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
  `}
  
  }
`

export function SmallLogoUpload(props) {

    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        onDrop(acceptedFiles, rejectedFiles, event) {
            if (rejectedFiles?.length >= 1) {
                Snackbar.Warning("Invalid file type")
            }

            if (acceptedFiles?.length >= 1) {
                props.onImageDrop(acceptedFiles)
            }
        },
        accept: "image/jpeg, image/png, image/gif, image/jpg"
    })

    return (
        <FlexColumn>
            <div>
                <Button  {...getRootProps({className: "dropzone"})}>Upload Logo</Button>
            </div>
            <div>
                <Outer image={props.image}>
                    <div {...getRootProps({className: "dropzone"})}>
                        <input {...getInputProps()} />
                        <p></p>
                    </div>
                </Outer>
            </div>
            <Bold>{props.text} </Bold>
        </FlexColumn>
    )
}
