import React from "react"
import {Spinner, useRequest} from "@greenbone/cloud-component-library"


export function FetchData({request, children, errorMessage}) {
    const [data, loading, error] = useRequest(request)

    if (loading) {
        return <Spinner/>
    }

    if (error) {
        if (errorMessage) {
            return errorMessage(error)
        }

        throw error
    }

    if (typeof children === "function") {
        return children({data, loading, error})
    }

    return children
}