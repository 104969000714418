import React from "react"
import {Button, ButtonSpinner} from "@greenbone/cloud-component-library"

export function SaveButton({children, saving, disabled, ...other}) {

    return <Button disabled={saving || disabled} style={{display: "flex", alignItems: "center"}} {...other}>{children}
        {saving && <ButtonSpinner style={{margin: 0, marginLeft: "1rem"}}/>}

    </Button>
}
