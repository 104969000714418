import React from "react"
import {Col, Row} from "reactstrap"
import {DashboardCellBanner, Headline, Paragraph, Spinner, useRequest} from "@greenbone/cloud-component-library"
import styled from "styled-components"
import {SmallVulnerabilityList} from "./components/SmallVulnerabilityList"
import {AffectedOperatingSystem} from "./components/AffectedOperatingSystem"
import {CompanyList} from "./components/company/CompanyList"
import {FetchData} from "./components/FetchData"
import {ManagedSecurityRestApiClient} from "../../service/ManagedSecurityRestApiClient"
import {Logger} from "../../Logger"
import {ListHasEntries} from "../../components/ListHasEntries"
import {ErrorBoundary} from "../../components/ErrorBoundary"

const NoDataAvailable = styled.div`
  margin: .5rem;

`

const noDataAvailable = () => {
    return <NoDataAvailable>
        <Paragraph>
            No Data Available
        </Paragraph>
    </NoDataAvailable>
}

const StyledParagraph = styled(Paragraph)`
  margin: .5rem;
`


export function ManagedSecurityPage() {

    const managedSecurityRestApiClient = new ManagedSecurityRestApiClient()

    const [data, loading, error] = useRequest(managedSecurityRestApiClient.getCompanyList.bind(managedSecurityRestApiClient))

    if (loading) {
        return <Spinner/>
    }

    if (error) {
        Logger.exception(error)
        return <Headline>An Error Occurred </Headline>
    }

    if (data.length >= 1) {


        return <>
            <Row>
                <Col>
                    <Headline>Managed Security</Headline>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DashboardCellBanner>
                        Most Affected Operating System Last Week
                    </DashboardCellBanner>
                    <ErrorBoundary>
                        <FetchData request={() => new ManagedSecurityRestApiClient().getOperatingSystemDistribution()}>
                            {({data}) => <ListHasEntries message={noDataAvailable()} list={data}>
                                <AffectedOperatingSystem operatingSystemList={data}/>
                            </ListHasEntries>}
                        </FetchData>
                    </ErrorBoundary>
                </Col>
                <Col>
                    <ErrorBoundary>
                        <DashboardCellBanner>
                            Critical Vulnerabilities Last Week
                        </DashboardCellBanner>
                        <div style={{marginRight: ".5rem", marginLeft: ".5rem"}}>
                            <ErrorBoundary>
                                <FetchData
                                    request={() => new ManagedSecurityRestApiClient().getTopVulnerabilitiesLastWeek()}>
                                    {({data}) => <ListHasEntries message={noDataAvailable()} list={data}>
                                        <SmallVulnerabilityList vulnerabilities={data} amount={3}/>
                                    </ListHasEntries>}
                                </FetchData>
                            </ErrorBoundary>

                        </div>
                    </ErrorBoundary>

                </Col>
            </Row>
            <Row>
                <Col>
                    <DashboardCellBanner>
                        Customer Overview
                    </DashboardCellBanner>
                    <StyledParagraph>
                        This is a list of your customers who have monitoring enabled. The <b>Task Count</b> shows the
                        number of
                        tasks a customer has. <b style={{color: "#D4003E"}}>High</b>,
                        <b style={{color: "#FCB800"}}>Medium</b> and <b style={{color: "#7DB5D0"}}>Low</b> show the
                        amount of severities in the last seven days. You can click the button at the end to get more
                        information about the customer.
                    </StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ErrorBoundary>
                        <CompanyList/>
                    </ErrorBoundary>
                </Col>
            </Row>
        </>
    } else {
        return <>
            <Headline>Managed Security</Headline>
            <Paragraph>
                None of your customers have Managed Security Enabled. Your customers can enable this feature under <b>Team
                Settings</b> {">"} <b>Managed Security</b>
            </Paragraph>
        </>
    }
}
