import {EntityRestApiClient} from "./EntityRestApiClient";
import {RestApiClient} from "./RestApiClient";
import {globalInstanceStorage} from "../GlobalSingle";
import {GenericUrlProvider} from "./GenericUrlProvider";

function ErrorHandler(e) {
    // if (e.status === 400 || e.status === 500) {
    throw e;
    // } else {
    //     Snackbar.Error(e);
    // }
}


export class PaymentMethodRestApiClient extends EntityRestApiClient {

    constructor(apiClient = new RestApiClient(globalInstanceStorage.getFetchClient(), ErrorHandler),
                urlProvider = new GenericUrlProvider("/billing/admin/paymentOptions")) {
        super(apiClient, urlProvider);
    }

    async update(entity) {
        return await super.getClient().put(super.getUrlProvider().getAll(), entity);
    }

}
