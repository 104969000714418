import React, {useContext, useState} from "react"
import {Col, Container, Row} from "reactstrap"
import styled, {useTheme} from "styled-components"
import {
    Button,
    Caption,
    FilterTab,
    FlexColumn,
    FlexRow,
    GhostButton,
    Headline,
    Radio,
    RadioGroup,
    SlideIn
} from "@greenbone/cloud-component-library"
import MoneyLetter from "../assets/money-letter.svg"
import Bag from "../assets/money-bag.svg"
import Credit from "../assets/credit-card.svg"
import {CustomerContext} from "../CustomerContext"
import {PaymentTypes} from "../../PaymentMethod/components/PaymentTypes"
import VpnKeyIcon from "@material-ui/icons/VpnKey"


const Clear = styled.div`
  font-size: 14px;
  color: #707070;
  text-decoration: underline;
  cursor: pointer;
`

const FilterTabRow = styled(FlexRow)`
  div {
    cursor: pointer;
  }
`

function BillingMethodFilter({onChange, value}) {

    const {base} = useTheme()

    const handleChange = (method) => {
        if (value.includes(method)) {
            const found = value.filter(m => m !== method)

            onChange(found ? found : [])
        } else {
            onChange([...value, method])
        }
    }
    return <FilterTabRow justifyContent={"space-between"}>
        <FilterTab onClick={() => handleChange(PaymentTypes.NONE)} active={value.includes(PaymentTypes.NONE)}
                   color={base} iconColor={base}
                   icon={<Bag/>}>
            Free
        </FilterTab>
        <FilterTab onClick={() => handleChange(PaymentTypes.INVOICE)} active={value.includes(PaymentTypes.INVOICE)}
                   color={base}
                   iconColor={base} icon={<MoneyLetter/>}>
            Invoice
        </FilterTab>
        <FilterTab onClick={() => handleChange(PaymentTypes.CREDIT_CARD)}
                   active={value.includes(PaymentTypes.CREDIT_CARD)} color={base}
                   iconColor={base} icon={<Credit/>}>
            Credit Card
        </FilterTab>
        <FilterTab onClick={() => handleChange(PaymentTypes.PROJECT_KEY)}
                   active={value.includes(PaymentTypes.PROJECT_KEY)} color={base}
                   iconColor={base} icon={<VpnKeyIcon/>}>
            Project Key
        </FilterTab>
    </FilterTabRow>
}

function FilterComponent({onClose}) {


    const {
        applyFilter,
        filter,
        setFilter
    } = useContext(CustomerContext)


    const {paymentOptions, status, serviceMode} = filter

    const reset = () => {

        setFilter({
            "status": null,
            "serviceMode": null,
            "paymentOptions": [],
            "groupStatus": null
        })
    }


    const setBillingMethod = (billingsMethods) => {
        setFilter({...filter, paymentOptions: billingsMethods})
    }

    const setStatus = (status) => {
        setFilter({...filter, status})
    }

    const setMode = (serviceMode) => {
        setFilter({...filter, serviceMode})
    }

    return <Container style={{width: "32rem"}}>
        <Row style={{marginBottom: "2rem"}}>
            <Col>
                <FlexRow justifyContent={"space-between"} alignItems={"center"}>
                    <Headline>Filter Customers</Headline>
                    <Clear onClick={() => reset()}>Clear all filters</Clear>
                </FlexRow>
            </Col>
        </Row>
        <Row style={{marginBottom: "2rem"}}>
            <Col>
                <Caption>
                    Billing Method
                </Caption>
                <BillingMethodFilter onChange={(val) => setBillingMethod(val)} value={paymentOptions}/>
            </Col>
        </Row>
        <Row style={{marginBottom: "2rem"}}>
            <Col>
                <Caption>
                    Service Mode
                </Caption>
                <RadioGroup row={true} value={serviceMode}>
                    <FlexColumn>
                        <Radio value={"SELFSERVICE"} label={"Self Service"} onClick={() => setMode("SELFSERVICE")}/>
                        <Radio value={"MANAGEDSERVICE"} label={"MSP"} onClick={() => setMode("MANAGEDSERVICE")}/>
                        <Radio value={null} label={"Both"} onClick={() => setMode(null)}/>
                    </FlexColumn>
                </RadioGroup>
            </Col>
        </Row>
        <Row style={{marginBottom: "2rem"}}>
            <Col>
                <Caption>
                    Status
                </Caption>
                <RadioGroup row={false} value={status} label={""} name={"Status"}>
                    <FlexRow>
                        <FlexColumn>
                            <Radio value={"ACTIVE"} label={"Active"} onClick={() => setStatus("ACTIVE")}/>
                            <Radio value={"INACTIVE"} label={"Inactive"} onClick={() => setStatus("INACTIVE")}/>
                            <Radio value={"LOCKED"} label={"Locked"} onClick={() => setStatus("LOCKED")}/>
                        </FlexColumn>
                        <FlexColumn>
                            <Radio value={"INVITED"} label={"Invited"} onClick={() => setStatus("INVITED")}/>
                            <Radio value={"DELETED"} label={"Deleted"} onClick={() => setStatus("DELETED")}/>
                            <Radio value={null} label={"All"} onClick={() => setStatus(null)}/>
                        </FlexColumn>
                    </FlexRow>


                </RadioGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <FlexRow style={{flexDirection: "row-reverse"}}>

                    <Button onClick={() => {
                        applyFilter()
                        onClose()
                    }} style={{marginLeft: "1rem"}}>
                        Apply
                    </Button>
                    <GhostButton onClick={() => onClose()}>
                        Abort
                    </GhostButton>
                </FlexRow>
            </Col>
        </Row>

    </Container>
}

export function CustomFilter({onClose}) {
    const [open, setOpen] = useState(false)

    return <div>
        <Button onClick={() => setOpen(true)}>Add Filter +</Button>
        <SlideIn open={open} onClose={() => setOpen(false)}>
            <FilterComponent onClose={() => setOpen(false)}/>
        </SlideIn>
    </div>
}
