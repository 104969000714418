import React from "react"
import {SalesDashboard} from "./SalesDashboard"
import {ErrorBoundary} from "../../../components/ErrorBoundary"

export function SalesDashboardPage(props) {

    return <ErrorBoundary>
        <SalesDashboard/>
    </ErrorBoundary>

}