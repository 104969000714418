import React from 'react'
import {Bold, FlexColumn, FlexRow, Headline, SlideIn} from "@greenbone/cloud-component-library"
import styled from "styled-components"
import ListAltIcon from "@material-ui/icons/ListAlt"
import {IconButton} from "@material-ui/core"
import {FetchData} from "./FetchData"
import {VulnerabilityDetails} from "./VulnerabilityDetails"
import {useSlideInState} from "../../../Flux/useSlideInState"
import {ManagedSecurityRestApiClient} from "../../../service/ManagedSecurityRestApiClient"

const Severity = styled.div`
  width: 25px;
  height: 20px;
  background: #D40155;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: .875rem;

  margin-right: .5rem;
`

const MiniHeadline = styled(Headline)`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: .2rem;
`


const ExtraSmallText = styled.span`
  font-size: .875rem;
  color: #5B5B5B;
`

function Type({children}) {
    return <ExtraSmallText>
        Type:<Bold style={{fontSize: "0.875rem"}}>{children}</Bold>
    </ExtraSmallText>


}

function Count({children}) {
    return <>
        <ExtraSmallText style={{fontSize: "0.875rem"}}>Found: {children}</ExtraSmallText>
    </>
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: .5rem 0;
  grid-template-areas:
    "."
    ".";
  margin-bottom: 1rem;
  margin-top: 1rem;
`

export function SmallVulnerability({
                                       severity,
                                       type,
                                       summary,
                                       name,
                                       count,
                                       oid
                                   }) {
    const {isOpen, setOpen, setClosed} = useSlideInState()
    const apiClient = new ManagedSecurityRestApiClient()

    return <>
        <Grid>

            <FlexRow justifyContent={"space-between"} alignItems={"center"}>
                <FlexRow>
                    <Severity>
                        {severity}
                    </Severity>
                    <Type>
                        {type}
                    </Type>
                </FlexRow>
                <Count>
                    {count}
                </Count>
            </FlexRow>

            <FlexRow alignItems={"flex-end"} justifyContent={"space-between"}>
                <FlexColumn>
                    <MiniHeadline>
                        {name}
                    </MiniHeadline>
                    <ExtraSmallText>
                        {summary}
                    </ExtraSmallText>
                </FlexColumn>
                <div>
                    <IconButton role={"details-button"} onClick={() => setOpen(oid)}>
                        <ListAltIcon/>
                    </IconButton>
                </div>

            </FlexRow>

        </Grid>

        <SlideIn open={isOpen} onClose={() => setClosed()}>
            <FetchData request={() => apiClient.getVulnerability(oid)}>
                {({data}) => <VulnerabilityDetails vulnerability={data}/>}
            </FetchData>
        </SlideIn>
    </>
}
