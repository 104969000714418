import React, {useContext, useState} from "react"
import {AdminSettingsContext} from "../../../Context/AdminSettingsContext"
import {Button, FlexRow, Headline, Input, Paragraph, Snackbar, Spinner} from "@greenbone/cloud-component-library"
import {Logger} from "../../../Logger"
import {ManagedSecurityRestApiClient} from "../../../service/ManagedSecurityRestApiClient"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import {Col, Row} from "reactstrap"

export function JumpInComponent({groupId, handleClose, customer}) {


    const {settings, loading} = useContext(AdminSettingsContext)

    const [isFieldInvalid, setFieldInvalid] = useState(null)

    const [passwordInput, setPassword] = useState()
    const [twoTokenInput, setTwoToken] = useState()


    if (loading) {
        return <>
            <DialogTitle id="form-dialog-title">Loading...</DialogTitle>
            <FlexRow alignItems={"center"} justifyContent={"center"} style={{margin: "2rem"}}>
                <Spinner/>
            </FlexRow>

            <FlexRow justifyContent={"center"}>
                <Button onClick={event => {
                    event.preventDefault()
                    handleClose()
                }}>
                    Cancel
                </Button>
            </FlexRow>


        </>
    }

    if (!customer) {
        Logger.error(`Customer in Jump-In could not be found. UrlParamGroupId: "${groupId}"`)
        return <Headline>
            No Customer Found
        </Headline>
    }


    const handleTokenFetch = ({password, totp}) => {

        const client = new ManagedSecurityRestApiClient()
        setFieldInvalid(null)

        client.getJumpInExchangeToken(groupId, password, totp)
            .then(response => {

                if (response.status === "BAD_REQUEST") {
                    setFieldInvalid("Invalid Credentials")
                    return
                }

                const host = response.host
                const baseurl = `https://${host}`
                const url = new URL(baseurl)

                url.searchParams.append("jumptoken", response.token)

                const finalUrl = url.toString()

                if (!window.open) {
                    Snackbar.Warning("Please enable popups for the current domain.")
                }

                try {
                    window.open(finalUrl, "_blank").focus()
                    Snackbar.Success("Jump in successful")
                } catch (e) {
                    Snackbar.Warning("Please enable popups for the current domain.")
                }

                handleClose()

            })
            .catch(e => {
                if (e.status === 401 || e.status === "UNAUTHORIZED") {
                    setFieldInvalid("Invalid Credentials")
                    return
                }
                Snackbar.Error(e.toString())
                Logger.exception(e)
            })
    }


    const handleSubmit = e => {
        e.preventDefault()

        handleTokenFetch({password: passwordInput, totp: twoTokenInput})

    }


    return <>
        <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Jump-In for {customer.companyName}</DialogTitle>
            <DialogContent>
                <Paragraph>
                    Please enter your credentials again.
                </Paragraph>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col>
                                <Input isValid={isFieldInvalid} type={"password"}
                                       margin={"normal"} onChange={e => setPassword(e.target.value)}
                                       label={"Password"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {settings?.totpEnabled &&
                                    <Input isValid={isFieldInvalid} margin={"normal"}
                                           onChange={e => setTwoToken(e.target.value)}
                                           label={"Two Factor Token"}/>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <FlexRow style={{flexDirection: "reverse", justifyContent: "flex-end"}}>
                            <Button>
                                Jump
                            </Button>
                            <Button onClick={event => {
                                event.preventDefault()
                                handleClose()
                            }}>
                                Cancel
                            </Button>
                        </FlexRow>
                    </Col>
                </Row>
            </DialogContent>
        </form>
    </>
}