import React from "react"
import {Col, Row} from "reactstrap"
import {Input} from "@greenbone/cloud-component-library"


export function ContactPerson({person, setPerson, errors, onClose, ...other}) {


    const handleChange = event => {
        const {name, value} = event.target


        setPerson(prevState => ({...prevState, [name]: value}))
    }

    const state = person

    return <>
        <form>
            <Row>
                <Col>
                    <Input isValid={errors?.contactFirstName} value={state?.contactFirstName} name={"contactFirstName"}
                           onChange={handleChange}
                           margin={"normal"}
                           label={"First Name"}/>
                </Col>
                <Col>
                    <Input isValid={errors?.contactLastName} value={state?.contactLastName} name={"contactLastName"}
                           onChange={handleChange}
                           margin={"normal"}
                           label={"Last Name"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input isValid={errors?.contactEmail} value={state?.contactEmail} name={"contactEmail"}
                           onChange={handleChange} margin={"normal"}
                           label={"E-Mail"}/>
                </Col>
            </Row>
        </form>
    </>
}
