import {globalInstanceStorage} from "../GlobalSingle"

const jwt = require("jsonwebtoken")

export class JWT {
    token

    constructor() {
        this.decode()
    }

    get isValid() {
        return !!this.token
    }

    decode() {
        try {
            const rawToken = globalInstanceStorage.keycloak.token
            const decodedToken = jwt.decode(rawToken)
            this.token = decodedToken
        } catch (e) {
            console.error(e)
        }

    }

    getEmail() {
        const token = this.token
        return token.email
    }

    subject() {
        const token = this.token
        if (!token) {
            return ""
        }
        return token.sub
    }

    familyName() {
        const token = this.token
        return token.family_name
    }

    givenName() {
        const token = this.token
        return token.given_name
    }

    getRoles() {
        const token = this.token
        return token.realm_access.roles
    }

    hasUserRole() {
        return this.getRoles().includes("user")
    }
}
