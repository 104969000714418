import React, {useContext, useState} from "react"
import {LegalContext} from "./Context/LegalContext"
import {TabSwitch} from "./TabSwitch"
import {Editor} from "./Editor"
import {FormControlLabel, Switch} from "@material-ui/core"


export function TermsOfUse(props) {
    const {data, setTosDE, setTosEN, handleActiveChange} = useContext(LegalContext)

    const {termsOfUse, termsOfUseEnabled} = data

    const [english, setEnglish] = useState(true)

    return <>
        <FormControlLabel
            control={
                <Switch
                    checked={termsOfUseEnabled}
                    onChange={handleActiveChange}
                    name="termsOfUseEnabled"
                    color="primary"
                />
            }
            label="Active the terms of use entered below."
        />
        <TabSwitch active={english} onChange={setEnglish} activeText={"English"} inactiveText={"German"}/>

        {english && <Editor value={termsOfUse?.en} setValue={setTosEN}/>}
        {!english && <Editor value={termsOfUse?.de} setValue={setTosDE}/>}


    </>
}
