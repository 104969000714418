import React, {useContext} from "react"
import {Container} from "reactstrap"
import {AbortButton, Button, Checkbox, FlexRow, Input, Paragraph} from "@greenbone/cloud-component-library"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepContent from "@material-ui/core/StepContent"
import Typography from "@material-ui/core/Typography"
import {ProjectKeyContext} from "./ProjectKeyContext/ProjectKeyContext"
import {IPDistribution} from "./IPDistribution"
import {SaveButton} from "../../../../SaveButton"
import {Formatter} from "./ProjectKeyContext/Formatter"
import styled from "styled-components"

const StyledStepLabel = styled(StepLabel)`

  svg.MuiStepIcon-completed {
    color: ${props => props.theme.navbar.background};
  }

  svg.MuiStepIcon-active {
    color: ${props => props.theme.navbar.background};
  }

  svg {
    color: ${props => props.theme.navbar.background};
  }
`

function IPDistributionMapper(props) {
    const {distribution, updateDistribution, token} = useContext(ProjectKeyContext)

    const updateDistributionMapper = (distribution) => {
        updateDistribution({intern: distribution.ipsInternal, extern: distribution.ipsExternal})
    }

    return <IPDistribution externalIpCount={distribution.extern}
                           internalIpCount={distribution.intern}
                           min={token.ipLimit}
                           max={token.ipLimit}
                           updateDistribution={updateDistributionMapper}

    />
}

function Confirm(props) {
    const {token, sellingPrice, distribution, switchImmediate, setSwitchImmediate} = useContext(ProjectKeyContext)

    const {ipLimit, runtimeInMonth, purchasePrice} = token

    return <>
        <table style={{color: "inherit", width: "100%"}}>
            <tr>
                <td>IP Count:</td>
                <td style={{textAlign: "right"}}>{ipLimit}</td>
            </tr>
            <tr>
                <td>IP Intern</td>
                <td style={{textAlign: "right"}}>{distribution.intern}</td>
            </tr>
            <tr>
                <td>IP Extern</td>
                <td style={{textAlign: "right"}}>{distribution.extern}</td>
            </tr>
            <tr>
                <td>Runtime in month:</td>
                <td style={{textAlign: "right"}}>{runtimeInMonth}</td>
            </tr>
            <tr>
                <td>Purchase Price:</td>
                <td style={{textAlign: "right"}}>{purchasePrice} €</td>
            </tr>
            <tr>
                <td>Selling Price</td>
                <td style={{textAlign: "right"}}>{sellingPrice} €</td>
            </tr>
        </table>
        <Checkbox onChange={() => setSwitchImmediate()} checked={switchImmediate}
                  label={"Perform action instantly"}/>

    </>
}


function EnterKey(props) {

    const {tokenString, setTokenString, tokenValidation} = useContext(ProjectKeyContext)

    return <>
        <Paragraph>
            Enter the Project Key to to validate it and see the conditions.
        </Paragraph>
        <Input isValid={tokenValidation} value={tokenString} onChange={e => setTokenString(e.target.value)}
               label={"Project Key"}/>
    </>
}

function Price(props) {
    const {token, setSellingPrice, sellingPrice, priceValidation} = useContext(ProjectKeyContext)

    const {ipLimit, runtimeInMonth, purchasePrice} = token

    return <div>
        <table style={{color: "inherit", width: "100%", marginBottom: "1rem"}}>
            <tr>
                <td>IP Count:</td>
                <td style={{textAlign: "right"}}>{ipLimit}</td>
            </tr>
            <tr>
                <td>Runtime in month:</td>
                <td style={{textAlign: "right"}}>{runtimeInMonth}</td>
            </tr>
            <tr>
                <td>Purchase Price:</td>
                <td style={{textAlign: "right"}}>{Formatter.toCurrency(purchasePrice)}</td>
            </tr>
        </table>

        <Input isValid={priceValidation} value={sellingPrice}
               onChange={e => setSellingPrice(e.target.value)} label={"Sell Price"}/>
    </div>
}


function getSteps() {
    return ["Enter Project Key", "Enter Price", "Set Distribution", "Confirm"]
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return <EnterKey/>
        case 1:
            return <Price/>
        case 2:
            return <IPDistributionMapper/>
        case 3:
            return <Confirm/>
        default:
            return "Unknown step"
    }
}

function allowNext(priceValidation, activeStep) {

    if (priceValidation !== true && activeStep > 0) {
        return false
    }

    return true
}

export function ProjectKeyForm({onClose, id}) {
    const {
        activeStep,
        setActiveStep,
        setToken,
        isSaving,
        priceValidation,
        submitKey
    } = useContext(ProjectKeyContext)

    const steps = getSteps()

    const handleNext = () => {
        if (activeStep === 3) {
            submitKey(id, onClose)
        } else if (activeStep === 0) {
            setToken()
        } else {
            setActiveStep(activeStep + 1)
        }


    }

    const handleBack = () => {
        if (activeStep === 0) {
            onClose()
        }
        setActiveStep(activeStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    return (

        <Container style={{width: "30rem"}}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label} onClose={onClose}>
                        <StyledStepLabel>{label}</StyledStepLabel>
                        <StepContent>
                            {getStepContent(index)}

                            <FlexRow justifyContent={"flex-end"}>

                                <AbortButton
                                    onClick={handleBack}
                                >
                                    {activeStep === 0 ? "Cancel" : "Back"}
                                </AbortButton>
                                <SaveButton saving={isSaving}
                                            disabled={!allowNext(priceValidation, activeStep)}
                                            onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                </SaveButton>
                            </FlexRow>

                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset}>
                        Reset
                    </Button>
                </>
            )}
        </Container>
    )
}
