import {RestApiClient} from "@greenbone/cloud-component-library"
import {globalInstanceStorage} from "../../../../GlobalSingle"

function ErrorHandler(e) {
    throw e
}

export class LegalRestApiClient {
    constructor(apiClient = new RestApiClient(globalInstanceStorage.getFetchClient(), ErrorHandler)) {
        this.apiClient = apiClient
    }

    async get() {
        return await this.apiClient.get("/vmssp-admin/legal")
            .then(response => {
                Object.keys(response).map(field => {
                    if (response[field]?._primaryLanguage) {
                        delete response[field]._primaryLanguage
                    }
                    return field
                })

                return response
            })
    }

    async save(body) {
        return await this.apiClient.put("/vmssp-admin/legal", body)
    }

}
