import React from "react"
import {FlexRow, Headline, Paragraph} from "@greenbone/cloud-component-library"
import {JWT} from "../welcomeScreen/JWT"
import {createGlobalStyle} from "styled-components"
import {globalInstanceStorage} from "../GlobalSingle"

const Global = createGlobalStyle`
  body {
    min-height: 100vh;
  }

  #root {

    & > div {
      min-height: 100vh;
    }

    min-height: 100vh;
  }


`

export function MissingRole() {

    const roles = (new JWT()).getRoles()


    return <FlexRow style={{minHeight: "100vh"}} justifyContent={"center"} alignItems={"center"}>
        <Global/>
        <div>
            <Headline>Missing Permission</Headline>
            {
                (roles?.includes("mssp-admin")) && <div>
                    <Paragraph>
                        You signed in using an msp-admin account.<br/>
                        If your looking for the msp-admin interface you can find it <a
                        href="/ui/mssp/admin/">here</a>
                    </Paragraph>
                </div>
            }

            <a href="/" onClick={(event) => {
                event.preventDefault()
                globalInstanceStorage.getKeycloak().logout()
            }}>Logout</a>
        </div>
    </FlexRow>


}
