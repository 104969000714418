import React from "react"
import {HostValidationApiClient} from "../../../service/HostValidation/HostValidationApiClient"
import {HostValidationTableRow} from "./HostValidationTableRow"
import {ActionButton, FlexRow, Snackbar, Spinner} from "@greenbone/cloud-component-library"
import {Table} from "@greenbone/cloud-dynamic-table"
import {JWT} from "../../../welcomeScreen/JWT"
import {ValidationType} from "../ValidationType"
import {CustomerRestApiClient} from "../../../service/CustomerRestApiClient"


export class HostValidationLists extends React.Component {
    hostValidationClient
    tableColumns = [
        {accessor: "ipAddress", Header: "Host Address", disableFilters: true},
        {accessor: "contactEmail", Header: "Contact Email", disableFilters: true},
        {accessor: "contactName", Header: "Contact Name", disableFilters: true},
        {
            accessor: (approvalRequest) => {
                return new Date(approvalRequest.requestedOn).getTime() / 1000
            },
            id: "requestedOn",
            Header: "Requested On",
            disableFilters: true
        },
        {accessor: "companyName", Header: "Requester Company", disableFilters: true},
        {accessor: "userEmail", Header: "Requested By", disableFilters: true},
        {accessor: "type", Header: "Type"},
        {accessor: "actions", Header: "Actions", disableFilters: true}
    ]

    state = {
        hostValidations: [],
        selectedRows: [],
        _loading: false,
        _exception: false,
        _sending: false
    }

    constructor(props) {
        super(props)
        this.hostValidationClient = new HostValidationApiClient()
    }

    componentDidMount() {
        this.loadHostValidations()
    }

    loadAvailableUserInformation() {
        const userIds = [...new Set(this.state.hostValidations.map(val => val.userId))].filter(val => !!val)
        const realmId = this.state.hostValidations.find(val => val.realm !== null)?.realm

        if (!realmId || userIds.length <= 0) {
            return
        }

        const apiClient = new CustomerRestApiClient()
        apiClient.getUserList(userIds, realmId)
            .then(response => {
                const hostValidationsWithUser = this.state.hostValidations.map(validation => {
                    const user = response.find(user => user.userId === validation.userId)

                    return {...validation, ...user}
                })

                this.setState({hostValidations: hostValidationsWithUser})

            })

    }

    loadHostValidations() {
        this.setState({_loading: true})

        this.hostValidationClient.getApprovalRequests()
            .then((approvalRequests) => {
                this.setState({
                    hostValidations: approvalRequests,
                    _loading: false
                })
            })
            .catch(exception => {
                this.setState({
                    _exception: exception,
                    _loading: false
                })
            })
            .finally(() => {
                this.loadAvailableUserInformation()
            })
    }

    approveHostValidationEntry = (event, approvalRequest) => {
        event.preventDefault()
        if (approvalRequest.validationType === ValidationType.Admin) {
            this.hostValidationClient.approveHostAdmin(approvalRequest.id)
                .then(() => this.rowConfirmedSuccessful(approvalRequest))
                .catch(this.handleError)
        } else if (approvalRequest.validationType === ValidationType.Contact) {
            this.hostValidationClient.approveHostContact(approvalRequest.id)
                .then(() => this.rowConfirmedSuccessful(approvalRequest))
                .catch(this.handleError)
        } else {
            throw new Error("Invalid validation type")
        }
    }

    rejectHostValidationEntry = (event, approvalRequest) => {
        event.preventDefault()
        if (approvalRequest.validationType === ValidationType.Admin) {
            this.hostValidationClient.rejectHostAdmin(approvalRequest.id)
                .then(() => this.rowConfirmedSuccessful(approvalRequest))
                .catch(this.handleError)
        } else if (approvalRequest.validationType === ValidationType.Contact) {
            this.hostValidationClient.rejectHostContact(approvalRequest.id)
                .then(() => this.rowConfirmedSuccessful(approvalRequest))
                .catch(this.handleError)
        } else {
            throw new Error("Invalid validation type")
        }
    }

    rowConfirmedSuccessful = (approvalRequest) => {
        this.setState({hostValidations: this.state.hostValidations.filter(v => v.id !== approvalRequest.id)})
    }

    handleError = _exception => {
        Snackbar.Error(_exception.toString())
    }

    rowGenerator = ({dataset, row}) => {
        const approvalRequest = dataset
        return (
            <HostValidationTableRow row={row} key={approvalRequest.id} approvalRequest={approvalRequest}
                                    approveHostValidationEntry={this.approveHostValidationEntry}
                                    rejectHostValidationEntry={this.rejectHostValidationEntry}
            />
        )
    }

    handleSelectedChange = (rows) => {
        this.setState({selectedRows: rows.map(row => row.original)})
    }

    render() {
        if (this.state._exception) {
            throw this.state._exception
        }
        if (this.state._loading) {
            return (<div><Spinner/></div>)
        }

        const {selectedRows} = this.state

        return (
            <Table defaultSortBy={[{id: "requestedOn", desc: true}]}
                   cacheKey={`${new JWT().subject()}-hostValidations/2021.07.01`} actions={<FlexRow>
                {selectedRows.length > 0 ?
                    <>
                        <ActionButton onClick={() => this.approveAll(selectedRows)}>Accept selected</ActionButton>
                        <ActionButton onClick={() => this.rejectAll(selectedRows)}>Reject selected</ActionButton>
                    </>
                    : <div></div>
                }

            </FlexRow>} onSelectedRowsChange={this.handleSelectedChange} enableRowSelection={true}
                   data={this.state.hostValidations} filtering={true} columns={this.tableColumns}
                   entryGenerator={this.rowGenerator}/>
        )
    }

    rejectAll = (requests) => {
        this.setState({_sending: true})
        this.hostValidationClient.rejectMultipleHosts(requests)
            .then(result => {
                this.setState({
                    hostValidations: this.state.hostValidations.filter(n => !(!!requests.includes(n)))
                })
            })
            .catch(exception => {
                console.log(exception)
            })
            .finally(() => {
                this.setState({_sending: false})
            })
    }

    approveAll = (requests) => {
        this.setState({_sending: true})
        this.hostValidationClient.approveMultipleHosts(requests)
            .then(result => {
                this.setState({
                        hostValidations:
                            this.state.hostValidations.filter(n => !(!!requests.includes(n)))
                    }
                )
            })
            .catch(exception => {
                console.log(exception)
            })
            .finally(() => {
                this.setState({_sending: false})
            })
    }

}

