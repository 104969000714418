import React from "react"
import {Menu, MenuContext, MenuItem, PoweredBy, Sidebar, SimpleMenu} from "@greenbone/cloud-component-library"
import {ApplicationUrlProvider} from "./ApplicationUrlProvider"
import {ConfigurationMenu} from "./ConfigurationMenu"
import {useTranslation} from "react-i18next"

import AnalyticsBar from "../../assets/icons/analytics-graph-bar-1.svg"
import ManagedSecurity from "../../assets/icons/Icon_Vulnerability_Overview.svg"
import Book from "../../assets/icons/book-close-2.svg"
import Monitor from "../../assets/icons/monitor.svg"

import ShieldLock from "../../assets/icons/shield-lock.svg"
import Ribbon from "../areas/Compliance/assets/ribbon.svg"

import PoweredByImage from "./assets/powered_by_greenbone.svg"
import {BillingMenu} from "./BillingMenu"


function SwitchLanguage(props) {
    const {i18n} = useTranslation()

    const setDe = () => {
        i18n.changeLanguage("de")
    }

    const setEn = () => {
        i18n.changeLanguage("en")
    }

    if (process.env.REACT_APP_IS_LOCAL === "true")
        return <div>
            <button onClick={setDe}>de</button>
            <button onClick={setEn}>en</button>
        </div>


    return null
}


export const MainMenu = (props) => {

    return (
        <MenuContext.Consumer>
            {({minified, showMinifyButtonEvent, hideMinifyButtonEvent}) =>
                <Sidebar navStyle={{paddingBottom: 0}} minified={minified} onMouseEnter={showMinifyButtonEvent}
                         onMouseLeave={hideMinifyButtonEvent}>
                    <Menu>
                        <MenuItem to={ApplicationUrlProvider.dashboard()} icon={<AnalyticsBar/>} minified={minified}
                                  text={"Dashboard"}/>
                        <MenuItem to={ApplicationUrlProvider.managedSecurity()} icon={<ManagedSecurity/>}
                                  minified={minified}
                                  text={"Managed Security"}/>

                        <MenuItem to={ApplicationUrlProvider.hostvalidation()} icon={<Monitor/>} minified={minified}
                                  text={"Host-Validation"}/>
                        <BillingMenu/>
                        <ConfigurationMenu/>
                        <MenuItem to={ApplicationUrlProvider.security()} icon={<ShieldLock/>} minified={minified}
                                  text={"Security"}/>
                        <MenuItem minified={minified} to={ApplicationUrlProvider.compliance()} icon={<Ribbon/>}
                                  text={"Compliance"}/>
                        <MenuItem minified={minified} to={ApplicationUrlProvider.manual()} icon={<Book/>}
                                  text={"Manual"}/>


                    </Menu>
                    <SwitchLanguage/>
                    <SimpleMenu>
                        <PoweredBy img={PoweredByImage}/>
                    </SimpleMenu>

                </Sidebar>
            }
        </MenuContext.Consumer>
    )
}
