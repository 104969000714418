import React from "react"
import {Headline} from "@greenbone/cloud-component-library"
import {RangeList} from "./components/RangeList"
import {Col, Row} from "reactstrap"
import {ArticleForm} from "./components/ArticleForm"
import {RangeProvider} from "./RangeContext"
import {ErrorBoundary} from "../../../../components/ErrorBoundary"


export function RangesPage() {

    return <RangeProvider>
        <Row>
            <Col>
                <Headline>
                    IP-Ranges
                </Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <ArticleForm/>
            </Col>
        </Row>
        <Row>
            <Col>
                <ErrorBoundary>
                    <RangeList/>
                </ErrorBoundary>

            </Col>
        </Row>

    </RangeProvider>
}
