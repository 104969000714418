import React from "react"
import {LegalRouter} from "./LegalRouter"
import {LegalProvider} from "./Context/LegalContext"
import {ErrorBoundary} from "../../../components/ErrorBoundary"


export function LegalPage(props) {

    return <>
        <LegalProvider>
            <ErrorBoundary>
                <LegalRouter/>
            </ErrorBoundary>
        </LegalProvider>
    </>

}
