import React from "react"
import {BASEURL} from "../../../../Constants"
import {useTranslation} from "react-i18next"
import {createGlobalStyle} from "styled-components"


const Global = createGlobalStyle`
  .override-layout-container {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  .version-view {
    display: none;
  }
`


export function ManualPage(props) {
    const {i18n} = useTranslation()
    return <>
        <Global/>
        <iframe style={{
            width: "100%",
            height: "calc(100% - 0.5rem)"
        }}
                title={"Manual"} src={`${BASEURL}/ui/manual/admin/${i18n.language}/`} frameBorder="0"></iframe>
    </>
}
