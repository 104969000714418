export class HostValidationUrlProvider {
    ENTITY_ROUTE = "/host-validations/host-validation-resolutions";

    approvalRequests() {
        return `${this.ENTITY_ROUTE}/adminapprovalrequests`;
    }

    historicalApprovalRequests() {
        return `/host-validations/ripe-entry-management/history`;
    }

    rejectHostContact(token) {
        return `${this.ENTITY_ROUTE}/token/${token}/reject`;

    }

    approveHostContact(token) {
        return `${this.ENTITY_ROUTE}/token/${token}/approve`;
    }

    rejectHostAdmin(token) {
        return `${this.ENTITY_ROUTE}/admin/token/${token}/reject`;
    }

    approveHostAdmin(token) {
        return `${this.ENTITY_ROUTE}/admin/token/${token}/approve`;
    }

}
