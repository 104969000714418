import React, {useContext} from "react"
import styled from "styled-components"
import {Col, Container, Row} from "reactstrap"
import {Button, FlexRow, GhostButton, Headline, Paragraph} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next"

const DialogComponent = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DialogInner = styled.div`
  width: 100vw;
  height: auto;
  background: whitesmoke;
  padding-top: 2rem;
  padding-bottom: 2rem;
`


export function DialogActions({showCancel, handleCancel, handleConfirm}) {
    const {t} = useTranslation()

    return <Row>
        <Col>
            <FlexRow justifyContent={"flex-end"}>

                {showCancel && <GhostButton style={{marginRight: "1rem"}}
                                            onClick={handleCancel}>{t("dialog.abort")}</GhostButton>}
                <Button onClick={handleConfirm}>{t("dialog.confirm")}</Button>

            </FlexRow>
        </Col>
    </Row>
}

export function Dialog({onClose, title, text, Component, onConfirm, onAbort, ...other}) {

    const handleConfirm = event => {
        if (onConfirm)
            onConfirm(event)

        onClose()
    }

    const handleAbort = event => {
        try {
            if (onAbort)
                onAbort(event)
        } catch (e) {
            //Ignore
        }

        onClose()
    }

    return <DialogComponent>
        <DialogInner>
            <Container>
                <Row>
                    <Col>
                        <Headline>{title}</Headline>
                    </Col>
                </Row>
                {
                    Component ?
                        <Component showCancel={onAbort} handleCancel={handleAbort} handleConfirm={handleConfirm}/>
                        :
                        <Row>
                            <Col>
                                <Paragraph>{text}</Paragraph>
                            </Col>
                        </Row>
                }

                {!Component &&
                <DialogActions showCancel={onAbort} handleCancel={handleAbort} handleConfirm={handleConfirm}/>
                }
            </Container>
        </DialogInner>
    </DialogComponent>
}


export const DialogContext = React.createContext({})

export class DialogProvider extends React.Component {

    state = {
        dialogs: []
    }

    openConfirmDialog = (title, text, onConfirm, onAbort, ...other) => {
        if (!onAbort) {
            onAbort = () => {
            }
        }
        this.setState(prevState => prevState.dialogs.push({title, text, onConfirm, onAbort, ...other}))
    }

    openAlertDialog = (title, text, onConfirm, ...other) => {
        this.setState(prevState => prevState.dialogs.push({title, text, onConfirm, ...other}))
    }

    openCustomDialog = (title, Component, ...other) => {
        this.setState(prevState => prevState.dialogs.push({title, Component, ...other}))
    }

    closeDialog = (index) => {
        this.setState(prevState => prevState.dialogs.splice(index, 1))
    }

    render() {
        return (
            <DialogContext.Provider value={{
                openConfirmDialog: this.openConfirmDialog,
                openAlertDialog: this.openAlertDialog,
                openCustomDialog: this.openCustomDialog
            }}>
                {this.props.children}
                {this.state.dialogs.map((dialog, i) =>
                    <Dialog
                        key={i}
                        onClose={() => this.closeDialog(i)}
                        {...dialog}
                    />)}
            </DialogContext.Provider>
        )
    }

}

export function useDialog() {
    const {openConfirmDialog, openAlertDialog} = useContext(DialogContext)
    return {openConfirmDialog, openAlertDialog}
}
