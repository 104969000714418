import React from "react"
import {
    Bold,
    Button,
    ButtonSpinner,
    Caption,
    FlexRow,
    Snackbar,
    Spinner,
    StyleContext,
    Subheadline
} from "@greenbone/cloud-component-library"
import {createGlobalStyle} from "styled-components"
import {StyleRestApiClient} from "../../../service/style/StyleRestApiClient"
import {Card} from "./components/Card"
import {LayoutSelector} from "./components/LayoutSelector"
import {SmallLogoUpload} from "./components/SmallLogoUpload"
import {SplitLayoutConfiguration} from "./components/SplitLayoutConfiguration"


const InjectGlobal = createGlobalStyle`
  .picker {
    z-index: 1;
  }
`

export class Appearance extends React.Component {

    state = {
        "buttonBackground": "#ffffff",
        "buttonDisabled": "#ffffff",
        "buttonHover": "#ffffff",
        "buttonText": "#ffffff",
        "logoBig": "VHJhbGFsYWxhbGFsYQ==",
        "logoBigMime": "data:image/png;base64",
        "logoSmall": "VHJhbGFsYWxhbGFsYQ==",
        "logoSmallMime": "data:image/png;base64",
        "navbarBackground": "#ffffff",
        "navbarText": "#ffffff",
        "loginRadiantBackground": "#ffffff",
        "loginBackground": "#ffffff",
        "reportFooterBackgroundColor": "#fff",
        "reportFooterTextColor": "#fff",
        "baseMainColor": "#fff",
        "baseContrastColor": "#fff",
        "animationBackgroundColor": "#fff",
        "loginText": "#fff",
        "logoReport": "data:image/png;base64",
        "logoReportMime": "data:image/png;base64",

        "languageSelectActiveColor": "#999999",
        "languageSelectInactiveColor": "#FFFFFF",

        "footerTextColor": "#fff",
        "footerBackgroundColor": "#fff",
        "loginScreenLayout": "SPLIT_LEFT",
        "loginBigImage": "base64",
        "loginBigImageMime": "data:image/png;base64",
        "loginImageOrientation": "CENTER",
        "loginScreenBackgroundColor": "#000000",
        "illustrationImage": null,
        "illustrationContrastColor": "#FFFFFF",

        _loading: true,
        _sending: false,
        _exception: null
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })

    componentDidMount() {
        this.loadExistingTheme()
    }

    loadExistingTheme = () => {
        this.setState({_loading: true})
        const styleApiClient = new StyleRestApiClient()
        styleApiClient.getStyles()
            .then(response => {
                this.setState({...response, _loading: false}, () => {
                })
            })
            .catch(e => {
                this.setState({_loading: false, _exception: e})
            })
    }

    onChange = (name, color) => {

        if (color || color?.hex) {
            this.setState({[name]: color.hex})
        } else {
            Snackbar.Warning("Please enter a valid color.")
        }
    }

    saveOnServer = () => {
        const styleApiClient = new StyleRestApiClient()
        this.setState({_sending: true})
        styleApiClient.setStyles(this.state)
            .then((response) => {
                if (response.status === 400) {
                    response.json()
                        .then(json => {
                            const errorMessages = []

                            if (json?.fieldErrors?.logoReport) {
                                errorMessages.push(`Report Logo: ${json?.fieldErrors?.logoReport}`)
                            }

                            if (json?.fieldErrors?.logoSmall) {
                                errorMessages.push(`Application Code: ${json?.fieldErrors?.logoSmall}`)
                            }

                            if (json?.fieldErrors?.loginBigImage) {
                                errorMessages.push(`Login Background: ${json?.fieldErrors?.loginBigImage}`)
                            }


                            if (errorMessages.length > 0) {
                                Snackbar.Error(errorMessages.join(", "))
                            } else {
                                Snackbar.Error("An Error occurred, please check your inputs")
                            }
                        })
                        .catch(() => {
                            Snackbar.Error("An Error occurred, please check your inputs")
                        })
                }

                if (response.status === 413) {
                    Snackbar.Error("Request payload too large.")
                    return
                }

                if (response.status !== 200 && response.status !== 204) {
                    Snackbar.Error("An error occurred")
                    return
                }

                Snackbar.Success("Settings saved")
            })
            .catch(e => {
                Snackbar.Error("An Error occurred, please try again later")
            })
            .finally(() => {
                this.setState({_sending: false})
            })
    }

    onImageUpload = (name) => async (file) => {
        const imageAsBase64 = await this.toBase64(file[0])
        this.setState({[name]: imageAsBase64.split(",")[1]})
        this.setState({"illustrationImage": null})
    }


    render() {

        if (this.state._loading) {
            return <Spinner/>
        }


        return <StyleContext.Consumer>

            {() => {
                const onChange = this.onChange
                return <>
                    <InjectGlobal/>
                    <Subheadline>Colors</Subheadline>
                    <FlexRow style={{marginBottom: "4rem"}} wrap={"wrap"}>
                        <div>
                            <Caption>Button</Caption>
                            <Card name={"Button Background"} objectName={"buttonBackground"}
                                  handleColorChange={onChange}
                                  color={this.state.buttonBackground}/>
                            <Card name={"Button Disabled"} objectName={"buttonDisabled"} handleColorChange={onChange}
                                  color={this.state.buttonDisabled}/>
                            <Card name={"Button Hover"} objectName={"buttonHover"} handleColorChange={onChange}
                                  color={this.state.buttonHover}/>
                            <Card name={"Button Text"} objectName={"buttonText"} handleColorChange={onChange}
                                  color={this.state.buttonText}/>
                        </div>

                        <div>
                            <Caption>Navbar</Caption>
                            <Card name={"Navbar Background"} objectName={"navbarBackground"}
                                  handleColorChange={onChange}
                                  color={this.state.navbarBackground}/>
                            <Card name={"Navbar Text"} objectName={"navbarText"} handleColorChange={onChange}
                                  color={this.state.navbarText}/>
                        </div>

                        <div>
                            <Caption>Base</Caption>
                            <Card name={"Base Color"} objectName={"baseMainColor"} handleColorChange={onChange}
                                  color={this.state.baseMainColor}/>
                            <Card name={"Base Contrast Color"} objectName={"baseContrastColor"}
                                  handleColorChange={onChange}
                                  color={this.state.baseContrastColor}/>
                        </div>

                        <div>
                            <Caption>Report</Caption>
                            <Card name={"Footer Text"} objectName={"reportFooterTextColor"} handleColorChange={onChange}
                                  color={this.state.reportFooterTextColor}/>
                            <Card name={"Footer Background"} objectName={"reportFooterBackgroundColor"}
                                  handleColorChange={onChange}
                                  color={this.state.reportFooterBackgroundColor}/>
                        </div>
                        <div>
                            <Caption>Login</Caption>
                            <Card name={"Background"} objectName={"loginScreenBackgroundColor"}
                                  handleColorChange={onChange}
                                  color={this.state.loginScreenBackgroundColor}/>

                            {(this.state.loginScreenLayout === "SPLIT_LEFT" || this.state.loginScreenLayout === "SPLIT_RIGHT") &&
                            <Card name={"Lines and Shapes"} objectName={"illustrationContrastColor"}
                                  handleColorChange={onChange}
                                  color={this.state.illustrationContrastColor}/>
                            }

                            <Card name={"Language"} objectName={"languageSelectInactiveColor"}
                                  handleColorChange={onChange}
                                  color={this.state.languageSelectInactiveColor}/>
                            <Card name={"Language Active"} objectName={"languageSelectActiveColor"}
                                  handleColorChange={onChange}
                                  color={this.state.languageSelectActiveColor}/>
                            <Card name={"Footer Background"} objectName={"footerBackgroundColor"}
                                  handleColorChange={onChange}
                                  color={this.state.footerBackgroundColor}/>
                            <Card name={"Footer Text"} objectName={"footerTextColor"}
                                  handleColorChange={onChange}
                                  color={this.state.footerTextColor}/>
                            <Card name={"Illustration Backdrop"} objectName={"animationBackgroundColor"}
                                  handleColorChange={onChange}
                                  color={this.state.animationBackgroundColor}/>
                        </div>
                    </FlexRow>
                    <hr/>

                    <Caption>Layout</Caption>

                    <div style={{marginBottom: "1rem"}}>
                        <Bold>Sign-in Screen Layout</Bold>
                    </div>

                    <LayoutSelector style={{marginBottom: "2rem"}} layout={this.state.loginScreenLayout}
                                    onChange={(layout) => this.setState({"loginScreenLayout": layout})}/>

                    <hr/>
                    <Caption>Logo</Caption>

                    <div style={{marginBottom: "1rem"}}>
                        <Bold>Upload a small logo to display in the app, report, e-mail and login screen</Bold>
                    </div>


                    <FlexRow>
                        <SmallLogoUpload style={{marginRight: "4rem"}}
                                         image={`${this.state.logoSmallMime},${this.state.logoSmall}`}
                                         onImageDrop={this.onImageUpload("logoSmall")} text={"App Logo"}/>

                        <SmallLogoUpload
                            image={`${this.state.logoReportMime},${this.state.logoReport}`}
                            onImageDrop={this.onImageUpload("logoReport")}
                            text={"Report, E-Mail and Login Screen Logo"}/>

                    </FlexRow>

                    <hr/>


                    {(this.state.loginScreenLayout === "SPLIT_LEFT" || this.state.loginScreenLayout === "SPLIT_RIGHT") &&
                    <>
                        <SplitLayoutConfiguration loginBigImage={this.state.loginBigImage}
                                                  loginBigImageMime={this.state.loginBigImageMime}
                                                  loginImageOrientation={this.state.loginImageOrientation}
                                                  loginScreenBackgroundColor={this.state.loginScreenBackgroundColor}
                                                  illustrationImage={this.state.illustrationImage}
                                                  illustrationContrastColor={this.state.illustrationContrastColor}
                                                  onChange={(field, value) => {
                                                      this.setState({[field]: value})
                                                  }}
                                                  onImageUpload={this.onImageUpload}
                        />
                        <hr/>
                    </>}

                    <FlexRow>
                        <Button onClick={this.saveOnServer}>Save <ButtonSpinner style={{margin: 0}}
                                                                                hidden={!this.state._sending}/></Button>
                    </FlexRow>
                </>
            }}

        </StyleContext.Consumer>
    }
}
