var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionConstants } from "./ActionConstants";
import { useReducer } from "react";
export var DefaultState = {
    isLoading: true,
};
export function defaultReducer(reducer) {
    return function (state, action) {
        var _a;
        switch (action.type) {
            case ActionConstants.START_LOADING:
                return __assign(__assign({}, state), { isLoading: true });
            case ActionConstants.STOP_LOADING: {
                return __assign(__assign({}, state), { isLoading: false });
            }
            case ActionConstants.SET_DATA: {
                if (action.fieldName) {
                    return __assign(__assign({}, state), (_a = { isLoading: false }, _a[action.fieldName] = action.payload, _a));
                }
                return __assign(__assign({}, state), { isLoading: false, data: action.payload });
            }
            default: {
                if (reducer) {
                    // @ts-ignore
                    return reducer(state, action);
                }
                return __assign({}, state);
            }
        }
    };
}
export function useDefaultReducer(state, reducer) {
    return useReducer(defaultReducer(reducer), state);
}
