import React, {useContext, useEffect, useState} from "react"
import {FlexRow, Input} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {RangeContext} from "../RangeContext"
import {SaveButton} from "../../../../../SaveButton"

const useArticleNumber = () => {
    const {data} = useContext(RangeContext)

    return data?.articleNumber
}

const useProductName = () => {
    const {data} = useContext(RangeContext)

    return data?.productName
}

const useIpOrderMinimum = () => {
    const {data} = useContext(RangeContext)

    return data?.ipOrderMinimum
}

export function ArticleForm() {

    const {setArticleDetails, sending} = useContext(RangeContext)

    const pn = useProductName()
    const an = useArticleNumber()
    const iom = useIpOrderMinimum()

    const [productName, setProductName] = useState(pn)
    const [articleNumber, setArticleNumber] = useState(an)
    const [ipOrderMinimum, setIpOrderMinimum] = useState(iom)
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        setProductName(pn)
        setArticleNumber(an)
        setIpOrderMinimum(iom)
    }, [pn, an, iom])

    const handleFormSubmit = event => {
        event.preventDefault()
        setErrors(null)

        setArticleDetails(productName, articleNumber, ipOrderMinimum)
            .then(response => {
                if (response?.fieldErrors) {
                    setErrors(response?.fieldErrors)
                }
            })
    }

    return <Row>
        <Col xs={5}>
            <form onSubmit={handleFormSubmit}>
                <Row>
                    <Col>
                        <Input isValid={errors?.productName} onChange={e => setProductName(e.target.value)}
                               margin={"normal"}
                               value={productName || ""} label={"Article Name"}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input isValid={errors?.articleNumber} onChange={e => setArticleNumber(e.target.value)}
                               margin={"normal"}
                               value={articleNumber || ""}
                               label={"Article Number"}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input isValid={errors?.ipOrderMinimum} type={"number"}
                               onChange={e => setIpOrderMinimum(e.target.value)}
                               margin={"normal"}
                               value={ipOrderMinimum || 0}
                               label={"IP Order Minimum"}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FlexRow justifyContent={"flex-end"}>
                            <SaveButton saving={sending}>Save</SaveButton>
                        </FlexRow>
                    </Col>
                </Row>
            </form>
        </Col>
    </Row>
}
