import * as Sentry from "@sentry/browser"

export class Logger {
    static error(message) {
        console.error(message)
        Sentry.captureMessage(message, "error")
    }

    static exception(exception) {
        console.error(exception)
        Sentry.captureException(exception)
    }

    static warn(message) {
        console.warn(message)
        Sentry.captureMessage(message, "warning")
    }

    static debug(...message) {
        console.debug(...message)
    }
}
