import React, {useMemo} from "react"
import {VulnerabilityBySeverity} from "@greenbone/cloud-chart-library"
import styled from "styled-components"

const GraphStyleAbstraction = styled(VulnerabilityBySeverity)`
  svg {
    width: 75%;
    height: 75%;
  }
`

function calculateDistribution(data) {
    let high = 0, medium = 0, low = 0

    for (let i = 0; i < data.length; i++) {
        const row = data[i]
        high += row.high
        medium += row.medium
        low += row.low
    }

    return {high, medium, low}
}

export function DistributionsOfSeverity({data}) {

    const {high, medium, low} = useMemo(() => calculateDistribution(data), [data])

    return <div>
        <GraphStyleAbstraction
            graphStyle={{alignSelf: "center", minWidth: "18.125rem", minHeight: "18.125rem"}}
            high={high} medium={medium} low={low}/>
    </div>
}
