// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/DroidSans-Bold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/DroidSans-Bold.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/DroidSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/fonts/DroidSans-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../assets/fonts/DroidSans-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../assets/fonts/DroidSans.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../assets/fonts/DroidSans.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../assets/fonts/DroidSans.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../assets/fonts/DroidSans.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("../assets/fonts/DroidSans.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___, { hash: "#DroidSans-Bold" });
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___, { hash: "#DroidSans" });
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
    padding: 0;
    margin: 0;
}


@font-face {
    font-family: 'DroidSans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('svg'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff2');
    font-weight: Bold;
}

@font-face {
    font-family: 'DroidSans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('embedded-opentype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('svg'),
    url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('truetype'),
    url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format('woff2');
    font-weight: normal;
    font-style: normal;
}


* {
    font-family: DroidSans, sans-serif;
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: DroidSans, sans-serif;
    font-weight: normal;
}
`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;AACb;;;AAGA;IACI,wBAAwB;IACxB,4CAA8C;IAC9C;;;;2DAI2D;IAC3D,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;IACxB,4CAAyC;IACzC;;;;4DAIsD;IACtD,mBAAmB;IACnB,kBAAkB;AACtB;;;AAGA;IACI,kCAAkC;IAClC,mCAAmC;AACvC;;AAEA;IACI,kCAAkC;IAClC,mBAAmB;AACvB","sourcesContent":["body, html {\n    padding: 0;\n    margin: 0;\n}\n\n\n@font-face {\n    font-family: 'DroidSans';\n    src: url('../assets/fonts/DroidSans-Bold.eot');\n    src: url('../assets/fonts/DroidSans-Bold.eot?#iefix') format('embedded-opentype'),\n    url('../assets/fonts/DroidSans-Bold.svg#DroidSans-Bold') format('svg'),\n    url('../assets/fonts/DroidSans-Bold.ttf') format('truetype'),\n    url('../assets/fonts/DroidSans-Bold.woff') format('woff'),\n    url('../assets/fonts/DroidSans-Bold.woff2') format('woff2');\n    font-weight: Bold;\n}\n\n@font-face {\n    font-family: 'DroidSans';\n    src: url('../assets/fonts/DroidSans.eot');\n    src: url('../assets/fonts/DroidSans.eot?#iefix') format('embedded-opentype'),\n    url('../assets/fonts/DroidSans.svg#DroidSans') format('svg'),\n    url('../assets/fonts/DroidSans.ttf') format('truetype'),\n    url('../assets/fonts/DroidSans.woff') format('woff'),\n    url('../assets/fonts/DroidSans.woff2') format('woff2');\n    font-weight: normal;\n    font-style: normal;\n}\n\n\n* {\n    font-family: DroidSans, sans-serif;\n    -webkit-font-smoothing: antialiased;\n}\n\nbody {\n    font-family: DroidSans, sans-serif;\n    font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
