import {TableCell, TableRow} from "@greenbone/cloud-dynamic-table"
import React from "react"


export class HistoricHostValidationTableRow extends React.Component {

    render() {

        const {approvalRequest} = this.props

        return (

            <TableRow
                hover
                key={approvalRequest.id}
            >

                <TableCell>{approvalRequest.ipAddress}</TableCell>
                <TableCell>{approvalRequest.contactEmail}</TableCell>
                <TableCell>{approvalRequest.contactName}</TableCell>
                <TableCell>{approvalRequest.requestedOn && (new Date(approvalRequest.requestedOn).toDateString())}</TableCell>
                <TableCell>{approvalRequest?.companyName}</TableCell>
                <TableCell>{approvalRequest?.userEmail}</TableCell>
                <TableCell>{approvalRequest.type}</TableCell>
                <TableCell>{approvalRequest.status}</TableCell>
            </TableRow>
        );
    }
}
