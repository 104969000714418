import React from "react"
import {useRequest} from "@greenbone/cloud-component-library"
import {ManagedSecurityRestApiClient} from "../service/ManagedSecurityRestApiClient"

export const AdminSettingsContext = React.createContext({})

export const AdminSettingsProvider = ({children}) => {
    const [data, loading] = useRequest(() => new ManagedSecurityRestApiClient().getAdminSettings())

    return <AdminSettingsContext.Provider value={{loading: loading, settings: data}}>
        {children}
    </AdminSettingsContext.Provider>

}