import React from "react"
import {AlertBox, AlertBoxType} from "@greenbone/cloud-component-library";
import {MAILSERVER_IP_ADDRESSES} from "../../../../Constants";

const FirewallWarning = () => {
    return (
        <AlertBox
            type={AlertBoxType.Warning}
            text="The Greenbone Cloud Service uses fixed IP addresses to communicate with the mail server to send e-mails to end customers. If a firewall is used, it must be configured to allow the following IP addresses:"
        >
            <ul>
                {MAILSERVER_IP_ADDRESSES.map(ip => (
                    <li key={ip}>{ip}</li>
                ))}
            </ul>
        </AlertBox>
    );
};

export default FirewallWarning
