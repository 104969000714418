import {rest} from "msw"
import {buildUrl} from "../buildUrl"

export const vmspAdminService = [
    rest.get(buildUrl("/vmssp-admin/legal"), (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                imprint: {
                    "de": "<h1>impressum</h1>",
                    "en": "<h1>imprint</h1>"
                },
                privacyPolicy: {
                    "de": "<h1>datenschutzdings</h1>",
                    "en": "<h1>privacyPolicy</h1>"
                },
                termsOfUse: {
                    "de": "<h1>nutzungsbedingungs</h1>",
                    "en": "<h1>terms of use</h1>"
                },
                privacyPolicyEnabled: true,
                termsOfUseEnabled: true
            })
        )
    }),

    rest.put(buildUrl("/vmssp-admin/legal"), (req, res, ctx) => {
        return res(
            ctx.status(204)
        )
    }),

    rest.get(buildUrl("/host-validations/host-validation-resolutions/adminapprovalrequests"), (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    "contactEmail": "string",
                    "contactName": "string",
                    "hostId": 0,
                    "hostRepresentation": "string",
                    "id": "string",
                    "ipAddress": "string",
                    "realm": "string",
                    "requestedOn": 0,
                    "senderEmail": "string",
                    "senderFullname": "string",
                    "type": "CONTACT"
                },
                {
                    "contactEmail": "string",
                    "contactName": "string",
                    "hostId": 0,
                    "hostRepresentation": "string",
                    "id": "string",
                    "ipAddress": "string",
                    "realm": "string",
                    "requestedOn": 0,
                    "senderEmail": "string",
                    "senderFullname": "string",
                    "type": "ADMIN"
                }

            ])
        )
    })

]
