import {JWT} from "./JWT"


export class Cookie {

    static userid() {

        const jwtToken = new JWT()

        return jwtToken.subject() ? jwtToken.subject() : null
    }

    static set(name, value, days) {
        let expires = ""
        if (days) {
            let date = new Date()
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
            expires = "; expires=" + date.toUTCString()
        }
        if (this.userid()) {
            document.cookie = name + this.userid() + "=" + (value || "") + expires + "; path=/"
        }

    }

    static _dangerousGet(name) {

        const nameEQ = name + "="
        const ca = document.cookie.split(";")
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === " ") {
                c = c.substring(1, c.length)
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length)
            }
        }
        return null
    }

    static get(name) {
        if (!this.userid()) {
            return null
        }
        const nameEQ = name + this.userid() + "="

        const ca = document.cookie.split(";")
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === " ") {
                c = c.substring(1, c.length)
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length)
            }
        }
        return null
    }

    static erase(name) {
        document.cookie = name + this.userid() + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    }
}
