import styled from "styled-components"
import React from "react"
import {Text} from "@greenbone/cloud-component-library"
import {SketchPicker} from "react-color"

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const CardContainer = styled.div`
  max-width: 15rem;
  min-width: 15rem;
  border: 1px solid #707070;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin:3rem;
  margin-left: 0;

  
  header {
    display: flex;
    align-items: center;
    height: 2.5rem;
    padding-left: 1rem;
    justify-content: space-between;
    
    .colorBlock {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      background: ${props => props.color};
      width: 2.5rem;
      height: 2.5rem;
      border-left: 1px solid #707070;
    }
    
    .picker {
      position: relative;
      top: 2.215rem;
      left: -4.6rem;
    }
  }
`;

export class Card extends React.Component {
    state = {
        picking: false
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({picking: false});
    };

    handleOpen = (event) => {
        this.setState({picking: true});
    };

    handleChange = (color, event) => {
        const {objectName} = this.props
        this.props.handleColorChange(objectName, color, event)
    }


    render() {
        const {color, name} = this.props;

        return <CardContainer color={color || "#FFFFFF"} {...this.props}>
            {this.state.picking ?
                <Cover onClick={this.handleClose}/>
                : ""}

            <header onClick={this.handleOpen}>
                <Text noMargin>{name}</Text>
                <div className="colorBlock">
                    {this.state.picking ?
                        <div className="picker">
                            <SketchPicker onChange={this.handleChange}
                                          color={color || "#FFFFFF"}/>
                        </div>
                        : ""}
                </div>
            </header>
        </CardContainer>;

    }
}
