import React, {useContext, useState} from "react"
import {isNumberPrimitive} from "@greenbone/cloud-validation-library"
import {
    AbortButton,
    AlertBox,
    AlertBoxType,
    Caption,
    Checkbox,
    FlexRow,
    Headline,
    Input,
    Snackbar,
    Subheadline
} from "@greenbone/cloud-component-library"
import {Col, Container, Row} from "reactstrap"
import styled from "styled-components"
import {CustomerContext, useCustomer} from "../CustomerContext"
import {RangeSlider} from "./RangeSlider"
import {SaveButton} from "../../../../SaveButton"
import {MAX_IP_COUNT} from "../../../../../Constants"


const GreyField = styled.input`
  background: #bbbbbb;
  border-radius: 3px;
  border: none;
  padding: 0.2rem;
  text-align: center;
  -moz-appearance: textfield;
  max-width: 3rem;

  &:focus {
    outline: none;
  }


`

function showRaiseWarning(oldCustomer, newCustomer) {
    const newCount = newCustomer?.ipsInternal + newCustomer?.ipsExternal
    const oldCount = oldCustomer?.ipsInternal + oldCustomer?.ipsExternal

    if (newCount >= oldCount * 2) {
        return true
    }

    return false
}


export function AmountForm({id, onClose}) {

    const [oldCustomer] = useState(useCustomer(id))
    const [customer, setCustomer] = useState(useCustomer(id))
    const [switchImmediate, setSwitchImmediate] = useState(false)
    const [saving, setSaving] = useState(false)
    const {updateIpAmounts, updateIpDistribution} = useContext(CustomerContext)


    const setAmount = amount => {

        if (amount > MAX_IP_COUNT) {
            amount = MAX_IP_COUNT
        }
        if (amount % 2 === 0) {
            const half = amount / 2
            setCustomer({...customer, ipsInternal: half, ipsExternal: half})
        } else {
            const half = Math.trunc(amount / 2)
            setCustomer({...customer, ipsInternal: half + 1, ipsExternal: half})

        }
    }

    const isManaged = (c) => {
        return c.serviceMode === "MANAGEDSERVICE"
    }

    const handleInternChange = e => {
        const dirtyValue = e.target.value
        if (!isNumberPrimitive(dirtyValue)) {
            return
        }

        let value = parseInt(dirtyValue)

        if (!isManaged(customer)) {
            if (value + customer.ipsExternal > oldCustomer.ipsInternal + oldCustomer.ipsExternal) {
                value = (oldCustomer.ipsInternal + oldCustomer.ipsExternal) - customer.ipsExternal
            }

        }

        if (value + customer.ipsExternal > MAX_IP_COUNT) {
            value = MAX_IP_COUNT - customer.ipsExternal
        }

        setCustomer({...customer, ipsInternal: value})
    }

    const handleExternChange = e => {
        const dirtyValue = e.target.value
        if (!isNumberPrimitive(dirtyValue)) {
            return
        }
        let value = parseInt(dirtyValue)

        if (!isManaged(customer)) {
            if (value + customer.ipsInternal > oldCustomer.ipsInternal + oldCustomer.ipsExternal) {
                value = (oldCustomer.ipsInternal + oldCustomer.ipsExternal) - customer.ipsInternal
            }

        }

        if (value + customer.ipsInternal > MAX_IP_COUNT) {
            value = MAX_IP_COUNT - customer.ipsInternal
        }

        setCustomer({...customer, ipsExternal: value})
    }

    const onChange = e => {
        const value = parseInt(e.target.value)

        const ipAmount = customer.ipsExternal + customer.ipsInternal

        setCustomer({...customer, ipsInternal: value, ipsExternal: (ipAmount - value)})
    }

    const minLabel = ({value}) => {
        return <><GreyField type={"number"} value={value} onChange={handleInternChange}/> Intern</>
    }
    const maxLabel = ({value, max}) => {
        return <>Extern <GreyField type={"number"} value={max - value} onChange={handleExternChange}/></>
    }

    if (!customer) {
        return "Not Found"
    }
    const ipAmount = customer?.ipsInternal + customer?.ipsExternal

    const handleSubmit = (event) => {
        event.preventDefault()

        setSaving(true)

        const isAmountUnchanged = (oldCustomer.ipsExternal + oldCustomer.ipsInternal) === (customer.ipsInternal + customer.ipsExternal)

        const request = isAmountUnchanged
            ?
            updateIpDistribution(customer.id, customer.ipsInternal, customer.ipsExternal)
            :
            updateIpAmounts(customer.id, customer.ipsInternal, customer.ipsExternal, switchImmediate)


        request
            .then(response => {

                if (response?.status === "CONFLICT") {
                    Snackbar.Error(response.message)
                    return
                }
                if (response?.status === "BAD_REQUEST") {
                    if (response?.fieldErrors?.switchImmediate) {
                        Snackbar.Error("Perform action instantly must be checked.")
                    } else if (response.message) {
                        Snackbar.Error(response.message)
                    } else {
                        Snackbar.Error("Could not update ip amount.")
                    }
                    return
                }


                if (response?.id) {
                    Snackbar.Success("Amount and distribution have been updated")
                    onClose()
                } else {
                    Snackbar.Error("Could not update ip amount.")
                }

            })
            .catch(e => {
                Snackbar.Error(e)
            })
            .finally(() => setSaving(false))
    }

    return <>
        <Container style={{maxWidth: "40rem"}}>
            <Row>
                <Col>
                    <Headline>Change amount of IP's and Distribution</Headline>
                </Col>
            </Row>
            {isManaged(customer) && <>
                <Row style={{marginBottom: "2rem"}}>
                    <Col xs={6}>
                        <Input onChange={e => setAmount(e.target.value)}
                               margin={"normal"} type={"number"} label={"Total IP's"}
                               value={ipAmount}/>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <AlertBox type={AlertBoxType.Warning}>
                            You can increase or decrease the amount of IP's. If you increase it, you are able to set
                            "Instant".
                            Instant means that the changes are immediately applied.
                            <br/>
                            <br/>
                            Reductions will only be taken into account in the next billing cycle.
                        </AlertBox>
                        <br/>
                        <AlertBox type={AlertBoxType.Warning}>
                            If you increase the IP's you will get a higher charge next billing cycle.
                        </AlertBox>
                        <br/>
                    </Col>
                </Row>
            </>
            }
            <Row>
                <Col>
                    <Subheadline>Distribution</Subheadline>
                    <Caption>Total IP's {ipAmount}</Caption>
                    <RangeSlider step={1} maxLabel={maxLabel} minLabel={minLabel} min={0} max={ipAmount}
                                 value={customer.ipsInternal} onChange={onChange}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AlertBox type={AlertBoxType.Warning}>
                        You can change the distribution at any time. No new subscription will be created. The
                        distribution change can be made immediately or at the next billing cycle.
                    </AlertBox>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Checkbox onChange={() => setSwitchImmediate(!switchImmediate)} checked={switchImmediate}
                              label={"Perform action instantly"}/>
                </Col>
            </Row>
            {showRaiseWarning(oldCustomer, customer) && <AlertBox type={AlertBoxType.Warning}>
                Are you sure?
                You're about to raise the amount of ip addresses for this customer. This will result in a significantly
                higher charge.
            </AlertBox>

            }
            <Row>
                <Col>
                    <FlexRow justifyContent={"space-between"}>
                        <AbortButton onClick={onClose}>Abort</AbortButton>
                        <SaveButton saving={saving} onClick={handleSubmit}>Save</SaveButton>
                    </FlexRow>
                </Col>
            </Row>
        </Container>

    </>
}
