import React from "react"
import {Headline} from "@greenbone/cloud-component-library"
import First from "./assets/1.svg"
import Second from "./assets/5.svg"
import Third from "./assets/2.svg"
import Fourth from "./assets/4.svg"
import Fifth from "./assets/3.svg"
import {HelpLine} from "./HelpLine"

export function WelcomeScreenContent(props) {
    return <>
        <Headline>Welcome! Few steps you need to configure.</Headline>
        <HelpLine icon={First} title={"Configure Appearance"}>
            Customize your colours, logo, login styles and background!
        </HelpLine>
        <HelpLine icon={Second} title={"Configure Mailserver"}>
            Enter your e-mail server to send mails to your customers with your domain!
        </HelpLine>
        <HelpLine icon={Third} title={"Configure Plans & Features"}>
            Create features and custom plans to provide flexible solutions to your customers!
        </HelpLine>
        <HelpLine icon={Fourth} title={"Configure Payment"}>
            Decide which payment methods you want to allow to your customers!
        </HelpLine>
        <HelpLine icon={Fifth} title={"Configure Legal"}>
            Very important for you is to configure your Terms of Use, Privacy Policy and your imprint!
        </HelpLine>
    </>
}
