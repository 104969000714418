import React from "react"
import styled, {css} from "styled-components"

import Illustration1 from "../assets/Illustration1.svg?url"
import Illustration2 from "../assets/Illustration2.svg?url"
import Illustration3 from "../assets/Illustration3.svg?url"

import checked from "../assets/check.svg?url"

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Item = styled.div`
  position: relative;
  cursor: pointer;
  
  margin-right: 2rem;
  margin-bottom: 2rem;
  border: 2px solid transparent;
  
  .active {
    display: none;
  }
  
  ${props => props.active && css`
     border-color: #7EC14B;
  
    .active {
        border-top-left-radius: 3px;
        right: 0;
        bottom: 0;
        background: #7EC14B;
        height: 25px;
        width: 25px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 80%;
          height: 80%;
          }
        }
  `}
  
  .illustration  {
    width: 500px;
    height: 400px;
    background-color: #E2E2E2;
    border-radius: 3px;
  }
`


export function IllustrationList({illustrationImage, onChange}) {
    const handleClick = name => () => {
        if (name === illustrationImage) {
            onChange("illustrationImage", null)
        } else {
            onChange("illustrationImage", name)
        }
    }

    return <Row>
        <Item onClick={() => handleClick("NETWORK_QUADRANT")()}
              active={"NETWORK_QUADRANT" === illustrationImage}>
            <div className="active">
                <img src={checked} alt="Checked"/>
            </div>
            <img className={"illustration"} src={Illustration1} alt=""/>
        </Item>

        <Item onClick={() => handleClick("LINE_AND_BLOCKS")()}
              active={"LINE_AND_BLOCKS" === illustrationImage}>
            <div className="active">
                <img src={checked} alt="Checked"/>
            </div>
            <img className={"illustration"} src={Illustration2} alt=""/>
        </Item>

        <Item onClick={() => handleClick("THE_CLOUD_ON_A_NOTEBOOK")()}
              active={"THE_CLOUD_ON_A_NOTEBOOK" === illustrationImage}>
            <div className="active">
                <img src={checked} alt="Checked"/>
            </div>
            <img className={"illustration"} src={Illustration3} alt=""/>
        </Item>
    </Row>
}

