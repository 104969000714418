export class SecurityException extends Error {
    constructor(message) {
        if (message) {
            super(message)
        } else {
            super("Security Exception")
        }

    }
}

export class ConfigFetchClient {

    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    async fetchConfig(configUrl) {

        const baseUrl = this.baseUrl

        if (!baseUrl)
            throw new SecurityException("No valid baseurl")


        let url = new URL(configUrl, baseUrl)

        const response = await fetch(url.href)
        const config = await response.json()

        if (this.checkUrlValidity(config))
            return config

        throw new SecurityException("Invalid realm config")
    }

    checkUrlValidity(config) {
        const comparisonUrl = new URL("/auth", this.baseUrl)
        return config.url === comparisonUrl.toString()
    }

}
