import {DashboardCellBanner, DashboardCellBody, DashboardCellText, FlexRow} from "@greenbone/cloud-component-library"
import {Donut} from "@greenbone/cloud-chart-library"
import React from "react"


const svg = `<svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.66 9.75967V13.6495" stroke="#7EC14B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.7145 11.7052H32.6043" stroke="#7EC14B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.56628 0.929993V4.81982" stroke="#7EC14B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.62073 2.87552H7.51056" stroke="#7EC14B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.4917 4.81863H12.2271C11.2273 4.81863 10.4167 5.62915 10.4167 6.62898V28.5547C10.4167 29.5545 11.2273 30.365 12.2271 30.365H22.4917C23.4916 30.365 24.3021 29.5545 24.3021 28.5547V6.62898C24.3021 5.62915 23.4916 4.81863 22.4917 4.81863Z" stroke="#7EC14B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.3933 4.81863V30.365" stroke="#7EC14B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.1391 10.7059H16.3583" stroke="#7EC14B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.1391 16.5931H16.3583" stroke="#7EC14B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.2564 23.9576C20.161 23.9576 20.0676 23.9859 19.9882 24.039C19.9088 24.092 19.847 24.1674 19.8104 24.2556C19.7739 24.3438 19.7643 24.4409 19.783 24.5346C19.8016 24.6282 19.8476 24.7142 19.9151 24.7817C19.9826 24.8492 20.0686 24.8952 20.1623 24.9139C20.2559 24.9325 20.353 24.9229 20.4412 24.8864C20.5294 24.8498 20.6048 24.788 20.6578 24.7086C20.7109 24.6292 20.7392 24.5358 20.7392 24.4404C20.7392 24.3123 20.6883 24.1895 20.5978 24.099C20.5073 24.0085 20.3845 23.9576 20.2564 23.9576Z" stroke="#7EC14B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.3551 22.5842H26.5903C26.9965 22.5846 27.3922 22.7132 27.7211 22.9515C28.05 23.1899 28.2954 23.5259 28.4224 23.9118L30.9991 31.6962C31.0959 31.9886 31.1223 32.2997 31.0762 32.6042C31.03 32.9088 30.9126 33.1981 30.7336 33.4487C30.555 33.6982 30.3196 33.9016 30.0467 34.042C29.7739 34.1825 29.4715 34.2558 29.1646 34.2561H5.55168C5.24482 34.2558 4.94243 34.1825 4.66958 34.042C4.39673 33.9016 4.16127 33.6982 3.98271 33.4487C3.80364 33.1981 3.68626 32.9088 3.64012 32.6042C3.59398 32.2997 3.62039 31.9886 3.7172 31.6962L6.29271 23.9154C6.41965 23.5295 6.66503 23.1935 6.99395 22.9552C7.32287 22.7168 7.71858 22.5883 8.12478 22.5878H10.3998" stroke="#7EC14B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`

export function InternalPlans({count, data}) {
    return <> <DashboardCellBanner>Internal</DashboardCellBanner>
        <DashboardCellBody>
            <FlexRow fullWidth={true} alignItems={"center"} justifyContent={"space-between"}>
                <DashboardCellText>{count}</DashboardCellText>
                <div style={{width: "6.25rem", height: "6.25rem"}}>
                    <Donut width={100} height={100} accessor={d => d.count} thickness={10} data={data}
                           colorScheme={["#55C14A", "#B1EA8D"]}
                           icon={svg}
                    />
                </div>
            </FlexRow>


        </DashboardCellBody>
    </>
}
