import React from "react"
import {Snackbar} from "@greenbone/cloud-component-library"
import {LegalRestApiClient} from "./LegalRestApiClient"

export const LegalContext = React.createContext({})


export class LegalProvider extends React.Component {
    state = {
        loading: true,
        saving: false,

        data: {
            "termsOfUse": {
                "de": "",
                "en": ""
            },
            "imprint": {
                "de": "",
                "en": ""
            },
            "privacyPolicy": {
                "de": "",
                "en": ""
            },
            privacyPolicyEnabled: false,
            termsOfUseEnabled: false
        }
    }

    constructor(props) {
        super(props)
        this.apiClient = new LegalRestApiClient()
    }


    componentDidMount() {
        this.apiClient.get()
            .then(response => {
                this.setState({data: response, loading: false})
            })
            .catch(e => {
                Snackbar.Error(e)
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    save = () => {
        this.setState({sending: true})

        this.apiClient.save(this.state.data)
            .then(response => {
                if (response !== null) {
                    if (response.status === "BAD_REQUEST") {

                        if (response?.fieldErrors?.privacyPolicy) {
                            Snackbar.Error("English Privacy Policy cannot be empty")
                            return
                        }

                        if (response?.fieldErrors?.imprint) {
                            Snackbar.Error("English Imprint cannot be empty")
                            return
                        }

                        if (response?.fieldErrors?.termsOfUse) {
                            Snackbar.Error("English Terms of Use cannot be empty")
                            return
                        }

                        Snackbar.Error("An Error occurred")
                    }
                } else {
                    Snackbar.Success("Saved Successfully")
                }


            })
            .catch(e => {
                Snackbar.Error(e.toString())
            })
            .finally(() => {
                this.setState({sending: false})
            })
    }

    setImprintDE = value => {
        const data = this.state.data
        this.setState({data: {...data, imprint: {...data.imprint, de: value}}})
    }

    setImprintEN = value => {
        const data = this.state.data
        this.setState({data: {...data, imprint: {...data.imprint, en: value}}})
    }

    setTosDE = value => {
        const data = this.state.data
        this.setState({data: {...data, termsOfUse: {...data.termsOfUse, de: value}}})
    }

    setTosEN = value => {
        const data = this.state.data
        this.setState({data: {...data, termsOfUse: {...data.termsOfUse, en: value}}})
    }

    setDppDE = value => {

        const data = this.state.data
        this.setState({data: {...data, privacyPolicy: {...data.privacyPolicy, de: value}}})
    }

    setDppEN = value => {
        const data = this.state.data
        this.setState({data: {...data, privacyPolicy: {...data.privacyPolicy, en: value}}})
    }


    handleActiveChange = (event) => {
        const name = event.target.name
        this.setState(prevState => ({data: {...prevState.data, [name]: !prevState.data[name]}}))
        Snackbar.Info("Remember to save to persist this change.")
    }


    render() {
        return <LegalContext.Provider value={{
            ...this.state,
            save: this.save,
            setImprintEN: this.setImprintEN,
            setImprintDE: this.setImprintDE,
            setTosEN: this.setTosEN,
            setTosDE: this.setTosDE,
            setDppDE: this.setDppDE,
            setDppEN: this.setDppEN,
            handleActiveChange: this.handleActiveChange

        }}>
            {this.props.children}
        </LegalContext.Provider>
    }
}

