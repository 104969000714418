import React, {useContext, useMemo, useState} from "react"
import {Button, Caption, FlexRow, Headline, SlideIn} from "@greenbone/cloud-component-library"
import {Table} from "@greenbone/cloud-dynamic-table"
import {Col, Row} from "reactstrap"
import {CustomerContext} from "../CustomerContext"
import {CustomFilter} from "./CustomerFilter"
import {CustomerDownload} from "./CustomerDownload"
import {JWT} from "../../../../welcomeScreen/JWT"
import {CustomerListRow} from "./CustomerListRow"
import {CustomerForm} from "./CustomerForm"
import Loader from "../../../../components/Loader"
import {Status} from "./Status"
import {BillingMethod} from "./BillingMethod"
import {MissingMailConfigurationsContext} from "../MissingMailConfigurationsContext"


export function CustomList(props) {
    const {customers, isLoading, filter} = useContext(CustomerContext)
    const [showNewCustomer, setShowNewCustomer] = useState(false)
    const {isMailConfigMissing} = useContext(MissingMailConfigurationsContext)

    const cachedColumns = useMemo(() => {
        return [
            {
                id: "companyName",
                accessor: data => `${data.companyName} ${data.contactEmail}`,
                Header: "Company Name"
            },
            {
                accessor: "serviceMode",
                Header: "Service Mode"
            },
            {
                accessor: "status",
                Header: "Status"
            },
            {
                accessor: "ipsInternal",
                Header: "IP's Internal"
            },
            {
                accessor: "ipsExternal",
                Header: "IP's External"
            },
            {
                accessor: "paymentOption",
                Header: "Payment Method"
            },
            {
                accessor: "averageIpPrice",
                Header: "Average IP Price"
            },
            {
                Header: "Actions",
                id: "actions"
            }
        ]
    }, [])

    const cachedCustomers = useMemo(() => {
        return customers
    }, [customers])

    return <>
        <Row>
            <Col>
                <Headline>Customers</Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <FlexRow justifyContent={"space-between"} alignItems={"center"}>
                    <FlexRow>
                        <CustomFilter/>
                        <Button disabled={isMailConfigMissing} onClick={() => setShowNewCustomer(true)} style={{marginLeft: "1rem"}}>+ New
                            Customer</Button>
                    </FlexRow>
                    <CustomerDownload/>
                </FlexRow>
                <SlideIn open={showNewCustomer} onClose={() => setShowNewCustomer(false)}>
                    {showNewCustomer && <CustomerForm onClose={() => setShowNewCustomer(false)}/>}
                </SlideIn>
            </Col>
        </Row>
        <Row>
            <Col>
                <Caption>
                    Current Filter:
                </Caption>
                <table>
                    <tbody>
                    <tr>
                        <td>Status:</td>

                        <td>
                            {filter?.status ? <Status status={filter?.status}/> : "All"}
                        </td>
                    </tr>
                    <tr>
                        <td>Service Mode:</td>
                        <td>{filter?.serviceMode ? filter.serviceMode : "All"}</td>
                    </tr>
                    <tr>
                        <td>Payment Options:</td>
                        <td>
                            <FlexRow>
                                {filter?.paymentOptions?.length === 0 ? "All" : filter.paymentOptions.map(option =>
                                    <BillingMethod method={option}/>)}
                            </FlexRow>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </Col>
        </Row>
        <Row>
            <Col>
                {isLoading ? <Loader/> :
                    <Table
                        defaultSortBy={["companyName"]}
                        actions={<div></div>}
                        searchableColumns={["companyName", "contactEmail", "serviceMode", "status", "ipsInternal", "averageIpPrice", "ipsExternal", "paymentOption"]}
                        data={cachedCustomers}
                        columns={cachedColumns}
                        cacheKey={`${new JWT().subject()}-customerList/2020.12.4`}
                        entryGenerator={CustomerListRow}/>
                }
            </Col>
        </Row>
    </>
}
