import React from "react"
import {Headline} from "@greenbone/cloud-component-library"
import {Appearance} from "./Appearance"
import {ErrorBoundary} from "../../../components/ErrorBoundary"


export function AppearancePage(props) {

    return <ErrorBoundary>
        <Headline>Customize appearance for your End-Customer</Headline>
        <Appearance/>
    </ErrorBoundary>
}
