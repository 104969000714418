import {ApplicationUrlProvider} from "../../../Layout/ApplicationUrlProvider"

export class LegalRouteUrlProvider {

    static Imprint() {
        return "imprint"
    }

    static ImprintRoute() {
        return "/imprint"
    }

    static PrivacyPolicy() {
        return "privacypolicy"
    }

    static PrivacyPolicyRoute() {
        return "/privacypolicy"
    }

    static TermsOfUse() {
        return "termsofuse"
    }

    static TermsOfUseRoute() {
        return "/termsofuse"
    }
}
