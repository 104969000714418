import {RestApiClient} from "@greenbone/cloud-component-library"
import {globalInstanceStorage} from "../../../../GlobalSingle"

function ErrorHandler(e) {
    throw e
}


export class SalesDashboardRestApiClient {

    constructor(apiClient = new RestApiClient(globalInstanceStorage.getFetchClient(), ErrorHandler)) {
        this.apiClient = apiClient
    }

    async getOverview() {
        return await this.apiClient.get("/billing/admin/sales/overview")
    }

    async getPlanDevelopment() {
        return await this.apiClient.get(`/billing/admin/sales/overview/ipDevelopment`)
    }

    async downloadCSV(body) {
        const fetchClient = globalInstanceStorage.getFetchClient()
        return await fetchClient.post(`/billing/admin/sales/overview/billable`, body)
    }

}
