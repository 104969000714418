export class Formatter {
    static toCurrency(value, language) {

        switch (language) {
            case "de": {
                return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(value)
            }

            default: {
                return new Intl.NumberFormat("en-US", {style: "currency", currency: "EUR"}).format(value)
            }
        }
    }

    static toDate(value, language) {
        switch (language) {
            case "de": {
                return new Intl.DateTimeFormat("de-DE").format(value)
            }

            default: {
                return new Intl.DateTimeFormat("en-US").format(value)
            }
        }
    }

}
