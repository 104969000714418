import {RestApiClient} from "@greenbone/cloud-component-library"
import {globalInstanceStorage} from "../../../../GlobalSingle"

function ErrorHandler(e) {
    throw e
}


export class CustomerOverviewRestApiClient {

    constructor(apiClient = new RestApiClient(globalInstanceStorage.getFetchClient(), ErrorHandler)) {
        this.apiClient = apiClient
    }

    async getGroups(body) {

        return await this.apiClient.post("/billing/admin/customers", body)
    }

    async getCustomerUserInformation(data) {
        if (data) {
            return this.apiClient.post("/usermanagement/admin/customers", data)
        }
        return this.apiClient.get("/usermanagement/admin/customers")
    }

    async getCustomerBillingInformation(filter) {
        return this.apiClient.post("/billing/admin/customers", filter)
    }

    async setGroupStatus(groupId, status) {
        return this.apiClient.put(`/usermanagement/admin/customers/${groupId}/status/${status}`)
    }

    async getHistoricSubscriptions(groupId) {
        return this.apiClient.get(`/billing/admin/groups/${groupId}/subscriptions`)
    }

    async getPlans() {
        return this.apiClient.get("/billing/public/en/plans")
    }

    async setDebtor(groupId, debtor) {
        return this.apiClient.put(`/usermanagement/admin/customers/${groupId}`, {debtorNumber: debtor})
    }

    async cancelSubscription(groupIp) {
        return this.apiClient.put(`/billing/admin/groups/${groupIp}/subscriptions/cancel`)
    }

    async getUsersOfGroup(groupId) {
        return this.apiClient.get(`/usermanagement/admin/customers/${groupId}/users`)
    }

    async changeMainUserOfGroup(groupId, userId) {
        return this.apiClient.put(`/usermanagement/admin/customers/${groupId}/users/${userId}/main-user`)
    }

}
