import React, {useContext, useState} from "react"
import {DownloadSelect, FlexColumn, SIODateTime, Snackbar} from "@greenbone/cloud-component-library"
import {saveAs} from "file-saver"
import {CustomerContext} from "../CustomerContext"
import {CustomerOverviewRestApiClient} from "../service/CustomerOverviewRestApiClient"

export function CustomerDownload(props) {

    const [status, setStatus] = useState("company-json")
    const {customers} = useContext(CustomerContext)

    const handleDownload = () => {

        const apiClient = new CustomerOverviewRestApiClient()
        const exitingCustomers = [...customers.filter(cu => cu.status !== "INVITED")]
        const length = exitingCustomers.length

        const promises = exitingCustomers.map(async (group, index) => {
            setStatus(`pulling subscription ${index + 1}/${length}`)
            const subscriptions = await apiClient.getHistoricSubscriptions(group.groupId)
            return {...group, subscriptions: subscriptions}
        })

        const getData = async () => {
            return Promise.all(promises)
        }

        getData()
            .then(data => {
                setStatus(null)
                const fileContent = JSON.stringify(data, null, 2)

                const blob = new Blob([fileContent], {
                    type: "text/plain;charset=utf-8"
                })

                saveAs(blob, `customers-${SIODateTime.local().toFormat("yyyy-MM-dd")}.json`)
            })
            .catch(e => {
                setStatus(null)
                Snackbar.Error(e.toString())
            })


    }

    return <FlexColumn>
        <DownloadSelect value={status} options={[
            {
                label: "Customer (json)", action: () => {
                    handleDownload()
                }, value: "company-json"
            }
        ]
        }/>
    </FlexColumn>
}
