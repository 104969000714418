import {ActionConstants} from "./ActionConstants"


export const slideInInitialState = {
    isOpen : false
}

export function slideInReducer(state, action) {


    switch (action.type) {
        case ActionConstants.OPEN_SLIDE_IN: {
            return {...state, id: action.id, isOpen: true}
        }

        case ActionConstants.CLOSE_SLIDE_IN : {
            return {...state, isOpen: false}
        }

        default : {
            return {...state}
        }
    }
}