import React, {useState} from "react"
import {Col, Container, Row} from "reactstrap"
import {
    Button,
    ButtonSpinner,
    Checkbox,
    FlexRow,
    GhostButton,
    Headline,
    Snackbar
} from "@greenbone/cloud-component-library"
import {PaymentMethodRestApiClient} from "../../../../service/PaymentMethodRestApiClient"
import {PaymentTypes} from "./PaymentTypes"

export function InvoiceForm({memcopy, close}) {

    const [state, setState] = useState(memcopy)
    const [sending, setSending] = useState(false)

    const onSubmit = event => {
        event.preventDefault()
        setSending(true)
        const apiClient = new PaymentMethodRestApiClient()
        apiClient.update(state)
            .then(response => {
                if (response && response.status === 400) {
                    Snackbar.Error("Bad Request");
                }
                Snackbar.Success("Invoice updates successfully");
                setSending(false);
                close(state);
            })
            .catch(e => {
                setSending(false);
                Snackbar.Error(e.toString());
            });
    };


    const onChkBoxChange = event => {

        setState(prevState => ({
            ...prevState,
            [PaymentTypes.INVOICE]: {
                ...prevState[PaymentTypes.INVOICE],
                active: !prevState[PaymentTypes.INVOICE].active
            }
        }));
    };

    return <Container fluid style={{minWidth: "30rem"}}>
        <Row>
            <Col>
                <Headline>Edit Invoice</Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <Checkbox onChange={onChkBoxChange} checked={state[PaymentTypes.INVOICE]?.active} label={"is Active"}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <FlexRow justifyContent={"space-between"} style={{flexDirection: "row-reverse"}}>
                    <Button onClick={onSubmit}>Save {sending && <ButtonSpinner/>}</Button>
                    <GhostButton onClick={() => close(memcopy)} style={{marginLeft: 0}}>Abort</GhostButton>
                </FlexRow>
            </Col>
        </Row>
    </Container>;
}
