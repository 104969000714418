import React from "react"
import {Button, FlexRow} from "@greenbone/cloud-component-library"
import Dialog from "@material-ui/core/Dialog"
import AdminPanelIcon from "../components/assets/admin_panel_settings.svg"
import {JumpInComponent} from "./JumpInComponent"


export function JumpInDialog({groupId, customer}) {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Button onClick={handleClickOpen}>
                <FlexRow alignItems={"center"}>
                    <AdminPanelIcon style={{marginRight: ".5rem"}}/>
                    Jump-In
                </FlexRow>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

                <JumpInComponent customer={customer} handleClose={handleClose} groupId={groupId}/>

            </Dialog>
        </div>
    )
}


