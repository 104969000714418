import {RestApiClient} from "@greenbone/cloud-component-library"
import {globalInstanceStorage} from "../../../../GlobalSingle"

function ErrorHandler(e) {
    throw e
}


export class RegistrationRestApiClient {

    constructor(apiClient = new RestApiClient(globalInstanceStorage.getFetchClient(), ErrorHandler)) {
        this.apiClient = apiClient
    }

    async getStatus() {
        return await this.apiClient.get("/vmssp-admin/realm")
    }

    async setStatus(isUserRegistrationEnabled) {
        return await this.apiClient.put("/vmssp-admin/realm", {isUserRegistrationEnabled})
    }


}
