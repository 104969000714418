import React, {useContext} from "react"
import * as ReactDOM from "react-dom"
import {
    AlertBox,
    AlertBoxType,
    Button,
    ButtonSpinner,
    SubNavbar,
    SubNavbarItem
} from "@greenbone/cloud-component-library"
import {Navigate, Route, Routes, useNavigate, useLocation} from "react-router"
import {Imprint} from "./Imprint"
import {LegalRouteUrlProvider} from "./LegalRouteUrlProvider"
import {PrivacyPolicy} from "./PrivacyPolicy"
import {TermsOfUse} from "./TermsOfUse"
import {LegalContext} from "./Context/LegalContext"

class ContentPortal extends React.Component {

    state = {ref: null}

    componentDidMount() {
        const domElement = document.querySelector("#content-wrapper-subnavbar-renderer")
        this.setState({ref: domElement})
    }

    render() {
        const {children} = this.props
        const domElement = this.state.ref

        if (domElement) {
            return ReactDOM.createPortal(
                children,
                domElement
            )
        }
        return null
    }
}

function Navbar(props) {

    const navigate = useNavigate()
    const {pathname} = useLocation()

    return <SubNavbar>
        <SubNavbarItem active={pathname.includes(LegalRouteUrlProvider.TermsOfUse())}
                       onClick={() => navigate(LegalRouteUrlProvider.TermsOfUse())}>
            Terms of Use
        </SubNavbarItem>
        <SubNavbarItem active={pathname.includes(LegalRouteUrlProvider.PrivacyPolicy())}
                       onClick={() => navigate(LegalRouteUrlProvider.PrivacyPolicy())}>
            Privacy Policy
        </SubNavbarItem>
        <SubNavbarItem active={pathname.includes(LegalRouteUrlProvider.Imprint())}
                       onClick={() => navigate(LegalRouteUrlProvider.Imprint())}>
            Imprint
        </SubNavbarItem>
    </SubNavbar>
}

export function LegalRouter() {

    const {save, sending} = useContext(LegalContext)

    return <>
        <ContentPortal>
            <Navbar/>
        </ContentPortal>

        <div style={{marginBottom: "2rem"}}>
            <AlertBox type={AlertBoxType.Warning} title={"Warning"}
                      text={"In case legal texts are only supplied in one language, please copy that language into the other language."}/>
        </div>
        <Routes>
            <Route Component={Imprint} path={LegalRouteUrlProvider.ImprintRoute()}/>
            <Route Component={PrivacyPolicy} path={LegalRouteUrlProvider.PrivacyPolicyRoute()}/>
            <Route Component={TermsOfUse} path={LegalRouteUrlProvider.TermsOfUseRoute()}/>
            <Route element={<Navigate to={LegalRouteUrlProvider.TermsOfUse()}/>}
                   path="/"/>
        </Routes>

        <Button onClick={save}>Save {sending && <ButtonSpinner/>}</Button>
    </>
}
