import React, {useContext, useReducer} from "react"
import {Snackbar} from "@greenbone/cloud-component-library"
import {CustomerRestApiClient} from "../../../../../service/CustomerRestApiClient"
import {Actions} from "./Actions"
import {reducer} from "./Reducer"
import {createSchema, isMax, isMin, isNumber} from "@greenbone/cloud-validation-library"
import {CustomerContext} from "../../CustomerContext"

const initialState = {
    tokenString: "",
    isSaving: false,
    distribution: {intern: 0, extern: 0},
    sellingPrice: null,
    switchImmediate: false,
    token: {
        ipLimit: null,
        runtimeInMonth: null,
        purchasePrice: null,
        isNfr: null,
        status: null
    },
    priceValidation: null,
    tokenValidation: null,
    activeStep: 0
}




export const ProjectKeyContext = React.createContext({data: null})

export const ProjectKeyProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const {update} = useContext(CustomerContext)

    const fetchTokenDetails = () => {
        dispatch(Actions.setSaving(true))
        const apiClient = new CustomerRestApiClient()
        apiClient.getProjectTokenDetails(state.tokenString)
            .then(response => {
                if (response?.status === "CREATED") {
                    dispatch(Actions.setToken(response))
                } else {
                    Snackbar.Error("Key has been used.")
                }
            })
            .catch(error => {
                if (error.status === 404) {
                    dispatch(Actions.setTokenValidation("Invalid Key"))
                    return
                }

                if (error.message) {
                    Snackbar.Error(error.message)
                    return
                }
                Snackbar.Error("An error occurred.")
            })
            .finally(() => {
                dispatch(Actions.setSaving(false))
            })
    }

    const setActiveStep = (step) => {
        dispatch(Actions.setStep(step))
    }

    const setTokenString = token => {
        dispatch(Actions.setTokenString(token))
    }

    const setSellingPrice = priceString => {

        const price = parseFloat(priceString)

        const schema = createSchema({
            price: [isNumber(), isMax(1000000), isMin(state.token.purchasePrice)]
        })

        const validationErrors = schema({price})?.errors?.price


        if (validationErrors?.length > 0) {
            dispatch(Actions.setPriceValidation(validationErrors))
            dispatch(Actions.setSellingPrice(priceString))
        } else {
            dispatch(Actions.setSellingPrice(price))
            dispatch(Actions.setPriceValidation(true))
        }

    }

    const updateDistribution = distribution => {
        dispatch(Actions.setDistribution(distribution))
    }

    const setSwitchImmediate = () => {

        dispatch(Actions.setSwitchImmediate())
    }

    const submitKey = (customerId, onClose) => {
        dispatch(Actions.setSaving(true))
        const apiClient = new CustomerRestApiClient()
        apiClient.submitProjectKey(customerId, {
            "ipCountExternal": state.distribution.extern,
            "ipCountInternal": state.distribution.intern,
            "sellingPrice": state.sellingPrice,
            "switchImmediate": state.switchImmediate,
            "token": state.tokenString
        })
            .then(response => {


                if (response?.status === "BAD_REQUEST") {
                    Snackbar.Error("Please check your inputs.")
                    return
                }

                if (!response || response.product) {
                    dispatch(Actions.setSaving(false))
                    Snackbar.Success("Project Key activated successfully.")
                    update()
                    onClose()
                    return
                }

                if (response?.status === "CONFLICT") {
                    Snackbar.Error(response.message)
                    return
                }

                Snackbar.Error("An error occurred.")

            })
            .catch(e => {

                if (e.status === 404) {
                    Snackbar.Error("Key not valid.")
                    return
                }

                if (e.status === 428) {
                    Snackbar.Error("Customer is not managed by admin")
                    return
                }

                if (e.status === 409) {
                    Snackbar.Error("Downgrade not possible.")
                    return
                }


                if (e?.message) {
                    Snackbar.Error(e.message)
                    return
                }
                Snackbar.Error("An error occurred.")
            })
            .finally(() => {
                dispatch(Actions.setSaving(false))
            })
    }

    return <ProjectKeyContext.Provider value={{
        ...state,
        setToken: fetchTokenDetails,
        setActiveStep,
        setTokenString,
        updateDistribution,
        setSellingPrice,
        submitKey,
        setSwitchImmediate
    }}>
        {children}
    </ProjectKeyContext.Provider>
}
