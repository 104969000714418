import React from "react"
import {RegistrationForm} from "./RegistrationForm"
import {Col, Row} from "reactstrap"


export function RegistrationPage() {
    return <Row>
        <Col xs={6}>
            <RegistrationForm/>
        </Col>
    </Row>
}
