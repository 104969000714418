import React from "react"
import {RegistrationPage} from "./Registration/RegistrationPage"
import {RangesPage} from "./Ranges/RangesPage"


export function SelfServicePage(props) {
    return <>
        <RegistrationPage/>
        <hr/>
        <RangesPage/>

    </>
}
