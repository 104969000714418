var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import styled from "styled-components";
import { resolveOperatingSystem, ResolveOperatingSystemIcon } from "@greenbone/cloud-resolve-os";
import { FlexColumn } from "@greenbone/cloud-component-library";
import { useMemo } from "react";
var Grid = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: .5rem;\n  height: auto;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 1rem 1rem;\n"], ["\n  margin: .5rem;\n  height: auto;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 1rem 1rem;\n"])));
var OSBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid black;\n  border-radius: 5px;\n\n  display: flex;\n  align-items: center;\n\n  svg {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    margin-left: 1rem;\n    height: 4rem;\n    fill: #4C4C4D;\n    width: 100%;\n  }\n\n  .name {\n    text-transform: capitalize;\n    color: #4C4C4D;\n    font-weight: bold;\n    font-size: 1.2rem;\n  }\n\n  .count {\n    text-transform: capitalize;\n    color: #4C4C4D;\n    font-weight: bold;\n    font-size: 2rem;\n  }\n"], ["\n  border: 1px solid black;\n  border-radius: 5px;\n\n  display: flex;\n  align-items: center;\n\n  svg {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    margin-left: 1rem;\n    height: 4rem;\n    fill: #4C4C4D;\n    width: 100%;\n  }\n\n  .name {\n    text-transform: capitalize;\n    color: #4C4C4D;\n    font-weight: bold;\n    font-size: 1.2rem;\n  }\n\n  .count {\n    text-transform: capitalize;\n    color: #4C4C4D;\n    font-weight: bold;\n    font-size: 2rem;\n  }\n"])));
var FlexColumnWithMargin = styled(FlexColumn)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 1rem;\n"], ["\n  margin-right: 1rem;\n"])));
export function countAllResults(list) {
    return list.map(function (os) { return os.count; }).reduce(function (previousValue, currentValue) { return previousValue + currentValue; });
}
export function calculateOperatingSystemSpread(operatingSystemList) {
    var newList = [];
    var allFoundOsCount = countAllResults(operatingSystemList);
    var _loop_1 = function (i) {
        var row = operatingSystemList[i];
        var osConstants = resolveOperatingSystem(row.name);
        var found = newList.find(function (os) { return os.name === osConstants; });
        if (found) {
            found.count += row.count;
        }
        else {
            var newElement = { name: osConstants, count: row.count };
            newList.push(newElement);
        }
    };
    for (var i = 0; i < operatingSystemList.length; i++) {
        _loop_1(i);
    }
    return newList.map(function (osEntry) { return ({ name: osEntry.name, percentage: ((osEntry.count / allFoundOsCount) * 100) }); });
}
export var AffectedOperatingSystem = function (_a) {
    var operatingSystemList = _a.operatingSystemList;
    var osSpread = useMemo(function () { return calculateOperatingSystemSpread(operatingSystemList); }, [operatingSystemList]);
    return _jsx(Grid, { children: osSpread.map(function (os) {
            var _a;
            return _jsxs(OSBox, { children: [_jsx(ResolveOperatingSystemIcon, { operatingSystem: os.name }), _jsxs(FlexColumnWithMargin, { full: true, alignItems: "flex-end", justifyContent: "center", children: [_jsx("div", { className: "name", children: ((_a = os === null || os === void 0 ? void 0 : os.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || "Unknown" }), _jsx("div", { className: "count", children: "".concat(os.percentage.toFixed(), " %") })] })] }, os.name);
        }) });
};
var templateObject_1, templateObject_2, templateObject_3;
