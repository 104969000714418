import React from "react"
import {Col, Container, Row} from "reactstrap"
import {globalInstanceStorage} from "../../../GlobalSingle"
import {ErrorBoundary} from "../../../components/ErrorBoundary"

const Security = ({accountUrl, language}) => (
    <Container fluid={true}>
        <iframe title="security settings"
                src={accountUrl.replace("/account", "/account/#/security/signingin") + `&kc_locale=${language}`}
                style={{width: "100%", height: "880px", borderStyle: "none"}}/>
    </Container>
);

export function SecurityPage(props) {
    const accountUrl = globalInstanceStorage.getKeycloak().createAccountUrl();
    return <>
        <Row>
            <Col>
                <ErrorBoundary>
                    <Security accountUrl={accountUrl} language={"en"}/>
                </ErrorBoundary>
            </Col>
        </Row>

    </>;
}
