export function reducer(state, action) {

    switch (action.type) {
        case "setStep" : {
            return {...state, activeStep: action.payload}
        }

        case "setTokenString" : {
            return {...state, tokenString: action.payload}
        }

        case "setSaving": {
            return {...state, isSaving: action.payload}
        }

        case "setToken": {
            if (action.payload.ipLimit % 2) {
                return {
                    ...state, token: action.payload, isSaving: false, activeStep: state.activeStep + 1,
                    distribution: {
                        intern: Math.trunc(action.payload.ipLimit / 2) + 1,
                        extern: Math.trunc(action.payload.ipLimit / 2)
                    }
                }
            }

            return {
                ...state, token: action.payload, isSaving: false, activeStep: state.activeStep + 1,
                distribution: {intern: action.payload.ipLimit / 2, extern: action.payload.ipLimit / 2}
            }
        }

        case "setAllowNext" : {
            return {...state, allowNext: action.payload}
        }

        case "setDistribution" : {
            return {...state, distribution: action.payload}
        }

        case "setSellingPrice" : {
            return {...state, sellingPrice: action.payload}
        }

        case "setTokenValidation" : {
            return {...state, tokenValidation: action.payload}
        }

        case "setPriceValidation" : {
            return {...state, priceValidation: action.payload}
        }

        case "setSwitchImmediate" : {
            return {...state, switchImmediate: !state.switchImmediate}
        }

        default:
            return state
    }
}
