import React from 'react'
import {SmallVulnerability} from "./SmallVulnerability"

export function SmallVulnerabilityList({vulnerabilities, amount}) {

    if(isNaN(amount)){
        amount = vulnerabilities.length
    }


    return <div>
        {vulnerabilities?.slice(0, amount).map((vulnerability, index) => <SmallVulnerability key={index} {...vulnerability} />)}
    </div>
}


