import React from "react"
import {CustomList} from "./components/CustomerList"
import {CustomerProvider} from "./CustomerContext"
import {ErrorBoundary} from "../../../components/ErrorBoundary"
import {MissingMailConfigurationContextProvider} from "./MissingMailConfigurationsContext"


export function CustomerPage(props) {

    return <>

        <ErrorBoundary>
            <CustomerProvider>
                <MissingMailConfigurationContextProvider>
                    <CustomList/>
                </MissingMailConfigurationContextProvider>
            </CustomerProvider>
        </ErrorBoundary>
    </>
}
