import styled from "styled-components"
import {Checkbox, FlexColumn, FlexRow, Radio, Subheadline, Tiny} from "@greenbone/cloud-component-library"
import React from "react"

export const FadeBox = styled.div`
  transition: height 1s;
`

export const Icon = styled.div`
  height: 1rem;

  & + & {
    margin-left: 0.8125rem;
  }
`


export const GreyBoxTiny = styled(Tiny)`
  color: #707070;
  font-size: 0.875rem;
  margin: 0;
`


export const GreyBoxHead = function ({checkbox, children, title, text, selected, onSelect}) {

    return <FlexRow justifyContent={"space-between"}>
        <FlexRow>
            <FlexColumn justifyContent={"flex-start"}>
                {checkbox ? <Checkbox id={title} checked={selected} onChange={onSelect}/> :
                    <Radio id={title} checked={selected} onChange={onSelect}/>}
            </FlexColumn>
            <FlexColumn justifyContent={"space-between"}>
                <FlexRow>
                    <Subheadline>
                        <FlexRow>
                            {title}
                            {children}
                        </FlexRow>

                    </Subheadline>

                </FlexRow>


                <GreyBoxTiny>
                    {text}
                </GreyBoxTiny>
            </FlexColumn>
        </FlexRow>

    </FlexRow>

}

export const GreyBoxSimpleHead = function ({children, title, text, open}) {

    return <FlexRow justifyContent={"space-between"}>
        <FlexRow>
            <FlexColumn justifyContent={"space-between"}>
                <Subheadline>
                    {title}
                </Subheadline>

                <GreyBoxTiny>
                    {text}
                </GreyBoxTiny>
            </FlexColumn>
        </FlexRow>
        {open && children}
    </FlexRow>

}

