import React, {Component} from "react"
import {Button, FlexRow, Headline, Snackbar, Spinner} from "@greenbone/cloud-component-library"
import "draft-js/dist/Draft.css"
import {ContentState, convertToRaw, EditorState} from "draft-js"
import {Editor} from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import {Col, Row} from "reactstrap"
import draftToHtml from "draftjs-to-html"
import {MailSignatureRestApiClient} from "../../../service/Mailserver/MailSignatureRestApiClient"
import {globalInstanceStorage} from "../../../GlobalSingle"
import htmlToDraft from "html-to-draftjs"
import DOMPurify from "dompurify"
import {ErrorBoundary} from "../../../components/ErrorBoundary"

class ControlledEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editorState: EditorState.createEmpty(),
            sending: false,
            loading: true,
            testing: false
        }
        this.restApiClient = new MailSignatureRestApiClient(globalInstanceStorage.getFetchClient())
    }

    onEditorStateChange = (editorState) => {
        const rawHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        DOMPurify.sanitize(rawHtml, {ADD_ATTR: ["target"]})
        const removedElements = DOMPurify.removed
        if (removedElements.length > 0) {
            Snackbar.Error("Illegal character")
        } else {
            this.setState({
                editorState: editorState
            })
        }
    }

    componentDidMount() {
        this.restApiClient.getAll()
            .then(response => {
                const signature = response.entity.signature
                const blocksFromHtml = htmlToDraft(signature)

                const {contentBlocks, entityMap} = blocksFromHtml
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
                const editorState = EditorState.createWithContent(contentState)
                this.setState({
                    editorState
                })


            })
            .catch(e => {
                console.error(e)
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    getHtml = () => {
        return draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    }

    save = (event) => {
        this.setState({saving: true})
        this.restApiClient.updateEntity({"signature": this.getHtml()})
            .then(response => {
                Snackbar.Success("Signature updated successfully")
            })
            .catch(e => {
                console.error(e)
                Snackbar.Error("Could not save signature")
            }).finally(() => {
            this.setState({saving: false})
        })
    }

    test = (event) => {
        this.setState({testing: true})
        this.restApiClient.createEntity({"signature": this.getHtml()})
            .then(response => {
                Snackbar.Success("A test email has been sent")
            })
            .catch(e => {
                console.error(e)
                Snackbar.Error("Could not test signature")
            }).finally(() => {
            this.setState({testing: false})
        })
    }

    render() {
        const {editorState} = this.state
        if (this.state.loading) {
            return <Spinner/>
        }
        return (<>
                <Headline>Mail Signature</Headline>
                <div style={{minHeight: "20rem", border: "1px solid #c2c2c2", marginBottom: "1rem"}}>
                    <Editor
                        readonly={this.state.sending || this.state.loading}
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                            options: ["remove", "history", "inline", "link", "fontSize", "colorPicker", "textAlign", "emoji"],
                            inline: {
                                inDropdown: false,

                                options: ["bold", "italic", "underline", "strikethrough"]
                            },
                            fontSize: {
                                options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
                            },
                            link: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                popupClassName: undefined,
                                dropdownClassName: undefined,
                                showOpenOptionOnHover: true,
                                defaultTargetOption: "_blank",
                                options: ["link", "unlink"],
                                linkCallback: undefined
                            }
                        }}
                    />
                </div>
                <FlexRow justifyContent={"flex-end"}>
                    <Button onClick={this.test}>Test {this.state.testing && <Spinner/>}</Button>
                    <Button style={{marginLeft: "1rem"}} onClick={this.save}>Save {this.state.saving &&
                    <Spinner/>}</Button>
                </FlexRow>
            </>

        )
    }
}


export function Signature(props) {
    return <Row>
        <Col xs={5}>
            <ErrorBoundary>
                <ControlledEditor/>
            </ErrorBoundary>
        </Col>
    </Row>
}
