import React from 'react'
import {SlideIn} from "@greenbone/cloud-component-library"
import {Table} from "@greenbone/cloud-dynamic-table"
import DescriptionIcon from "@material-ui/icons/Description"
import {IconButton} from "@material-ui/core"
import {useSlideInState} from "../../../../Flux/useSlideInState"
import {FetchData} from "../FetchData"
import {ManagedSecurityRestApiClient} from "../../../../service/ManagedSecurityRestApiClient"
import {toDate} from "../../../../components/Date"
import {ESeverityType, SeverityHeader} from "../SeverityHeader"


const Columns = [
    {
        accessor: row => toDate(row.scanStartDate.toJsDate()),
        Header: "Scan Date"
    },
    {
        accessor: "severity",
        Header: "Severity"
    },
    {
        accessor: "high",
        Header: <SeverityHeader severity={ESeverityType.HIGH}>High</SeverityHeader>
    },
    {
        accessor: "medium",
        Header: <SeverityHeader severity={ESeverityType.MEDIUM}>Medium</SeverityHeader>
    },
    {
        accessor: "low",
        Header: <SeverityHeader severity={ESeverityType.LOW}>Low</SeverityHeader>
    },
    {
        accessor: "log",
        Header: "Log"
    }
]

function ReportList({reportList}) {
    return <Table columns={Columns} data={reportList}/>
}

export function ReportSlideIn({groupId, taskId}) {
    const {isOpen, setOpen, setClosed} = useSlideInState()


    return <div>
        <IconButton onClick={() => setOpen()}>
            <DescriptionIcon/>
        </IconButton>
        <SlideIn open={isOpen} onClose={setClosed}>
            <div style={{minWidth: "50rem"}}>
                <FetchData request={() => new ManagedSecurityRestApiClient().getReportList(groupId, taskId)}>
                    {({data}) => <ReportList reportList={data}/>}
                </FetchData>
            </div>

        </SlideIn>
    </div>

}
