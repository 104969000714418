import React from "react"
import {HostValidationLists} from "./components/HostValidationLists"
import {HistoricalHostValidationLists} from "./components/HistoricHostValidationLists"
import {ErrorBoundary} from "../../components/ErrorBoundary"

export function HostValidationPage(props) {

    return <>

        <ErrorBoundary>
            <HostValidationLists/>
        </ErrorBoundary>


        <br/>
        <br/>
        <ErrorBoundary>
            <HistoricalHostValidationLists/>
        </ErrorBoundary>
    </>

}
