import React from 'react'
import {Table, TableCell, TableRow} from "@greenbone/cloud-dynamic-table"
import {ReportSlideIn} from "./ReportSlideIn"
import {Date} from "../../../../components/Date"
import {ESeverityType, SeverityHeader} from "../SeverityHeader"
import {Trend} from "../Trends"

const columns = [
    {
        accessor: "taskName",
        Header: "Task Name"
    },
    {
        accessor: "trend",
        Header: "Trend"
    },
    {
        accessor: "maxSeverity",
        Header: "Severity"
    },
    {
        accessor: "high",
        Header: <SeverityHeader severity={ESeverityType.HIGH}>High</SeverityHeader>
    },
    {
        accessor: "medium",
        Header: <SeverityHeader severity={ESeverityType.MEDIUM}>Medium</SeverityHeader>
    },
    {
        accessor: "low",
        Header: <SeverityHeader severity={ESeverityType.LOW}>Low</SeverityHeader>
    },
    {
        id: "lastScanDate",
        Header: "Last Scan",
        accessor: row => {
            const lastScanDate = row["lastScanDate"]
            if (lastScanDate) {
                const unixtime = lastScanDate?.toJsDate().getTime()
                return unixtime
            }

            return 0
        }
    },
    {
        id: "reports",
        Header: "Reports"
    }

]

function Entry({dataset}) {
    return <TableRow>
        <TableCell>
            {dataset.taskName}
        </TableCell>
        <TableCell><Trend trend={dataset.trend}/></TableCell>
        <TableCell>{dataset.maxSeverity}</TableCell>
        <TableCell>{dataset.high}</TableCell>
        <TableCell>{dataset.medium}</TableCell>
        <TableCell>{dataset.low}</TableCell>
        <TableCell>
            {dataset?.lastScanDate ? <Date date={dataset.lastScanDate?.toJsDate()}/> : <div></div>}
        </TableCell>
        <TableCell>
            <ReportSlideIn groupId={dataset.groupId} taskId={dataset.taskId}/>
        </TableCell>
    </TableRow>
}

export function TaskList({taskList, groupId}) {
    return <Table
        data={taskList.map(task => ({...task, groupId}))}
        columns={columns}
        entryGenerator={Entry}
    />
}


