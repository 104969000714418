import React, {useContext, useMemo, useState} from "react"
import {
    AlertBox,
    Caption,
    Checkbox,
    FlexRow,
    GhostButton,
    Headline,
    Snackbar,
    Spinner,
    useApiRequest
} from "@greenbone/cloud-component-library"
import {CustomerOverviewRestApiClient} from "../service/CustomerOverviewRestApiClient"
import {CustomerContext, useCustomer} from "../CustomerContext"
import {useTheme} from "styled-components"
import {Col, Container, Row} from "reactstrap"
import {Table, TableCell, TableRow} from "@greenbone/cloud-dynamic-table"
import {SaveButton} from "../../../../SaveButton"
import {ErrorBoundary} from "../../../../components/ErrorBoundary"


function isActiveSubscription(list) {
    for (let i = 0; i < list.length; i++) {
        const row = list[i]

        if (row?.active === "ACTIVE" && row?.cancelledBy === null) {
            return true
        }
    }

    return false
}

const PAYMENT_OPTION = {
    "PROJECT_KEY": "Project Key",
    "CREDIT_CARD": "Credit Card",
    "INVOICE": "Invoice",
    "NONE": "Free Trial"
};

const STATUS_MAP = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    NEXT: "NEXT",
    CANCELED: "CANCELED"
}

const STATUS_TRANSLATION_MAP = {
    [STATUS_MAP.ACTIVE]: "Active",
    [STATUS_MAP.INACTIVE]: "Completed",
    [STATUS_MAP.NEXT]: "Next",
    [STATUS_MAP.CANCELED]: "Ending"
}

export const sortSubscriptionByStatus = (data = []) => {
    const { next, current, inactive } = data?.reduce((accumulatedValues, currentValue) => {
        if (currentValue.active === STATUS_MAP.NEXT) {
            accumulatedValues.next = currentValue
        } else if (currentValue.active === STATUS_MAP.ACTIVE || currentValue.active === STATUS_MAP.CANCELED) {
            accumulatedValues.current = currentValue
        } else {
            accumulatedValues.inactive.push(currentValue)
        }
        return accumulatedValues
    }, {
        next: null,
        current: null,
        inactive: []
    }) || {
        next: null,
            current: null,
            inactive: []
    }


    return [
        current?.cancelledBy !== null ? null : next,
        current,
        ...inactive?.sort(function(a,b){
            return new Date(b.endedAt) - new Date(a.endedAt)
        })
    ].filter(x => x)
}

export const activeAccessor = (row) => {
    if (row.active === STATUS_MAP.ACTIVE) {
        return STATUS_TRANSLATION_MAP[STATUS_MAP.ACTIVE]
    }
    if (row.active === STATUS_MAP.NEXT) {
        return row.articleNumber !== null ?
            STATUS_TRANSLATION_MAP[STATUS_MAP.NEXT]:
            STATUS_TRANSLATION_MAP[STATUS_MAP.CANCELED]
    }
    return STATUS_TRANSLATION_MAP[STATUS_MAP.INACTIVE]
}

export function CustomerSubscriptionHistory({id}) {
    const apiClient = new CustomerOverviewRestApiClient()
    const [data, loading, , setData] = useApiRequest(() => apiClient.getHistoricSubscriptions(id))
    const initialData = useMemo(() => sortSubscriptionByStatus(data), [data])
    const [saving, setSaving] = useState(false)

    const {base} = useTheme()
    const {cancelActiveSubscription} = useContext(CustomerContext)

    if (loading || !data) {
        return <Spinner/>
    }


    const cancelActive = e => {
        e.preventDefault()
        setSaving(true)
        cancelActiveSubscription(id)
            .then(response => {
                if (!response) {
                    apiClient.getHistoricSubscriptions(id)
                        .then(response => {
                            setData(response)
                        })
                        .catch(e => {
                            Snackbar.Error("Could not update Subscription history. Please try again later.")
                        })
                }
            })
            .catch(e => {
                if (e?.message) {
                    Snackbar.Error(e.message)
                    return
                }
                Snackbar.Error("There are no active subscriptions to be cancelled.")
            })
            .finally(() => setSaving(false))
    }



    const columns = [
        {
            id: "active",
            accessor: activeAccessor,
            Header: "Status"
        },
        {
            id: "startedAt",
            accessor: row => row.startedAt ? new Date(row.startedAt).toDateString() : undefined,
            Header: "Start"
        },
        {
            id: "endedAt",
            accessor: row => row.endedAt ? new Date(row.endedAt).toDateString() : undefined,
            Header: "End"
        },
        {
            id: "ipsInternal",
            Header: "Internal IPs",
            accessor: dataset => dataset.ipsInternal
        },
        {
            id: "ipsExternal",
            Header: "External IPs",
            accessor: dataset => dataset.ipsExternal
        },
        {
            id: "total",
            accessor: dataset => dataset.ipsExternal + dataset.ipsInternal,
            Header: "Total IPs"
        },
        {
            id: "paymentOption",
            accessor: dataset => dataset.paymentOption,
            Header: "Payment method"
        },
        {
            id: "cancelationSetBy",
            accessor: dataset => dataset.cancelledBy,
            Header: "Cancelation set by"
        }
    ]

    const StatusComponent = ({ active, articleNumber, cancelledBy}) => {
        let refactoredStatus = active

        if (refactoredStatus === STATUS_MAP.NEXT && articleNumber === null) {
            refactoredStatus = STATUS_MAP.CANCELED
        }
        if (refactoredStatus === STATUS_MAP.ACTIVE && cancelledBy !== null) {
            refactoredStatus = STATUS_MAP.CANCELED
        }
        return <span
            style={refactoredStatus === STATUS_MAP.CANCELED ? {color: 'orange'} :
                   active === STATUS_MAP.ACTIVE ? {color: base} : {}}
        >
            {STATUS_TRANSLATION_MAP[refactoredStatus] || STATUS_TRANSLATION_MAP[STATUS_MAP.INACTIVE]}
        </span>
    }

    const Generator = ({row, dataset}) => {
        return <TableRow>
            <TableCell>
                <StatusComponent {...dataset}/>
            </TableCell>
            <TableCell>
                {dataset.startedAt ? new Date(dataset.startedAt).toDateString() : undefined}
            </TableCell>
            <TableCell>
                {dataset.endedAt ? new Date(dataset.endedAt)?.toDateString() : undefined}
            </TableCell>
            <TableCell>
                {dataset.ipsInternal}
            </TableCell>
            <TableCell>
                {dataset.ipsExternal}
            </TableCell>
            <TableCell>
                {dataset.ipsInternal + dataset.ipsExternal}
            </TableCell>
            <TableCell>
                {PAYMENT_OPTION[dataset.paymentOption]}
            </TableCell>
            <TableCell>
                {dataset.cancelledBy}
            </TableCell>
        </TableRow>
    }

    const actions = <div>
        <Caption noMargin style={{marginRight: "1rem"}}>Subscriptions</Caption>
        <SaveButton disabled={!isActiveSubscription(data)} onClick={cancelActive} saving={saving}>Cancel
            Active Subscription</SaveButton></div>

    return <div>
        <Table headline={"Subscriptions"}
               data={initialData}
               columns={columns}
               entryGenerator={Generator}
               searchableColumns={columns.map(col => col.id)}
               actions={actions || null}
        >
        </Table>
    </div>
}


export function CustomerDetails({id, onClose}) {


    const customer = useCustomer(id)


    const [status, setStatus] = useState(customer.status)
    const [sending, setSending] = useState(false)


    const {setCustomerStatus} = useContext(CustomerContext)

    const handleSubmit = e => {
        e.preventDefault()

        setSending(true)

        setCustomerStatus(id, status)
            .then(response => {
                if (response) {

                }
                setSending(false)
                onClose()
            })
            .catch(e => {
                Snackbar.Error(e)
                setSending(false)
            })

    }


    return <Container style={{width: "830px"}}>
        <Row style={{marginBottom: "2rem"}}>
            <Col>
                <Headline>Customer Detail</Headline>
                <Caption>
                    {customer.companyName}
                </Caption>
            </Col>
        </Row>
        <Row style={{marginBottom: "2rem"}}>
            <Col>
                <Caption>Details</Caption>
                <Row>
                    <Col>
                        <Checkbox checked={status === "LOCKED"}
                                  onChange={() => setStatus(prevState => prevState === "LOCKED" ? "ACTIVE" : "LOCKED")}
                                  label={"Account locked"}/>
                        <AlertBox type={"warning"}
                                  text={"If you lock an account, the company will not be able to sign in. The subscription will not be canceled."}/>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col>
                <FlexRow style={{flexDirection: "row-reverse"}}>
                    <SaveButton onClick={handleSubmit} saving={sending}>
                        Save
                    </SaveButton>
                    <GhostButton onClick={() => onClose()} style={{marginRight: "1rem"}}>
                        Abort
                    </GhostButton>
                </FlexRow>
            </Col>
        </Row>
        <Row style={{marginBottom: "2rem"}}>
            <Col>
                <ErrorBoundary>
                    <CustomerSubscriptionHistory id={id}/>
                </ErrorBoundary>
            </Col>
        </Row>

    </Container>
}

