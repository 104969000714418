import React, {useEffect, useState} from "react"
import {SlideIn, Snackbar, Spinner} from "@greenbone/cloud-component-library"
import {Table, TableCell, TableRow} from "@greenbone/cloud-dynamic-table"
import {PaymentMethodRestApiClient} from "../../../service/PaymentMethodRestApiClient"
import {Edit} from "@material-ui/icons"
import IconButton from "@material-ui/core/IconButton"
import {Col, Row} from "reactstrap"
import {StripeForm} from "./components/StripeForm"
import {PaymentTypes} from "./components/PaymentTypes"
import {InvoiceForm} from "./components/InvoiceForm"
import CheckIcon from "../../Configuration/Plans/assets/check.svg?url"
import RemoveIcon from "../../Configuration/Plans/assets/remove.svg?url"
import {JWT} from "../../../welcomeScreen/JWT"
import {ProjectKeyForm} from "./components/ProjectKeyForm"


function ObjectToArray(object) {
    const arr = []
    Object.keys(object).forEach(key => {
        arr.push({...object[key], type: key})
    })

    return arr
}


export function SelectPaymentMethod() {

    const [options, setOptions] = useState()
    const [loading, setLoading] = useState(true)

    const [slideInOpen, setSlideInOpen] = useState(false)
    const [editMethod, setEditMethod] = useState(null)

    useEffect(() => {
        if (editMethod !== null) {
            setSlideInOpen(true)
        }
    }, [editMethod])

    useEffect(() => {
        if (slideInOpen === false) {
            setEditMethod(null)
        }
    }, [slideInOpen])

    useEffect(() => {
        const apiClient = new PaymentMethodRestApiClient()
        apiClient.getAll()
            .then(response => {
                setOptions({
                    ...response,
                    [PaymentTypes.PROJECT_KEY]: {
                        ...response[PaymentTypes.PROJECT_KEY],
                        active: !!response[PaymentTypes.PROJECT_KEY]?.active
                    },
                    [PaymentTypes.CREDIT_CARD]: {
                        ...response[PaymentTypes.CREDIT_CARD],
                        active: !!response[PaymentTypes.CREDIT_CARD]?.active
                    },
                    [PaymentTypes.INVOICE]: {
                        ...response[PaymentTypes.INVOICE],
                        active: !!response[PaymentTypes.INVOICE]?.active
                    }
                })
                setLoading(false)
            })
            .catch(e => {
                Snackbar.Error(e.toString())
            })
    }, [])

    const columns = [
        {
            accessor: "type",
            Header: "Billing Type"
        },
        {
            accessor: "name",
            Header: "Status"
        },
        {
            accessor: "actions",
            Header: "Actions"
        }
    ]

    const Generator = ({row, dataset}) => (
        <TableRow>

            <TableCell>
                {dataset.type === PaymentTypes.NONE ? "FREE" : dataset.type.toString()}
            </TableCell>
            <TableCell>
                {dataset.active ? <img style={{height: "1rem"}} src={CheckIcon} alt={"is Active"}/> :
                    <img style={{height: "1rem"}} src={RemoveIcon} alt={"is Inactive"}/>}
            </TableCell>
            <TableCell>
                {dataset.type !== PaymentTypes.NONE &&
                <IconButton onClick={() => setEditMethod(dataset.type)}>
                    <Edit/>
                </IconButton>}
            </TableCell>
        </TableRow>
    )

    if (loading) {
        return <Spinner/>
    }


    return <>

        <Row>
            <Col>
                <Table defaultSortBy={["type"]}
                       searchableColumns={[...columns.map(col => col.accessor)]}
                       data={ObjectToArray(options)}
                       columns={columns}
                       entryGenerator={Generator}
                       actions={<div></div>}
                       cacheKey={`${new JWT().subject()}-selectPaymentMethod/2020.11.1`}
                />
            </Col>
        </Row>
        <SlideIn open={slideInOpen} onClose={() => setSlideInOpen(false)}>
            {editMethod === PaymentTypes.CREDIT_CARD &&
            <StripeForm memcopy={{...options}} close={(memcopy) => {
                setOptions({...memcopy})
                setSlideInOpen(false)
            }}/>}
            {editMethod === PaymentTypes.INVOICE &&
            <InvoiceForm memcopy={{...options}} close={(memcopy) => {
                setOptions({...memcopy})
                setSlideInOpen(false)
            }}/>}
            {editMethod === PaymentTypes.PROJECT_KEY &&
            <ProjectKeyForm memcopy={{...options}} close={(memcopy) => {
                setOptions({...memcopy})
                setSlideInOpen(false)
            }}/>}
        </SlideIn>
    </>
}
