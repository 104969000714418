import React, {useMemo, useState} from "react"
import {DownloadSelect, SIODateTime, Snackbar} from "@greenbone/cloud-component-library"
import {saveAs} from "file-saver"
import { SalesDashboardRestApiClient } from "../service/SalesDashboardRestApiClient"


function createDateList() {
    const array = []
    let startTime = SIODateTime.local(2021, 1)
    const endTime = SIODateTime.local().minus({months: 1})

    while (startTime.isBefore(endTime)) {
        array.push(startTime)
        startTime = startTime.plus({months: 1})
    }

    return array.reverse()

}

function displayDate(date) {
    return new Intl.DateTimeFormat("en-US", {month: "long", year: "numeric"}).format(date)
}

function getMonthInEnglishUppercase(date) {
    return new Intl.DateTimeFormat("en-US", {month: "long"}).format(date).toUpperCase()
}

function downloadFile(content, fileName = new Date().toISOString()) {
    if (!content) return console.error("content was not specified", content)

    let toDownload = content;
    
    if (!(content instanceof Blob)) {
        toDownload = new Blob([content])
    }

    saveAs(toDownload, fileName)
}

export function CSVDownload() {

    const dateList = useMemo(() => createDateList(), [])
    const [isLoading, setLoading] = useState(false)

    const handleDownload = (date) => {

        const year = parseInt(date.toFormat("y"))
        const month = getMonthInEnglishUppercase(date.toJsDate())

        const body = {
            month,
            year
        }
        
        setLoading(true)
        const apiClient = new SalesDashboardRestApiClient()
        apiClient.downloadCSV(body).then(response => {
            response.blob().then(blob => {
                downloadFile(blob, `${year}-${month}.csv`)
            })
        })
            .catch(e => {
                console.log(e)
                Snackbar.Error(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return <div>
        <DownloadSelect loading={isLoading} value={dateList[0].valueOf()} options={dateList.map(date => ({
            label: displayDate(date.toJsDate()),
            value: date.valueOf(),
            action: () => handleDownload(date)
        }))}/>
    </div>
}
