import React, {useEffect} from "react"
import styled, {css} from "styled-components"
import {Bold} from "@greenbone/cloud-component-library"

import center from "../assets/center.svg?url"
import fill from "../assets/fill.svg?url"
import fit from "../assets/fit.svg?url"
import checked from "../assets/check.svg?url"

const PreviewImage = styled.div`
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  border-radius: 3px;
  height: 73px;
  width: 106px;
  border: 2px solid transparent;
  padding: 5px;
  .main {
    height: 100%;
    width: 100%;
  }
  
  .active {
    display: none;
  }
  
  
  ${({active}) => active && css`
    border-color: #7EC14B;
    
    .active {
        border-top-left-radius: 3px;
        right: 0;
        bottom: 0;
        background: #7EC14B;
        height: 25px;
        width: 25px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 80%;
          height: 80%;
        }
    }
  `}
`

const Option = ({src, active, onClick, text}) => (
    <>
        <PreviewImage active={active} onClick={onClick}>
            <div className="active">
                <img src={checked} alt="Checked"/>
            </div>
            <img className={"main"} src={src} alt="Layout Preview"/>
        </PreviewImage>
        <Bold style={{marginLeft: "1.2rem"}}>{text}</Bold>
    </>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
`


export function BigImageOrientation({onChange, layout, ...other}) {

    useEffect(() => {
        if (layout === null) {
            onChange("loginImageOrientation", "FILL")
        }
    }, [layout, onChange])


    return <Container {...other}>
        <div>
            <Option onClick={() => onChange("loginImageOrientation", "CENTER")}
                    src={center}
                    active={layout === "CENTER"}
                    text={"Center"}/>
        </div>
        <div>
            <Option onClick={() => onChange("loginImageOrientation", "FILL")}
                    src={fill}
                    active={layout === "FILL"}
                    text={"Fill"}/>
        </div>
        <div>
            <Option onClick={() => onChange("loginImageOrientation", "FIT")}
                    src={fit}
                    active={layout === "FIT"}
                    text={"Fit"}/>
        </div>
    </Container>
}
