import React from "react"
import {MailForm} from "./MailForm"
import {Signature} from "./Signature"
import {ErrorBoundary} from "../../../components/ErrorBoundary"
import FirewallWarning from "./FirewallWarning";

export function MailPage(props) {
    return <ErrorBoundary>
        <MailForm/>
        <div className="row">
            <div className="col-xs-12 col-sm-5 col-5" style={{marginBottom: '2rem'}}>
                <FirewallWarning/>
            </div>
        </div>
        <Signature/>
    </ErrorBoundary>
}
