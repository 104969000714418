import React, {useEffect, useState} from "react"
import {isNumberPrimitive} from "@greenbone/cloud-validation-library"
import {Caption, Subheadline} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import styled from "styled-components"
import {RangeSlider} from "./RangeSlider"


const GreyField = styled.input`

  color: #23241f;
  background: #bbbbbb;
  border-radius: 3px;
  border: none;
  padding: 0.2rem;
  text-align: center;
  -moz-appearance: textfield;
  max-width: 3rem;

  &:focus {
    outline: none;
  }
`

export function IPDistribution({internalIpCount, externalIpCount, updateDistribution, min, max}) {

    const [distribution, setDistribution] = useState({ipsInternal: internalIpCount, ipsExternal: externalIpCount})

    useEffect(() => {
        updateDistribution(distribution)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distribution])

    const handleInternChange = e => {
        const dirtyValue = e.target.value
        if (!isNumberPrimitive(dirtyValue)) {
            return
        }

        let value = parseInt(dirtyValue)

        setDistribution({...distribution, ipsInternal: value})
    }

    const handleExternChange = e => {
        const dirtyValue = e.target.value
        if (!isNumberPrimitive(dirtyValue)) {
            return
        }
        let value = parseInt(dirtyValue)

        setDistribution({...distribution, ipsExternal: value})
    }

    const onChange = e => {
        const value = parseInt(e.target.value)

        const ipAmount = distribution.ipsExternal + distribution.ipsInternal

        setDistribution({...distribution, ipsInternal: value, ipsExternal: (ipAmount - value)})
    }

    const minLabel = ({value}) => {
        return <><GreyField disabled type={"number"} value={value} onChange={handleInternChange}/> Intern</>
    }
    const maxLabel = ({value, max}) => {
        return <>Extern <GreyField disabled type={"number"} value={max - value} onChange={handleExternChange}/></>
    }

    if (!distribution) {
        return "Not Found"
    }
    const ipAmount = distribution?.ipsInternal + distribution?.ipsExternal


    return <>
        <Row>
            <Col>
                <Subheadline>Distribution</Subheadline>
                <Caption>Total IP's {ipAmount}</Caption>
                <RangeSlider step={1} maxLabel={maxLabel} minLabel={minLabel} min={0} max={ipAmount}
                             value={distribution.ipsInternal} onChange={onChange}/>
            </Col>
        </Row>
    </>
}
