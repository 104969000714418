import MaterialUIPaper from "@material-ui/core/Paper"
import React from "react"
import {compose} from "redux"
import styled from "styled-components"
import {Cookie} from "./Cookie"
import {IS_LOCAL, WELCOME_SCREEN_COOKIE} from "../../Constants"
import {WelcomeScreenContent} from "./WelcomeScreenContent"
import {JWT} from "./JWT"
import {Button, Checkbox, FlexRow} from "@greenbone/cloud-component-library"


const Paper = styled(MaterialUIPaper)`
  padding: 2.50rem;
  padding-bottom: 1.25rem;
  width: 46.88rem;
`
const StyledBox = styled(Checkbox)`

  span {
    font-family: "DroidSans";
  }
`

const WelcomeScreenClickGuard = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  z-index: 1201;
  display: flex;
  justify-content: center;
  align-items: center;
`

class _WelcomeScreen extends React.Component {

    state = {
        showPopup: false,
        showAgain: true
    }

    updateWizardVisibility = () => {
        if (IS_LOCAL) {
            return
        }

        let cookie = Cookie.get(WELCOME_SCREEN_COOKIE)


        if (!cookie) {
            cookie = Cookie._dangerousGet("WELCOME_WIZARD_ACCEPTED_HIDDEN_VMSP")
        }

        const jwt = new JWT()

        if (jwt.subject().trim()) {
            if (cookie === null) {
                this.setState({showPopup: true})
            }
        }


    }

    componentDidMount() {
        this.updateWizardVisibility()
    }

    handleChkboxChange = event => {
        this.setState(prevState => {
            return {showAgain: !prevState.showAgain}
        })
    }

    handleBoxClose = event => {
        event.preventDefault()

        if (!this.state.showAgain) {
            Cookie.set(WELCOME_SCREEN_COOKIE, true.toString(), 300)
        }

        this.setState({
            showPopup: false
        })
    }

    render() {
        if (this.state.showPopup === false || this.props.open === false) {
            return null
        }

        return (
            <WelcomeScreenClickGuard>
                <Paper>
                    <WelcomeScreenContent/>
                    <FlexRow justifyContent={"space-between"}>
                        <StyledBox style={{fontFamily: "DroidSans !important"}} onChange={this.handleChkboxChange}
                                   checked={!this.state.showAgain}
                                   label={"Do not show again"}/>
                        <Button onClick={this.handleBoxClose}>Lets go!</Button>
                    </FlexRow>
                </Paper>
            </WelcomeScreenClickGuard>
        )
    }
}

export const WelcomeScreen = compose()(_WelcomeScreen)
