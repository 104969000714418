import React from "react"
import {DashboardCellBanner, Spinner, useRequest} from "@greenbone/cloud-component-library"
import {Line} from "@greenbone/cloud-chart-library"
import {SalesDashboardRestApiClient} from "../service/SalesDashboardRestApiClient"


export function PlanDevelopment(props) {

    const apiClient = new SalesDashboardRestApiClient()
    const [data, loading] = useRequest(apiClient.getPlanDevelopment.bind(apiClient))



    if (loading)
        return <Spinner/>

    return <>
        <DashboardCellBanner>
            Development within 6 months
        </DashboardCellBanner>
        <div style={{padding: "2rem"}}>
            <Line style={{width: "100%", height: "100%"}}
                  width={986}
                  height={381}
                  data={data}
                  showLegend={true}
                  dateAccessor={d => d.date}
                  dataAccessors={[d => d.amountIntern, d => d.amountExtern]}
                  nameAccessors={[d => "Internal IP's", d => "External IP's"]}
                  colorScheme={["#006F25", "#55C14A"]}
            />
        </div>

    </>
}
