import React from "react"
import styled, {css} from "styled-components"

import center from "../assets/center_layout.svg?url"
import splitLeft from "../assets/split_left.svg?url"
import splitRight from "../assets/split_right.svg?url"
import checked from "../assets/check.svg?url"

const PreviewImage = styled.div`
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  border-radius: 3px;
  width: 266px;
  border: 2px solid transparent;
  .main {
    height: 100%;
    width: 100%;
  }
  
  .active {
    display: none;
  }
  
  
  ${({active}) => active && css`
    border-color: #7EC14B;
    
    .active {
        border-top-left-radius: 3px;
        right: 0;
        bottom: 0;
        background: #7EC14B;
        height: 25px;
        width: 25px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 80%;
          height: 80%;
        }
    }
  `}
`

const Layout = ({src, active, onClick}) => (
    <PreviewImage onClick={onClick} active={active}>
        <div className="active">
            <img src={checked} alt="Checked"/>
        </div>
        <img className={"main"} src={src} alt="Layout Preview"/>
    </PreviewImage>
)

const Container = styled.div`
max-width: 70%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
`

// "SPLIT_LEFT" | "SPLIT_RIGHT" | "WINDOW_CENTER"
export function LayoutSelector({onChange, layout, ...other}) {
    return <Container {...other}>
        <div>
            <Layout onClick={() => onChange("SPLIT_LEFT")} src={splitLeft} active={layout === "SPLIT_LEFT"}/>
        </div>
        <div>
            <Layout onClick={() => onChange("SPLIT_RIGHT")} src={splitRight} active={layout === "SPLIT_RIGHT"}/>
        </div>
        <div>
            <Layout onClick={() => onChange("WINDOW_CENTER")} src={center} active={layout === "WINDOW_CENTER"}/>
        </div>
    </Container>
}
