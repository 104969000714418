import React from "react"
import {Headline} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {SelectPaymentMethod} from "./SelectPaymentMethod"
import {ErrorBoundary} from "../../../components/ErrorBoundary"

export function PaymentMethodPage(props) {


    return <ErrorBoundary>
        <Row>
            <Col>
                <Headline>Payment Methods</Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <SelectPaymentMethod/>
            </Col>
        </Row>
    </ErrorBoundary>;


}
