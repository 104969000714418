import React from 'react'
import {Spinner, useRequest} from "@greenbone/cloud-component-library"
import {ManagedSecurityRestApiClient} from "../../../../service/ManagedSecurityRestApiClient"
import {CustomerOverviewRestApiClient} from "../../../Billing/Customers/service/CustomerOverviewRestApiClient"
import {ESeverityType, SeverityHeader} from "../SeverityHeader"
import {useEffect, useReducer} from "react"
import {CompanyListRow} from "./CompanyListRow"
import {JWT} from "../../../../welcomeScreen/JWT"
import {Table} from "@greenbone/cloud-dynamic-table"

const columns = [
    {
        id: "company",
        Header: "Company",
        accessor: row => row.customer?.companyName
    },
    {
        accessor: "taskCount",
        Header: "Task Count"
    },
    {
        accessor: "high",
        Header: <SeverityHeader severity={ESeverityType.HIGH}>High</SeverityHeader>
    },
    {
        accessor: "medium",
        Header: <SeverityHeader severity={ESeverityType.MEDIUM}>Medium</SeverityHeader>
    },
    {
        accessor: "low",
        Header: <SeverityHeader severity={ESeverityType.LOW}>Low</SeverityHeader>
    },
    {
        accessor: "trend",
        Header: "Trend"
    },
    {
        Header: "Actions",
        id: "actions"
    }
]

const ACTIONS = {
    DATALOADED: "DATALOADED",
    SETDATA: "SETDATA",
    SETERROR: "SETERROR",
    SETLOADING: "SETLOADING"
}

function reducer(state, {action, data}) {
    switch (action) {
        case ACTIONS.SETLOADING:
            return {...state, loading: true}
        case ACTIONS.DATALOADED:
            return {...state, loading: false, data}
        case ACTIONS.SETDATA:
            return {...state, data}
        case ACTIONS.SETERROR:
            return {...state, loading: false, error: data}
        default:
            return state
    }
}


export function useConditionedRequest(request, condition, deps) {
    const [state, dispatch] = useReducer(reducer, {loading: true, data: null, error: null})
    useEffect(() => {
        if (condition(deps)) {
            dispatch({action: ACTIONS.SETLOADING})
            request(deps)
                .then(response => {
                    dispatch({action: ACTIONS.DATALOADED, data: response})
                })
                .catch(e => {
                    dispatch({action: ACTIONS.SETERROR, data: e})
                })
        }
        // eslint-disable-next-line
    }, deps)

    function setData(data) {
        if (typeof data === "function") {
            dispatch({action: ACTIONS.SETDATA, data: data(state.data)})
        } else {
            dispatch({action: ACTIONS.SETDATA, data})
        }

    }

    return [state.data, state.loading, state.error, setData]
}

export function CompanyList() {
    const managedSecurityRestApiClient = new ManagedSecurityRestApiClient()

    const [managedReportingEnabledCustomers, loadingManagedReportingEnabledCustomers, errorManagedReportingEnabledCustomers] = useRequest(managedSecurityRestApiClient.getCompanyList.bind(managedSecurityRestApiClient))

    const [customers, , errorCustomers] = useConditionedRequest(([data, loading, error]) => new CustomerOverviewRestApiClient().getCustomerUserInformation({
            groupIds: data.map(company => company.groupId)
        }),
        () => {
            if (managedReportingEnabledCustomers && loadingManagedReportingEnabledCustomers === false && !errorManagedReportingEnabledCustomers) {
                return true
            }
            return false
        },
        [managedReportingEnabledCustomers, loadingManagedReportingEnabledCustomers, errorManagedReportingEnabledCustomers])


    if (loadingManagedReportingEnabledCustomers) {
        return <Spinner/>
    }

    if (errorManagedReportingEnabledCustomers) {
        throw errorManagedReportingEnabledCustomers
    }

    if (errorCustomers) {
        throw errorCustomers
    }

    let enrichedData = []

    if (managedReportingEnabledCustomers && customers) {
        const customerMap = new Map(customers.map(customer => [customer.groupId, customer]));
        enrichedData = managedReportingEnabledCustomers.map(customerSecurityStatus => {
            const customerBillingInformation = customerMap.get(customerSecurityStatus.groupId);
            if (customerBillingInformation) {
                return { ...customerSecurityStatus, customer: customerBillingInformation }
            } else if (loadingManagedReportingEnabledCustomers) {
                return { ...customerSecurityStatus, customer: { companyName: <Spinner key={customerSecurityStatus.groupId} /> } }
            }
            return null;
        }).filter(Boolean); // removes null entries
    }


    if (managedReportingEnabledCustomers) {
        return <Table
            actions={<div></div>}
            defaultSortBy={["company"]}
            data={enrichedData}
            searchableColumns={["company"]}
            columns={columns}
            cacheKey={`${new JWT().subject()}-managedSecurityList/2021.06.01`}
            entryGenerator={CompanyListRow}

        />
    }


    return <div>No data available.</div>
}
