import React, {useContext, useEffect, useState} from "react"
import {AbortButton, FlexRow, Snackbar, Subheadline} from "@greenbone/cloud-component-library"
import {BillingAddress} from "./BillingAddress"
import {CustomerRestApiClient} from "../../../../service/CustomerRestApiClient"
import {Col, Container, Row} from "reactstrap"
import {ContactPerson} from "./ContactPerson"
import {SaveButton} from "../../../../SaveButton"
import {CustomerContext} from "../CustomerContext"

export function CustomerForm({onClose}) {
    const [companyAddress, setCompanyAddress] = useState(null)
    const [, setLoading] = useState(true)
    const [countries, setCountries] = useState([])
    const [companyAddressErrors, setCompanyAddressErrors] = useState(null)
    const [contactPerson, setContactPerson] = useState(null)
    const [contactErrors, setContactErrors] = useState(null)
    const [saving, setSaving] = useState(false)

    const {create} = useContext(CustomerContext)

    useEffect(() => {
        async function f() {
            const apiClient = new CustomerRestApiClient()
            const countryList = await apiClient.getCountryCodes()
            setLoading(false)
            setCountries(countryList)
        }

        f()
    }, [])

    const handleSubmit = event => {
        event.preventDefault()

        setSaving(true)
        create({...companyAddress, ...contactPerson})
            .then(response => {
                if (response?.status === "BAD_REQUEST" || response?.status === "CONFLICT") {
                    setContactErrors(response.fieldErrors)
                    setCompanyAddressErrors(response.fieldErrors)
                    setSaving(false)
                    return
                }
                setSaving(false)
                onClose()
            })
            .catch(e => Snackbar.Error(e))
    }

    return <Container style={{minWidth: "40rem"}}>
        <Row>
            <Col>
                <Subheadline>
                    Add new Customer
                </Subheadline>
            </Col>
        </Row>
        <Row style={{marginBottom: "2rem"}}>
            <Col>
                <BillingAddress countries={countries}
                                errors={companyAddressErrors} address={companyAddress}
                                onChange={address => setCompanyAddress(address)}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <Subheadline>
                    Contact Person
                </Subheadline>
            </Col>
        </Row>
        <Row>
            <Col>
                <ContactPerson errors={contactErrors} person={contactPerson} setPerson={setContactPerson}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <FlexRow style={{flexDirection: "row-reverse"}} justifyContent={"space-between"}>
                    <SaveButton saving={saving} onClick={handleSubmit}>Create</SaveButton>
                    <AbortButton style={{marginLeft: 0}} onClick={() => onClose()}>Abort</AbortButton>
                </FlexRow>
            </Col>
        </Row>


    </Container>
}
