import React from "react"
import {Col, Container, Row} from "reactstrap"
import {Warning} from "@material-ui/icons"
import {
    AlertBox,
    AlertBoxType,
    Button,
    Checkbox,
    FlexColumn,
    FlexRow,
    Headline,
    Input,
    Snackbar,
    Spinner,
    Subheadline,
    Text
} from "@greenbone/cloud-component-library"
import {MailServerRestApiClient} from "../../../service/Mailserver/MailServerRestApiClient"
import {globalInstanceStorage} from "../../../GlobalSingle"

const FieldErrorsInitialState = {
    domain: null,
    port: null,
    senderEMailAddress: null,
    supportEMailAddress: null,
    username: null,
    password: null

}

export class MailForm extends React.Component {

    state = {
        fields: {
            domain: "",
            port: "",
            senderEMailAddress: "",
            supportEMailAddress: "",
            username: "",
            password: ""
        },
        fieldErrors: {
            domain: null,
            port: null,
            senderEMailAddress: null,
            supportEMailAddress: null,
            username: null,
            password: null
        },
        warning: null,
        isAuthenticationRequired: false,
        _loading: true,
        _sending: false,
        _testing: false,
        _error: null
    }

    constructor(props) {
        super(props)

        this.apiClient = new MailServerRestApiClient(globalInstanceStorage.getFetchClient())
    }


    componentDidMount() {
        this.setState({_loading: true})
        this.apiClient.getAll()
            .then(response => {


                if (response.status === 200) {
                    this.setState({
                        fields: {...this.state.fields, ...response.entity},
                        warning: response.entity.warning
                    })


                    if (response.entity.isAuthenticationRequired) {
                        this.setState({
                            isAuthenticationRequired: response.entity.isAuthenticationRequired
                        })
                    }
                }

                this.setState({_loading: false})

            })
            .catch(e => {

                this.setState({_loading: false})
            })
    }

    handleChange = event => {
        const {name, value} = event.target

        this.setState(prevState => {
            return {
                fields: {
                    ...prevState.fields,
                    [name]: value
                }
            }
        })

    }

    toggleAuthentication = (event) => {
        this.setState(prevState => ({
            isAuthenticationRequired: !prevState.isAuthenticationRequired
        }))
    }

    getSubmitDTO = () => {
        let mailConfig = {
            domain: this.state.fields.domain,
            port: this.state.fields.port,
            senderEMailAddress: this.state.fields.senderEMailAddress,
            supportEMailAddress: this.state.fields.supportEMailAddress,
            isAuthenticationRequired: this.state.isAuthenticationRequired
        }

        if (this.state.isAuthenticationRequired) {
            mailConfig = {
                ...mailConfig,
                username: this.state.fields.username,
                password: this.state.fields.password || undefined
            }
        }

        return mailConfig
    }

    handleTestSubmit = (event) => {
        event.preventDefault()
        const mailConfig = this.getSubmitDTO()
        this.setState({_testing: true, warning: null, fieldErrors: FieldErrorsInitialState})

        this.apiClient.createEntity(mailConfig)
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    Snackbar.Success("Test E-Mail has been sent.")
                    this.setState({
                        fieldErrors: {
                            domain: null,
                            port: null,
                            senderEMailAddress: null,
                            supportEMailAddress: null,
                            username: null,
                            password: null
                        }
                    })

                } else if (response.status === 400) {
                    if (response?.errors?.fieldErrors) {
                        this.setState({fieldErrors: response?.errors?.fieldErrors})
                        if (response?.errors?.fieldErrors?.isAuthenticationRequired) {
                            this.setState(prevState => ({
                                fieldErrors: {
                                    ...prevState.fieldErrors,
                                    username: prevState.fields.username ? null : response?.errors?.fieldErrors?.isAuthenticationRequired.join(" "),
                                    password: prevState.fields.password ? null : response?.errors?.fieldErrors?.isAuthenticationRequired.join(" ")
                                }
                            }))
                            return
                        }
                        return
                    }

                    if (response?.errors?.message) {
                        this.setState({warning: response?.errors?.message})
                        return
                    }

                    Snackbar.Error("An unknown error occurred")

                } else {
                    Snackbar.Error("Test E-Mail could not be sent.")
                    this.setState({_testing: false})
                }
                this.setState({_testing: false})

            })
            .catch(e => {
                Snackbar.Error("Test E-Mail could not be sent.")
                this.setState({_testing: false})
            })
            .finally(() => this.setState({_testing: false}))
    }

    handleFormSubmit = (event) => {
        event.preventDefault()

        const mailConfig = this.getSubmitDTO()

        this.setState({_sending: true})
        this.apiClient.updateEntity(mailConfig)
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    Snackbar.Success("Test E-Mail has been sent.")
                    this.setState({
                        fieldErrors: {
                            domain: null,
                            port: null,
                            senderEMailAddress: null,
                            supportEMailAddress: null,
                            username: null,
                            password: null
                        }
                    })

                } else if (response.status === 400) {
                    if (response?.errors?.message === "Could not send email") {
                        if (this.state.isAuthenticationRequired) {
                            this.setState(prevState => ({
                                fieldErrors: {
                                    ...prevState.fieldErrors,
                                    username: "Invalid Credentials",
                                    password: "Invalid Credentials"
                                }
                            }))
                            this.setState({_sending: false})
                            return
                        }

                    }

                    this.setState(prevState => ({fieldErrors: {...prevState.fieldErrors, ...response.errors?.fieldErrors}}))
                    if (response?.errors?.fieldErrors?.isAuthenticationRequired) {
                        this.setState(prevState => ({
                            fieldErrors: {
                                ...prevState.fieldErrors,
                                username: prevState.fields.username ? null : response?.errors?.fieldErrors?.isAuthenticationRequired.join(" "),
                                password: prevState.fields.password ? null : response?.errors?.fieldErrors?.isAuthenticationRequired.join(" ")
                            }
                        }))
                    }
                } else {
                    Snackbar.Error("Test E-Mail could not be sent.")
                    this.setState({_sending: false})
                }
                this.setState({_sending: false})

            })
            .catch(e => {
                Snackbar.Error("The server was not able to process your request")
                this.setState({_sending: false})
            })
            .finally(() => {
                this.setState({_sending: false})
            })
    }

    render() {

        const {domain, password, port, senderEMailAddress, supportEMailAddress, username} = this.state.fields

        const {fieldErrors, warning} = this.state

        if (this.state._loading) {
            return <Spinner/>
        }

        return <Container fluid={true}>
            {warning && <Row style={{margiBottom: "2rem"}}>
                <Col>
                    <AlertBox style={{marginBottom: "1rem"}}
                              type={(typeof warning) === "string" ? AlertBoxType.Error : AlertBoxType.Warning}>
                        <FlexRow>
                            <FlexColumn style={{width: "4rem", marginRight: ".5rem"}} alignItems={"center"}
                                        justifyContent={"center"}>
                                <Warning style={{width: "3rem", height: "3rem"}}/>
                            </FlexColumn>
                            {(typeof warning) === "string" ?
                                <>
                                    <FlexColumn>
                                        <Subheadline style={{marginBottom: 0}}>
                                            Error
                                        </Subheadline>
                                        <Text>
                                            {warning}
                                        </Text>
                                    </FlexColumn>
                                </>
                                :
                                <>
                                    <FlexColumn>
                                        <Subheadline>
                                            {warning.title}
                                        </Subheadline>
                                        <Text>
                                            {warning.description}
                                        </Text>
                                    </FlexColumn>
                                </>}

                        </FlexRow>

                    </AlertBox>
                </Col>
            </Row>
            }

            <Row>
                <Col xs={5}>
                    <form onSubmit={this.handleFormSubmit}>
                        <Row>
                            <Col>
                                <Headline>Mailserver</Headline>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Input isValid={fieldErrors.domain} margin={"normal"} label={"Mailserver"}
                                       name={"domain"} value={domain}
                                       onChange={this.handleChange}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Input isValid={fieldErrors.port} margin={"normal"} label={"Port"} name={"port"}
                                       value={port}
                                       onChange={this.handleChange}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Input isValid={fieldErrors.senderEMailAddress} margin={"normal"} label={"Mail from:"}
                                       name={"senderEMailAddress"}
                                       value={senderEMailAddress}
                                       onChange={this.handleChange}/>
                            </Col>
                        </Row>

                        <Row style={{marginBottom: "2rem"}}>
                            <Col>
                                <Input isValid={fieldErrors.supportEMailAddress} margin={"normal"}
                                       label={"Administrative Mail"} name={"supportEMailAddress"}
                                       value={supportEMailAddress} onChange={this.handleChange}/>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Checkbox onChange={this.toggleAuthentication}
                                          label={"Authentication"} checked={this.state.isAuthenticationRequired}/>
                            </Col>
                        </Row>

                        {
                            this.state.isAuthenticationRequired &&
                            <>
                                <Row>
                                    <Col>
                                        <Input isValid={fieldErrors.username} label={"Username"} value={username}
                                               name={"username"}
                                               onChange={this.handleChange}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Input isValid={fieldErrors.password} value={password} margin={"normal"}
                                               label={"Password"}
                                               type={"password"} onChange={this.handleChange}
                                               name={"password"}/>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row>
                            <Col>
                                <FlexRow justifyContent={"flex-end"}>
                                    <Button onClick={this.handleTestSubmit}>Test {this.state._testing &&
                                    <Spinner/>}</Button>
                                    <Button>Save {this.state._sending && <Spinner/>}</Button>
                                </FlexRow>

                            </Col>
                        </Row>

                    </form>
                </Col>
            </Row>


        </Container>
    }

}
