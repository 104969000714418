import React from "react"
import {compose} from "redux"
import {Collapsible, ListMenuItem, ListMenuRootItem, MenuContext, TopLi} from "@greenbone/cloud-component-library"
import {ApplicationUrlProvider} from "./ApplicationUrlProvider"
import TaskList from "../../assets/icons/task-list-edit.svg"
import Cash from "../../assets/icons/cash-payment-bills.svg"
import MultiWifi from "../../assets/icons/multiple-users-wifi.svg"
import Money from "../areas/Configuration/SalesDashboard/assets/money-neutral.svg"
import {withLocation} from "../../hocs/withLocation";

export class _BillingMenu extends React.Component {

    get isActive() {
        const {pathname} = this.props.location
        return pathname?.startsWith(ApplicationUrlProvider.billing())
    }

    render() {
        return (
            <MenuContext.Consumer>
                {({minified, toggle, ...rest}) => {
                    const billingOpen = rest[`billingOpen`]
                    const toggleBilling = toggle("billingOpen")

                    return <TopLi open={this.isActive}>
                        <ListMenuRootItem exact open={billingOpen} extended={billingOpen}
                                          onClick={(event) => {
                                              toggleBilling(event, true)
                                          }} to={ApplicationUrlProvider.billingCustomers()}
                                          icon={<Money/>}
                                          text={"Billing"}/>
                        <Collapsible style={{display: billingOpen ? "block" : "none"}}>
                            <ListMenuItem open={this.isActive} to={ApplicationUrlProvider.billingCustomers()}
                                          minified={minified} icon={MultiWifi}>
                                Customers

                            </ListMenuItem>
                            <ListMenuItem open={this.isActive} minified={minified}
                                          to={ApplicationUrlProvider.selfService()} icon={TaskList}>
                                Self-Service
                            </ListMenuItem>
                            <ListMenuItem open={this.isActive} minified={minified}
                                          to={ApplicationUrlProvider.billingPaymentMethods()} icon={Cash}>
                                Payment Methods
                            </ListMenuItem>
                        </Collapsible>
                    </TopLi>
                }}
            </MenuContext.Consumer>
        )
    }
}


export const BillingMenu = compose(
    withLocation
)(_BillingMenu)
