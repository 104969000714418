import React from "react"
import {ApplicationLayout, Logout} from "@greenbone/cloud-component-library"
import {Navigate, Route} from "react-router-dom"
import {ApplicationUrlProvider} from "./ApplicationUrlProvider"
import {MainMenu} from "./MainMenu"
import {LegalPage} from "../areas/Configuration/Legal/LegalPage"
import {AppearancePage} from "../areas/Configuration/Appearance/AppearancePage"
import {HostValidationPage} from "../areas/HostValidation/HostValidationPage"
import {VERSION} from "../../Constants"
import GreenboneLogo from "../../assets/logos/greenbone-logo.svg?url"
import {globalInstanceStorage} from "../GlobalSingle"
import {MailPage} from "../areas/Configuration/Mail/MailPage"
import {SecurityPage} from "../areas/Configuration/Security/SecurityPage"
import {PaymentMethodPage} from "../areas/Billing/PaymentMethod/PaymentMethodPage"
import {CustomerPage} from "../areas/Billing/Customers/CustomerPage"
import {SalesDashboardPage} from "../areas/Configuration/SalesDashboard/SalesDashboardPage"
import {CompliancePage} from "../areas/Compliance/CompliancePage"
import {ManualPage} from "../areas/Help/Manual/ManualPage"
import {WelcomeScreen} from "../welcomeScreen/WelcomeScreen"
import {SelfServicePage} from "../areas/Billing/SelfService/SelfServicePage"
import {ManagedSecurityPage} from "../areas/ManagedSecurity/ManagedSecurityPage"
import {ManagedSecurityCompanyOverviewPage} from "../areas/ManagedSecurity/ManagedSecurityCompanyOverviewPage"
import {JumpInPage} from "../areas/ManagedSecurity/JumpIn/JumpInPage"
import {Routes} from "react-router";

const NotFound = () => <div>404 page not found</div>

const LogoutComponent = (props) => <Logout keycloak={globalInstanceStorage.getKeycloak()}/>

export const ApplicationRouter = (props) => (
    <ApplicationLayout Logout={LogoutComponent} Menu={MainMenu} version={VERSION} logo={GreenboneLogo}>
        <WelcomeScreen/>
        <Routes>
            <Route exact path={"/"} element={<Navigate to={ApplicationUrlProvider.dashboard()}/>}/>

            <Route exact path={ApplicationUrlProvider.managedSecurity()} Component={ManagedSecurityPage}/>
            <Route exact path={ApplicationUrlProvider.managedSecurityCustomer()}
                   Component={ManagedSecurityCompanyOverviewPage}/>
            <Route path={ApplicationUrlProvider.managedSecurityCustomerImpersonate()} Component={JumpInPage}/>
            <Route path={ApplicationUrlProvider.dashboard()} Component={SalesDashboardPage}/>
            <Route path={ApplicationUrlProvider.hostvalidation()} Component={HostValidationPage}/>
            <Route path={ApplicationUrlProvider.billingCustomers()} Component={CustomerPage}/>
            <Route exact={true} path={ApplicationUrlProvider.configuration()} Component={LegalPage}/>
            <Route path={ApplicationUrlProvider.configurationMailserver()} Component={MailPage}/>
            <Route path={ApplicationUrlProvider.billingPaymentMethods()} Component={PaymentMethodPage}/>
            <Route path={ApplicationUrlProvider.configurationLegalWildCard()} Component={LegalPage}/>
            <Route path={ApplicationUrlProvider.configurationAppearance()} Component={AppearancePage}/>
            <Route path={ApplicationUrlProvider.security()} Component={SecurityPage}/>
            <Route path={ApplicationUrlProvider.compliance()} Component={CompliancePage}/>
            <Route path={ApplicationUrlProvider.manual()} Component={ManualPage}/>
            <Route path={ApplicationUrlProvider.selfService()} Component={SelfServicePage}/>
            <Route path="*" Component={NotFound} />
        </Routes>
    </ApplicationLayout>
);
