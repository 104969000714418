//https://gcs-dev-mgmt.greenbone.io/usermanagement/admin/customers/93f25070-49c8-11eb-9270-d3c46628c461/migrate

import {rest} from "msw"
import {buildUrl} from "../buildUrl"


export const userManagementServiceHandlers = [
    rest.put(buildUrl("/usermanagement/admin/customers/:company/migrate"), ((req, res, context) => {
        return res(
            context.status(428),
            context.json({
                message: "message aus dem be",
                status: "PRECONDITION_REQUIRED"
            })
        )
    }))
]
