import {UrlProvider} from "@greenbone/cloud-component-library"


export class ApplicationUrlProvider extends UrlProvider {
    static BASE_URL = `/`

    static getBase() {
        return ApplicationUrlProvider.BASE_URL
    }

    static dashboard() {
        return `${ApplicationUrlProvider.getBase()}dashboard`
    }

    static managedSecurity() {
        return `${ApplicationUrlProvider.getBase()}managedSecurity`
    }

    static managedSecurityCustomer() {
        return `${ApplicationUrlProvider.getBase()}managedSecurity/:customerId`
    }

    static managedSecurityCustomerImpersonate() {
        return `${ApplicationUrlProvider.getBase()}managedSecurity/impersonate/:groupId`
    }

    static billingCustomers() {
        return `${ApplicationUrlProvider.getBase()}billing/customers`
    }

    static hostvalidation() {
        return `${ApplicationUrlProvider.getBase()}hostvalidation`
    }

    static billing() {
        return `${ApplicationUrlProvider.getBase()}billing`
    }


    static configuration() {
        return `${ApplicationUrlProvider.getBase()}configuration`
    }

    static configurationAppearance() {
        return `${ApplicationUrlProvider.getBase()}configuration/appearance`
    }

    static configurationMailserver() {
        return `${ApplicationUrlProvider.getBase()}configuration/mail`
    }



    static billingPaymentMethods() {
        return `${ApplicationUrlProvider.getBase()}billing/paymentMethods`
    }


    static configurationLegal() {
        return `${ApplicationUrlProvider.getBase()}configuration/legal`
    }

    static configurationLegalWildCard() {
        return `${this.configurationLegal()}/*`
    }

    static security() {
        return `${ApplicationUrlProvider.getBase()}security`
    }

    static compliance() {
        return `${ApplicationUrlProvider.getBase()}compliance`
    }

    static manual() {
        return `${ApplicationUrlProvider.getBase()}help/manual`
    }

    static selfService() {
        return `${ApplicationUrlProvider.getBase()}billing/selfservice`
    }


}
