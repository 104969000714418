var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { useMemo } from "react";
import md5 from "md5";
import styled from "styled-components";
import { Item, Menu, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IconButton } from "@material-ui/core";
import { css } from "styled-components";
// @ts-ignore
import { Theme } from "@greenbone/cloud-component-library";
export var Dropdown = function (_a) {
    var id = _a.id, children = _a.children;
    var menuId = useMemo(function () { return "DROPDOWNMENU".concat(md5("".concat(id))); }, [id]);
    var show = useContextMenu({
        id: menuId
    }).show;
    return _jsxs("div", { children: [_jsx(IconButton, { onClick: show, "aria-haspopup": "true", "aria-controls": "dropdown-menu", children: _jsx(MoreVertIcon, {}) }), _jsx(Menu, { id: menuId, children: children })] });
};
var IconWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  margin-right: 0.5rem;\n  color: #4C4C4C;\n\n  ", "\n"], ["\n  display: inline-block;\n  margin-right: 0.5rem;\n  color: #4C4C4C;\n\n  ", "\n"])), function (_a) {
    var _b;
    var danger = _a.danger;
    return danger && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["color: ", ";"], ["color: ", ";"])), (_b = Theme === null || Theme === void 0 ? void 0 : Theme.severity) === null || _b === void 0 ? void 0 : _b.high);
});
var TextWrapper = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: #4C4C4C;\n\n  ", "\n"], ["\n  color: #4C4C4C;\n\n  ", "\n"])), function (_a) {
    var _b;
    var danger = _a.danger;
    return danger && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["color: ", ";"], ["color: ", ";"])), (_b = Theme === null || Theme === void 0 ? void 0 : Theme.severity) === null || _b === void 0 ? void 0 : _b.high);
});
export var DropdownMenuItem = function (_a) {
    var danger = _a.danger, text = _a.text, icon = _a.icon, onClick = _a.onClick;
    return _jsxs(Item, { onClick: onClick, children: [_jsx(IconWrapper, { danger: danger, children: icon }), _jsx(TextWrapper, { danger: danger, children: text })] });
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
