export class MailServerUrlProvider {
    ENTITY_ROUTE = "/usermanagement/enterprise";

    getAll() {
        return "/vmssp-admin/email";
    }

    create() {
        return "/messageservice/email/check";
    }

    update() {
        return "/vmssp-admin/email";
    }


}
