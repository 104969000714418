import React from "react"

import {FilterTab, FlexRow, ScaleFilterTab} from "@greenbone/cloud-component-library"
import MoneyLetter from "../assets/money-letter.svg"
import LicenceKey from "../assets/key.svg"
import Bag from "../assets/money-bag.svg"
import Credit from "../assets/credit-card.svg"
import {PaymentTypes} from "../../PaymentMethod/components/PaymentTypes"
import VpnKeyIcon from "@material-ui/icons/VpnKey"

export function BillingMethod({method}) {

    switch (method) {
        case PaymentTypes.INVOICE:
            return <FlexRow alignItems={"center"}>
                <ScaleFilterTab scale={0.5}>
                    <FilterTab disabled icon={<MoneyLetter/>} color={"#7EC14B"}>
                        Invoice
                    </FilterTab>
                </ScaleFilterTab>
                Invoice
            </FlexRow>
        case PaymentTypes.LICENCE_KEY:
            return <FlexRow alignItems={"center"}>
                <ScaleFilterTab scale={0.5}>
                    <FilterTab disabled icon={<LicenceKey/>} color={"#7EC14B"}>
                        Licence Key
                    </FilterTab>
                </ScaleFilterTab>
                Licence Key
            </FlexRow>
        case PaymentTypes.CREDIT_CARD:
            return <FlexRow alignItems={"center"}>
                <ScaleFilterTab scale={0.5}>
                    <FilterTab disabled icon={<Credit/>} color={"#7EC14B"}>
                        Credit Card
                    </FilterTab>
                </ScaleFilterTab>
                Credit Card
            </FlexRow>

        case PaymentTypes.PROJECT_KEY :
            return <FlexRow alignItems={"center"}>
                <ScaleFilterTab scale={0.5}>
                    <FilterTab disabled icon={<VpnKeyIcon/>} color={"#7EC14B"}>
                        Project Key
                    </FilterTab>
                </ScaleFilterTab>
                Project Key
            </FlexRow>
        case "NONE":
            return <FlexRow alignItems={"center"}>
                <ScaleFilterTab scale={0.5}>
                    <FilterTab disabled icon={<Bag/>} color={"#7EC14B"}>
                        Free
                    </FilterTab>
                </ScaleFilterTab>
                Free
            </FlexRow>

        default:
            return <div></div>

    }
}
