import React, {useEffect, useReducer} from "react"
import {Snackbar, updateObjectInList} from "@greenbone/cloud-component-library"
import {RangeApiClient} from "./service/RangeApiClient"

export const RangeContext = React.createContext({data: null})

const Actions = {
    LOAD: "LOAD",
    LOADED: "LOADED",
    SENDING: "SENDING",
    SEND: "SEND"
}

function reducer(state, action) {

    switch (action.type) {
        case Actions.LOAD:
            return {...state, loading: true}

        case Actions.LOADED:
            return {...state, loading: false, data: action.payload}

        case Actions.SENDING :
            return {...state, sending: true}

        case Actions.SEND :
            return {...state, sending: false}

        default:
            return state

    }
}

export const RangeProvider = ({children}) => {
    const apiClient = new RangeApiClient()
    const [state, dispatch] = useReducer(reducer, {
        data: null
    })

    useEffect(() => {
        function f() {

            apiClient.getAll()
                .then(response => {
                    dispatch({type: Actions.LOADED, payload: response})
                })
                .catch(e => {
                    throw e
                })
        }

        f()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const setRange = (range) => {
        dispatch({type: Actions.SENDING})
        const {data} = state
        const newState = {...data, prices: updateObjectInList(data.prices, range, "id")}
        return apiClient.setRanges(newState)
            .then(response => {

                if (response?.status === "BAD_REQUEST") {
                    Snackbar.Error("Please check your inputs.")
                    return response
                }


            })
            .catch(e => Snackbar.Error(e))
            .finally(() => dispatch({type: Actions.SEND}))
    }

    const setArticleDetails = (productName, articleNumber, ipOrderMinimum) => {
        dispatch({type: Actions.SENDING})
        const newState = {productName, articleNumber, ipOrderMinimum}

        return apiClient.setMetadata(newState)
            .then(response => {
                if (response?.status === "BAD_REQUEST") {
                    return response
                }
                if (!response || response?.prices) {
                    Snackbar.Success("Saved Successfully")
                    return response
                }
                Snackbar.Error("Could not save changes")
                return response
            })
            .catch(e => Snackbar.Error(e))
            .finally(() => dispatch({type: Actions.SEND}))
    }

    return <RangeContext.Provider value={{...state, setRange, setArticleDetails}}>
        {children}
    </RangeContext.Provider>
}
